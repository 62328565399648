import React, { createContext } from "react"

import CommunityAPI from "datasources/community"
import usePromise from "hooks/usePromise"
import useAppCtx from "hooks/useAppCtx"

const Context = createContext()

function useCommunityApi() {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    return CommunityAPI.list(ctx)
  })

  return {
    posts: result && result.data,
    isFetchingPosts: !isResolved,
  }
}

export function Provider({ children, history, match }) {
  const {
    posts,
    isFetchingPosts
  } = useCommunityApi()

  const value = {
    posts,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingPosts && children}
    </Context.Provider>
  )
}

export default Context