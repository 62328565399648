import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import { ChevronLeft, Bars, Times } from 'styled-icons/fa-solid'
import useLayoutCtx from 'hooks/useLayoutCtx'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #044c74;
  height: 80px;
  padding: 0 30px;
  @media only screen and (max-width: 565px) {
    padding: 0;
  }
`

const Title = styled.div`
  align-self: center;
  color: #ffffff;
  cursor: default;
  font-size: 30px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (max-width: 565px) {
    align-self: flex-start;
    font-size: 24px;
    width: 100%;
    margin-left: 10px;
  }
`

const SubTitle = styled.div`
  align-self: center;
  color: #9da3a8;
  cursor: default;
  font-size: 16px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  @media only screen and (max-width: 565px) {
    align-self: flex-start;
    font-size: 12px;
    width: 100%;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  min-width: 0;
  width: 100%;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
  }
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 50px;
  color: #ffffff;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 50px;
  color: #ffffff;
  cursor: pointer;
`

const HamburgerButton = styled.div`
  display: none;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 50px;
    color: #ffffff;
    cursor: pointer;
    margin-left: auto;
    z-index: 999999;
  }
`

export function Topbar({ hamburgerOpen = false, onHamburgerClick = () => {} }) {
  const history = useHistory()
  const { layoutState } = useLayoutCtx()

  return (
    <>
      <Container>
        {layoutState.backLink && layoutState.backLink !== true && (
          <BackLink to={layoutState.backLink}>
            <ChevronLeft size='20' />
          </BackLink>
        )}
        {layoutState.backLink === true && (
          <BackButton onClick={() => history.goBack()}>
            <ChevronLeft size='20' />
          </BackButton>
        )}
        {layoutState.title && (
          <TitleWrapper>
            <Title>{layoutState.title}</Title>
            <SubTitle>{layoutState.subTitle}</SubTitle>
          </TitleWrapper>
        )}
        <HamburgerButton onClick={onHamburgerClick}>
          {hamburgerOpen ? <Times size='20' /> : <Bars size='20' />}
        </HamburgerButton>
      </Container>
    </>
  )
}

export default Topbar
