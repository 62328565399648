import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  UserAlt,
  Check,
  AddressCard,
  CalendarAlt,
  Tag,
  MoneyBill,
} from 'styled-icons/fa-solid'
import { CcMastercard, CcVisa } from 'styled-icons/fa-brands'

import useForm from 'hooks/useForm'
import useAppCtx from 'hooks/useAppCtx'
import SubscriptionsAPI from 'datasources/subscriptions'
import CouponsAPI from 'datasources/coupons'
import FlexRow from 'components/FlexRow'
import FlexColumn from 'components/FlexColumn'
import Logo from 'routes/Anonymous/components/Logo'
import Input from 'routes/Anonymous/components/Input'
import Form from 'routes/Anonymous/components/Form'
import Title from 'routes/Anonymous/components/Title'
import Subtitle from 'routes/Anonymous/components/Subtitle'
import Submit from 'routes/Anonymous/components/Submit'
import Button from 'routes/Anonymous/components/Button'
import AltAction from 'routes/Anonymous/components/AltAction'
import mixpanel from 'utils/mixpanel'
import fbq from 'utils/fbq'
import { createGlobalStyle } from 'styled-components'
import toast, { Toaster } from 'react-hot-toast'
// import CheckoutCielo from '../../../../components/backgrounds/Checkout.png'

const GlobalTheme = createGlobalStyle`
    .globalClass_2cc9, .wrap_b362, ._orientationRight_75db, .__jivoMobileButton {
      display: none !important;
    }
    .grecaptcha-badge {
      visibility: hidden;
    }
  @media only screen and (max-width: 896px) {
    .globalClass_2cc9, .wrap_b362, ._orientationRight_75db, .__jivoMobileButton {
      display: none !important;
    }
    .grecaptcha-badge {
      visibility: hidden;
    }
  }
`

const initial = {
  bundleId: 14,
  identity: '',
  cardNum: '',
  cardName: '',
  cardCvv: '',
  cardExp: '',
  couponCode: '',
}

const constraints = {
  identity: {
    presence: { allowEmpty: false },
    format: /\d{3}.\d{3}.\d{3}-\d{2}/,
  },
  cardNum: { presence: { allowEmpty: false } },
  cardName: { presence: { allowEmpty: false } },
  cardCvv: { presence: { allowEmpty: false }, format: /[0-9]{3,4}/ },
  cardExp: { presence: { allowEmpty: false }, format: /\d{2}\/\d{2}/ },
}

const errorsMap = {
  chargeReturnCode05: 'Não Autorizada pelo Banco',
  chargeReturnCode51: 'Saldo Insuficiente',
  chargeReturnCode54: 'Cartão Expirado',
  chargeReturnCode57: 'Autorização Negada',
  chargeReturnCode70: 'Problemas com o Cartão de Crédito',
  chargeReturnCode77: 'Cartão Cancelado',
  chargeReturnCode78: 'Cartão Bloqueado',
  chargeReturnCode79: 'Falha de Comunicação',
  chargeReturnCodeXX: 'Bandeira Não Suportada',
  shouldBeValid: 'É Inválido',
}

const Wrapper = styled(FlexRow)``

const FormRow = styled(FlexRow)`
  display: block;
  @media only screen and (min-width: 565px) {
    display: flex;
    & > *:first-child {
      margin-right: 20px;
    }
  }
`
const CustomTitle = styled(Title)`
  @media only screen and (max-width: 565px) {
    margin-top: 5px;
  }
`

const CustomSubTitle = styled(Subtitle)`
  margin-top: 10px;
  margin-bottom: 10px;
`

const Bundles = styled(FlexColumn)`
  border-radius: 5px;
  color: #555555;
  margin-bottom: 25px;
  overflow: hidden;
`

Bundles.Item = styled(FlexRow)`
  align-items: center;
  background-color: #bce0fd;
  cursor: pointer;
  flex-grow: 1;
  position: relative;
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 4px;
  height: 60px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #a5f195;
    `}
`

Bundles.Item.Header = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
`

Bundles.Item.SelectedIcon = styled(Check).attrs({ size: '31' })`
  align-self: center;
  margin: 5px 0;
  flex: auto;
`

Bundles.Item.Price = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-align: end;
  flex: auto;
`

Bundles.Item.Price.CurrencySymbol = styled.span`
  font-size: 12px;
  line-height: 24px;
`

Bundles.Item.Price.Period = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: relative;
  text-align: center;
`

const BundleSummary = styled(FlexColumn)`
  border-radius: 4px;
  border: solid 1px #ffffff;
  font-size: 13px;
  line-height: 40px;
  font-weight: bold;
  padding: 3px 20px;
  padding-bottom: 0;
  margin-bottom: 25px;
  background-color: white;
  color: #939393;
  border: solid 1px #527abf;
`

const BundleAlert = styled.div`
  background-color: #ffca3a;
  color: #555555;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 25px;
  & > svg {
    margin-right: 10px;
  }
  @media only screen and (max-width: 565px) {
    font-size: 13px;
  }
`

BundleAlert.Text = styled.div``

BundleSummary.Row = styled(FlexRow)``

BundleSummary.Label = styled.div`
  flex-grow: 1;
`

BundleSummary.Value = styled.div`
  text-align: right;
  flex-grow: 1;
`

const BUNDLES = {
  10: {
    productId: 1,
    label: 'Pagamento único',
    confirmLabel: 'Mensal',
    installments: 1,
    valueFloat: 16.99,
    value: '16,99',
    totalValue: '16,99',
  },
  14: {
    productId: 4,
    label: 'Parcelado (40% off)',
    confirmLabel: 'Anual',
    installments: 12,
    valueFloat: 7.99,
    value: '7,99',
    totalValue: '95,88',
    alert: 'Você economizou R$60,00!',
  },
  12: {
    productId: 3,
    label: 'Parcelado (20% off)',
    confirmLabel: 'Semestral',
    installments: 6,
    valueFloat: 9.99,
    value: '9,99',
    totalValue: '59,94',
    alert: 'Você economizou R$18,00!',
  },
}

const BrandWrapper = styled(FlexRow)`
  width: 45px;
  & svg:first-child {
    margin-right: 5px;
  }
`

const ButtonC = styled.button`
  border-left: 1px solid white;
`

function Brands() {
  return (
    <BrandWrapper>
      <CcVisa size='20' />
      <CcMastercard size='20' />
    </BrandWrapper>
  )
}

function BundleSelect({ onChange, value }) {
  const bundle = BUNDLES[value]

  return (
    <div>
      <Bundles>
        <Bundles.Item selected={value === 1} onClick={() => onChange(1)}>
          <MoneyBill size='24' />
          <Bundles.Item.Header>
            &nbsp;Transferência Bancária
          </Bundles.Item.Header>
          {value === 1 && <Bundles.Item.SelectedIcon />}
        </Bundles.Item>
        <Bundles.Item selected={value === 10} onClick={() => onChange(10)}>
          <Bundles.Item.Header>1 mês</Bundles.Item.Header>
          {value === 10 ? (
            <Bundles.Item.SelectedIcon />
          ) : (
            <Bundles.Item.Price>
              <Bundles.Item.Price.CurrencySymbol>
                R$
              </Bundles.Item.Price.CurrencySymbol>
              16,99
              <Bundles.Item.Price.Period></Bundles.Item.Price.Period>
            </Bundles.Item.Price>
          )}
        </Bundles.Item>
        <Bundles.Item selected={value === 14} onClick={() => onChange(14)}>
          <Bundles.Item.Header>12 meses</Bundles.Item.Header>
          {value === 14 ? (
            <Bundles.Item.SelectedIcon />
          ) : (
            <Bundles.Item.Price>
              <Bundles.Item.Price.CurrencySymbol>
                R$
              </Bundles.Item.Price.CurrencySymbol>
              7,99 /mês
              {/* <Bundles.Item.Price.Period>/ mês</Bundles.Item.Price.Period> */}
            </Bundles.Item.Price>
          )}
        </Bundles.Item>
        <Bundles.Item selected={value === 12} onClick={() => onChange(12)}>
          <Bundles.Item.Header>6 meses</Bundles.Item.Header>
          {value === 12 ? (
            <Bundles.Item.SelectedIcon />
          ) : (
            <Bundles.Item.Price>
              <Bundles.Item.Price.CurrencySymbol>
                R$
              </Bundles.Item.Price.CurrencySymbol>
              9,99 /mês
              {/* <Bundles.Item.Price.Period>/ mês</Bundles.Item.Price.Period> */}
            </Bundles.Item.Price>
          )}
        </Bundles.Item>
      </Bundles>
      <CustomSubTitle id='test'>Resumo da Compra</CustomSubTitle>
      {/* {bundle.alert && <BundleAlert><Alert size="20"/><BundleAlert.Text>{bundle.alert}</BundleAlert.Text></BundleAlert>} */}
      {value !== 1 && (
        <BundleSummary>
          <BundleSummary.Row>
            <BundleSummary.Label>{bundle.label}</BundleSummary.Label>
            <BundleSummary.Value>
              {bundle.installments > 1 && (
                <span>{`${bundle.installments}x`}</span>
              )}{' '}
              R$ {bundle.value}
            </BundleSummary.Value>
          </BundleSummary.Row>
          <BundleSummary.Row>
            <BundleSummary.Label>Total a pagar</BundleSummary.Label>
            <BundleSummary.Value>R$ {bundle.totalValue}</BundleSummary.Value>
          </BundleSummary.Row>
        </BundleSummary>
      )}
    </div>
  )
}

const CreditCardForm = ({ submitting, fields }) => (
  <div>
    <Input
      id='identity'
      placeholder='CPF'
      icon={AddressCard}
      autoFocus
      mask='999.999.999-99'
      {...fields.identity}
    />
    <Input
      id='cardNum'
      placeholder='Número do Cartão'
      icon={Brands}
      iconWidth={50}
      mask='9999 9999 9999 9999'
      {...fields.cardNum}
    />
    <Input
      id='cardName'
      placeholder='Nome no Cartão'
      icon={UserAlt}
      {...fields.cardName}
    />
    <FormRow>
      <Input
        id='cardExp'
        placeholder='Vencimento'
        icon={CalendarAlt}
        mask='99/99'
        {...fields.cardExp}
      />
      <Input
        id='cardCvv'
        placeholder='CVV'
        icon={Check}
        mask='9999'
        {...fields.cardCvv}
      />
    </FormRow>
    <Input
      id='couponCode'
      placeholder='Cupom'
      icon={Tag}
      {...fields.couponCode}
    />
    <Submit value='Continuar' disabled={submitting} />
  </div>
)

const BankValues = () => (
  <BundleSummary>
    <BundleSummary.Row>
      <BundleSummary.Label>Pagamento único</BundleSummary.Label>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>30 dias</BundleSummary.Label>
      <BundleSummary.Value>R$ 16,99</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>6 meses</BundleSummary.Label>
      <BundleSummary.Value>R$ 56,00 (5% off)</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>12 meses</BundleSummary.Label>
      <BundleSummary.Value>R$ 85,00 (10% off)</BundleSummary.Value>
    </BundleSummary.Row>
  </BundleSummary>
)

const BankDetails = () => (
  <BundleSummary>
    <BundleSummary.Row>
      <BundleSummary.Label>PIX (CNPJ)</BundleSummary.Label>
      <BundleSummary.Value>28484498000124</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>Banco Bradesco</BundleSummary.Label>
      <BundleSummary.Value>237</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>Agência</BundleSummary.Label>
      <BundleSummary.Value>0831</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>Conta Corrente</BundleSummary.Label>
      <BundleSummary.Value>12825-2</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>CNPJ</BundleSummary.Label>
      <BundleSummary.Value>28.484.498/0001-24</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>Titular</BundleSummary.Label>
      <BundleSummary.Value>
        Instituto Brasileiro de Saúde Euclides Leite
      </BundleSummary.Value>
    </BundleSummary.Row>
    <p>
      Favor enviar o comprovante e o número do seu CPF para:{' '}
      <b>contato@ibsel.com.br</b>
    </p>
  </BundleSummary>
)

const Plan = styled.div`
  font-size: 28px;
  margin-bottom: 20px;
`

const Confirmation = ({ coupon, bundle, values, submitting, setIsFilled }) => (
  <div>
    <Plan>Plano: {bundle.confirmLabel}</Plan>
    <Plan>
      Total: {bundle.installments}x R$ {bundle.value}
    </Plan>
    {coupon && (
      <div>
        <Plan>Cupom: {coupon.code.toUpperCase()}</Plan>
        <Plan>
          c/ Desconto: {bundle.installments}x R${' '}
          {(bundle.valueFloat * (1 - coupon.amount / 100))
            .toFixed(2)
            .replace('.', ',')}
        </Plan>
      </div>
    )}
    <BundleSummary>
      <BundleSummary.Row>
        <BundleSummary.Label>CPF</BundleSummary.Label>
        <BundleSummary.Value>{values.identity}</BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>Nome</BundleSummary.Label>
        <BundleSummary.Value>{values.cardName}</BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>Cartão</BundleSummary.Label>
        <BundleSummary.Value>
          **** **** **** {values.cardNum.substr(values.cardNum.length - 4)}
        </BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>CVV</BundleSummary.Label>
        <BundleSummary.Value>***</BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>Expiração</BundleSummary.Label>
        <BundleSummary.Value>{values.cardExp}</BundleSummary.Value>
      </BundleSummary.Row>
    </BundleSummary>
    <FlexRow>
      <Button onClick={() => !submitting && setIsFilled(false)}>Voltar</Button>
      <Submit value='Efetuar Pagamento' disabled={submitting} />
    </FlexRow>
  </div>
)

export default function({ history }) {
  const ctx = useAppCtx()
  const [submitting, setSubmitting] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [coupon, setCoupon] = useState()

  const { fields, values, setAllErrors, onSubmit } = useForm(initial, {
    constraints,
    errorsMap,
  })

  useEffect(function() {
    fbq('InitiateCheckout')

    toast(
      t => (
        <span>
          {Math.floor(Math.random() * 6) +
            1 +
            1 +
            ' alunos estão realizando uma compra!'}
        </span>
      ),
      {
        icon: '🔥',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
        duration: 7000,
      },
    )
  }, [])

  const submit = async () => {
    if (!submitting) {
      setSubmitting(true)
      const host = `${window.location.protocol}//${window.location.host}`
      const { productId, installments } = BUNDLES[values.bundleId]
      const productIds = [productId]
      const captchaToken = await window.grecaptcha.execute(
        '6LeMp9UZAAAAACQjjLnJCa16UQFbRN5e5alriPxS',
        { action: 'submit' },
      )
      try {
        const { data: tokens } = await SubscriptionsAPI.create(ctx, {
          ...values,
          productIds,
          installments,
          host,
          captchaToken,
        })
        ctx.auth.auth(tokens)
        fbq('Purchase')
        mixpanel(ctx, 'Subscribe', { 'Product Id': productId })
        history.push('/')
      } catch (err) {
        setSubmitting(false)
        setIsFilled(false)
        console.log(err)
        setAllErrors(err)
      }
    }
  }

  useEffect(() => {
    ;(async function() {
      console.log('calling')
      if (values.couponCode) {
        try {
          const { data } = await CouponsAPI.get(ctx, values.couponCode)
          setCoupon(data)
          return
        } catch {
          setCoupon(undefined)
        }
      }
      setCoupon(undefined)
    })()
    // eslint-disable-next-line
  }, [values.couponCode])

  const isBankPayment = fields.bundleId && fields.bundleId.value === 1

  return (
    <Wrapper>
      <Toaster position='top-right' reverseOrder={false} />
      <GlobalTheme />
      <Form
        onSubmit={
          isFilled ? onSubmit(submit) : onSubmit(() => setIsFilled(true))
        }
      >
        <Logo />
        <CustomTitle>Quase Pronto</CustomTitle>
        {!isFilled && (
          <CustomSubTitle>Primeiro, escolha o período:</CustomSubTitle>
        )}
        {!isFilled && <BundleSelect {...fields.bundleId} />}
        {isBankPayment && <BankValues />}
        {isBankPayment && <BankDetails />}
        {!isFilled && !isBankPayment && (
          <CustomSubTitle>Agora, insira seus dados:</CustomSubTitle>
        )}
        {!isBankPayment && !isFilled && <CreditCardForm fields={fields} />}
        {!isBankPayment && isFilled && (
          <Confirmation
            coupon={coupon}
            setIsFilled={setIsFilled}
            bundle={BUNDLES[values.bundleId]}
            values={values}
            submitting={submitting}
          />
        )}
        <AltAction>
          Gostaria de experimentar mais um pouco? <Link to='/'>Voltar</Link>
        </AltAction>
      </Form>
    </Wrapper>
  )
}
