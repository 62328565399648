import React from 'react'
import { ThemeProvider } from 'styled-components'
import { media } from 'components/layouts'

const theme = {
  colors: {
    main: '#FD5E53',
    accent: '#FF5C5C',
    lightGray: '#5C81FF',
    gray: '#152852',
    darkGray: '#08183A',
  },
  media,
}

export default function({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
