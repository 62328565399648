import React, { createContext } from 'react'

import PlaylistsAPI from 'datasources/playlists'
import usePromise from 'hooks/usePromise'
import useAppCtx from 'hooks/useAppCtx'

const Context = createContext()

function usePlaylistItemType(playlistId, playlistItemTypeId) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function () {
    return PlaylistsAPI.get(ctx, playlistId, playlistItemTypeId)
  })

  return {
    playlistItems: result && result.data[0],
    isFetchingPlaylistItems: !isResolved,
  }
}

export function Provider({ children, playlist, itemType, history }) {
  const { playlistItems, isFetchingPlaylistItems } = usePlaylistItemType(
    playlist?.id,
    itemType?.id,
  )

  const value = {
    playlistItems,
    isFetchingPlaylistItems,
    playlist,
    itemType,
    history,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingPlaylistItems && children}
    </Context.Provider>
  )
}

export default Context
