import React from "react"
import {
  useLocation
} from "react-router-dom"

import useAppCtx from "hooks/useAppCtx"
import mixpanel from "utils/mixpanel"
import Logo from "routes/Anonymous/components/Logo"
import Form from "routes/Anonymous/components/Form"
import Title from "routes/Anonymous/components/Title"
import Subtitle from "routes/Anonymous/components/Subtitle"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ({ history }) {
  const ctx = useAppCtx()

  let query = useQuery();

  const tokens = {
    accessToken: query.get("t"),
    refreshToken: query.get("r")
  }

  try{
    ctx.auth.auth(tokens)
    let redirect = query.get("redirect") ? query.get("redirect") : ""
    history.push("/" + redirect)
    mixpanel(ctx, "User_Login", {'University': 'LTI' })
  }catch(err){
    let returnUrl = query.get("return")

    if (returnUrl) window.location.href = returnUrl
    else history.push("/")
  }

  return (
    <Form>
      <Logo/>
      <Title>
        Bem-vindo
      </Title>
      <Subtitle>
        Estamos te redirecionando.
      </Subtitle>
    </Form>
  )
}