import React, { useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { ChevronLeft, ChevronRight } from "styled-icons/fa-solid"

import mixpanel from "utils/mixpanel"
import FlexRow from "components/FlexRow"
import FlexColumn from "components/FlexColumn"
import useAppCtx from "hooks/useAppCtx"
import { getImgixUrl } from "utils/imgix"

import Book from "../../components/Book"
import useBooksCtx from "../../hooks/useBooksCtx"
import useUniversityCtx from 'hooks/useUniversityCtx'

const Topics = styled(FlexColumn)`
  width: 100%;`

const Topic = styled(FlexRow)`
  background-color: #F2F7FA;
  border-radius: 10px;
  height: 300px;
  margin-bottom: 40px;
  @media only screen and (max-width: 565px) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 260px;
  }
`

const TopicCard = styled(({ topicId, image, ...rest }) => <Link {...rest}/>)`
  background-color: #AAAAAA;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 15px;
  text-decoration: none;
  width: 220px;
  ${({ topicId, image }) => image && css`
    background-image: url("${getImgixUrl(`topics/${topicId}/image`, { w: 220, h: 300})}");
  `};
  @media only screen and (max-width: 565px) {
    display: none;
  }
`

const TopicMobileCard = styled.div`
  background-color: #F2F7FA;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #1CACE3;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  padding-left: 20px;
  padding-top: 20px;
  text-transform: uppercase;
  @media only screen and (min-width: 565px) {
    display: none;
  }
`

const TopicNav = styled(FlexRow)`
  margin-left: 40px;
  margin-right: 40px;
  align-self: center;
  width: 90px;
  @media only screen and (max-width: 565px) {
    display: none;
  }
`

const TopicNavButton = styled(FlexRow)`
  align-items: center;
  background-color: #1CACE3;
  border-radius: 35px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  width: 35px;
`

const TopicCardName = styled(FlexColumn)`
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-top: auto;
  scroll-behavior: smooth;
`

const Books = styled(FlexRow)`
  overflow-x: scroll;
  padding-right: 40px;
  width: 1px;
  flex-grow: 1;
  @media only screen and (max-width: 565px) {
    padding-left: 20px;
  }
`

const CustomBook = styled(Book)`
  &:first-child {
    margin-left: 0;
  }
  &:last-child:after {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    right: -55px;
    width: 55px;
  }
  @media only screen and (max-width: 565px) {
    &:last-child:after {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      right: -20px;
      width: 20px;
    }
  }
`

function TopicRow({ topic }) {
  const { currCourse } = useBooksCtx()
  const booksRef = useRef()

  function scrollRight() {
    booksRef.current.scrollBy({
      left: booksRef.current.offsetWidth - 220,
      behavior: 'smooth'
    })
  }

  function scrollLeft() {
    booksRef.current.scrollBy({
      left: -booksRef.current.offsetWidth + 220,
      behavior: 'smooth'
    })
  }
  return (
    <>
      <TopicMobileCard>
        {topic.name}
      </TopicMobileCard>
      <Topic>
        <TopicCard to={`/livros/${currCourse.id}/disciplinas/${topic.id}`} topicId={topic.id} image={topic.image}>
          <TopicCardName>
            {topic.name}
          </TopicCardName>
        </TopicCard>
        <TopicNav>
          <TopicNavButton onClick={scrollLeft}>
            <ChevronLeft size="16"/>
          </TopicNavButton>
          <TopicNavButton onClick={scrollRight}>
            <ChevronRight size="16"/>
          </TopicNavButton>
        </TopicNav>
        <Books ref={booksRef}>
          {topic.books.map(book => (
            <CustomBook
              key={book.id}
              courseId={currCourse.id}
              topicId={topic.id}
              {...book}
            />
          ))}
        </Books>
      </Topic>
    </>
  )
}

export default function() {
  const ctx = useAppCtx()
  const { currCourse } = useBooksCtx()
  const { universities } = useUniversityCtx()

  useEffect(() => {
    mixpanel(ctx, "Books_Topic_List", { "University": universities.name })
    // TODO: performance problem
    // eslint-disable-next-line
  }, [ctx])

  return(
    <Topics>
      {currCourse.topics.map(topic => (
        <TopicRow key={topic.id} topic={topic}/>
      ))}
    </Topics>
  )
}