import { useMemo } from "react"
import useAuthCtx from "hooks/useAuthCtx"
import useLoaderCtx from "hooks/useLoaderCtx"

export default function() {
  const auth = useAuthCtx()
  const { inc, dec } = useLoaderCtx()

  return useMemo(() => ({
    auth,
    loader: { inc, dec },
  }), [auth, inc, dec])
}