import { post, put, buildUrl } from "./common"

export const UserSubjectAPI = {
  putRating(ctx, id, rating) {
    return put(ctx, buildUrl(`/public/user/subjects/${id}/rating`), { body: { value: rating } })
  },
  postWatched(ctx, id) {
    return post(ctx, buildUrl(`/public/user/subjects/${id}/watched`))
  },
}

export default UserSubjectAPI