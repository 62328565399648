import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import CommunityAPI from "datasources/community"
import usePromise from "hooks/usePromise"
import useAppCtx from "hooks/useAppCtx"
import useLayoutCtx from "hooks/useLayoutCtx"
import useForm from "hooks/useForm"

import Post from 'components/Post'
import Reply from 'components/Reply'

import * as S from './styles'

function usePostDetail(postId) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    return CommunityAPI.get(ctx, { postId })
  }, [postId])

  return {
    post: result && result.data,
    isFetchingPost: !isResolved,
  }
}

function usePostReplies(postId) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    return CommunityAPI.replies(ctx, { postId })
  }, [postId])

  return {
    replies: result && result.data,
    isFetchingReplies: !isResolved,
  }
}

const Replies = ({ list, ...rest }) => {
  return (
    <S.RepliesContainer>
      {list.map(reply => <Reply data={reply} key={reply.id} {...rest} />)}
    </S.RepliesContainer>
  )
}

const initial = {
  content: "",
}

const constraints = {
  content: { presence: { allowEmpty: false } },
}

const errorsMap = {}

export default function() {
  const ctx = useAppCtx()
  const { postId } = useParams()
  const { setLayoutState } = useLayoutCtx()
  const [list, setList] = useState([])

  const {
    fields,
    values,
    setAllErrors,
    onSubmit
  } = useForm(initial, { constraints, errorsMap })

  const { post = {}, isFetchingPost } = usePostDetail(postId)
  const { replies = [], isFetchingReplies } = usePostReplies(postId)

  useEffect(() => {
    setLayoutState({ title: 'Pergunta', subTitle: 'Comunidade', backLink: '/comunidade' })
  }, [post.title, setLayoutState])

  useEffect(() => {
    setList(replies)
  }, [replies])

  const submit = async () => {
    try {
     const { data: reply } = await CommunityAPI.createReply(ctx, { ...values, postId })
     setList(replies => [reply, ...replies])
     fields.content.onChange('')
     // mixpanel(ctx, "Reply_Created", {})
    } catch(err) {
      setAllErrors(err)
    }
  }

  const deleteReply = ({ id }) => {
    const index = list.findIndex(obj => obj.id === id);
    setList([
      ...list.slice(0, index),
      ...list.slice(index + 1)
    ])
  }

  return(
    <form onSubmit={onSubmit(submit)}>
      {isFetchingPost ? <>
        <div>Carregando pergunta...</div>
      </> : <>
        <S.Header>
          <S.Title>{post.title}</S.Title>
        </S.Header>
        <S.PostContainer>
          <Post data={post} readonly={true} />
        </S.PostContainer>
        <S.ReplyContainer>
          <S.Field>
            <S.Textarea
              id="content"
              placeholder="Digite sua reposta"
              type="text"
              {...fields.content}
            />
          </S.Field>
          <S.Actions>
            <S.Submit value="Responder"/>
          </S.Actions>
        </S.ReplyContainer>
        {isFetchingReplies ? <div>carregando respostas...</div> : <Replies list={list} onDelete={deleteReply} />}
      </>}
    </form>
  )
}