import styled from 'styled-components'

const LeftCol = styled.div`
  background-color: white;
  padding: 12px;
  height: 422px;
  width: 470px;
  /*box-shadow: 0px 0px 3px -1px black;*/

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    height: 325px;
    min-width:50%;
  }
  @media only screen and (min-width: 813px) and (max-width: 896px) and (orientation: landscape) {
    height: 364px;
    min-width:50%;
  }
`
const RightCol = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  flex: 1;
  height: 422px;
  justify-content: center;
  /*margin-left: 20px;*/
  /*box-shadow: 0px 0px 3px -1px black;*/

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    height: 325px;
    min-width:50%;
  }
  @media only screen and (min-width: 813px) and (max-width: 896px) and (orientation: landscape) {
    height: 364px;
    min-width:50%;
  }
`
const MainCol = styled.div`
  background-color: white;
  height: 422px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 0px 3px -1px black;
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    height: 100vh;
    position: absolute;
    margin-top: 100vh;
    box-shadow: 0px 0px 3px -1px black;
  }
  @media only screen and (min-width: 813px) and (max-width: 896px) and (orientation: landscape) {
    height: 364px;
    box-shadow: 0px 0px 3px -1px black;
  }
`
export { LeftCol, RightCol, MainCol }