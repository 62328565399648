import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import useClinicalCaseCtx from '../../hooks/useClinicalCaseCtx'
import * as Core from '../Core'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  box-shadow: 0px 0px 3px -1px black;
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    align-items: stretch;
  }
`

const LeftCol = styled(Core.LeftCol)`
`

const RightCol = styled(Core.RightCol)`
`

const Img = styled.img`
  max-width: 100%;
`

const Title = styled(Core.Title)`
`

const Contents = styled.span`
  line-height: 22px;
  text-align: justify;
  font-weight: 500;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    & > p, & > ul {
      font-size:0.8em;
    }
  }
`

function TxtImgStatic(props) {
  const { clinicalCase } = useClinicalCaseCtx()
  return (
    <Core.Frame backAmount={props.backAmount} nextAmount={props.nextAmount}>
      <Wrapper>
        <LeftCol>
          <Title><ReactMarkdown>{props.title}</ReactMarkdown></Title>
          <br />
          <Contents>
            <ReactMarkdown>{props.content}</ReactMarkdown>
          </Contents>
        </LeftCol>
        <RightCol>
          <Img
            src={`https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/clinical-case/${clinicalCase.id}/case-frame/${props.id}/image`}
          />
        </RightCol>
      </Wrapper>
    </Core.Frame>
  )
}

TxtImgStatic.propTypes = {
  backAmount: PropTypes.number.isRequired,
  nextAmount: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.bool.isRequired,
}

TxtImgStatic.defaultProps = {
  backAmount: 1,
  nextAmount: 0,
}

export default TxtImgStatic
