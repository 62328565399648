import React from "react"

import Simulations from "./components/Simulations"
import { Provider } from "./contexts/Simulations"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <Simulations match={match} history={history}/>
    </Provider>
  )
}