import React, { useCallback } from 'react'
import styled from 'styled-components'
import FlexColumn from 'components/FlexColumn'
import getFileUrl from 'utils/getFileUrl'
import getMimeType from 'utils/getMime'
import usePlaylistItemssCtx from '../../../hooks/usePlaylistItemssCtx'

const ItemTypes = styled(FlexColumn)`
  display: grid;
  grid-gap: 22px;
  ${({ theme }) => theme.media.max('480px')} {
    grid-template-columns: repeat(auto-fill, 100%);
  }
  ${({ theme }) => theme.media.between('480px', '800px')} {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 23px), 1fr));
  }
  ${({ theme }) => theme.media.between('800px', '1100px')} {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(33.33333% - 23px), 1fr)
    );
  }
  ${({ theme }) => theme.media.between('1100px', '1920px')} {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 23px), 1fr));
  }
  ${({ theme }) => theme.media.min('1920px')} {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(16.66667% - 23px), 1fr)
    );
  }
`

const ListItem = styled.div`
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1),
    0 7px 18px 0 rgba(97, 108, 121, 0.13);
  transition: box-shadow 0.2s ease;
  position: relative;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1),
      0 7px 18px 0 rgba(21, 111, 170, 0.21);
  }
`

const Thumb = styled.div`
  background: rgba(0, 0, 0, 0.5);
  flex: 1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  object-fit: contain;
  max-height: calc(100% - 48px);
  object-position: center;
  background-position: 0% center;
  overflow: hidden;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    max-height: 166px;
    object-fit: cover;
    object-position: center;
  }
`

const Resume = styled.div`
  display: flex;
  background: white;
  height: 48px;
  padding: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
`

function Simulations() {
  const { playlistItems, history } = usePlaylistItemssCtx()

  const goToDetail = useCallback(uri => e => history.push(uri), [history])

  return (
    <ItemTypes>
      {playlistItems.items.map(item => {
        const { id, assets = [], title, uri } = item
        const { id: assetId, mimeType } =
          (assets?.reverse() || []).find(obj => {
            return ['image/jpg', 'image/jpeg', 'image/png'].includes(
              (obj || {}).mimeType,
            )
          }) || {}

        const ext = getMimeType(mimeType)
        const thumbUrl = getFileUrl(
          `/simulations/${item.foreingKey}/assets/${assetId}.${ext}`,
        )
        console.log(item)

        return (
          <ListItem key={id} onClick={goToDetail(uri)}>
            <Thumb>
              <img src={thumbUrl} alt={title} />
            </Thumb>
            <Resume>{title}</Resume>
          </ListItem>
        )
      })}
    </ItemTypes>
  )
}

export default Simulations
