import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import FlexRow from 'components/FlexRow'
import useLayoutCtx from 'hooks/useLayoutCtx'
import useAppCtx from 'hooks/useAppCtx'
import mixpanel from 'utils/mixpanel'
// import TopicCard from 'components/TopicCard'

import useUniversityCtx from 'hooks/useUniversityCtx'
import { topicsMap, clinicalMap } from './constants'

const Header = styled(FlexRow)`
  background-color: #f5f9fa;
  border-radius: 2px;
  padding: 40px;
  flex-direction: column;
  margin-bottom: 20px;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`

const Topic = styled(Link)`
  background-color: rgb(28 172 227);
  text-decoration: none;
  padding: 33px;
  width: 200px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  background: linear-gradient(153deg, #010d2f, #38668ee0);
  span {
    margin-left: 10px;
  }
  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    flex-flow: column;
    width: 150px;
    margin-right: 0;
    padding: 15px;
    span {
      margin-left: 0;
      font-size: 15px;
    }
  }
`
const Info = styled.div`
  align-self: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  width: 100%;
  border-top: 0.5px solid #d3d3d3;
  margin-top: 10px;
  padding-top: 25px;
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;

  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
`

const InfoContainer = styled.div`
  font-weight: 500;
  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
`
const InfoLabel = styled.div`
  font-weight: 600;
  margin-top: 10px;
  > span {
    font-weight: 400;
  }
`

// const CustomTopicCard = styled(TopicCard)`
//   height: 220px;
//   box-shadow: 1px 2px 3px 0px #967f7f;
//   @media only screen and (max-width: 565px) {
//     width: 100%;
//   }
// `

export default function () {
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()

  useEffect(() => {
    setLayoutState({ title: 'Flashcard' })
  }, [setLayoutState])

  useEffect(() => {
    mixpanel(ctx, 'Flashcard Landing', { "University": universities.name })
    // eslint-disable-next-line
  }, [])

  return (
    <Header>
      <InfoContainer>
        <InfoLabel>Ciclo Básico</InfoLabel>
      </InfoContainer>
      <Info>
        {topicsMap.map((item, i) => {
          const Icon = item.icon
          return (
            <Topic to={`/flashcards/${item.slug}`} key={i}>
              <Icon size='35' color='#FFFFFF' />
              <span>{item.title}</span>
            </Topic>
          )
        })}
      </Info>
      <InfoContainer>
        <InfoLabel>Ciclo Clínico</InfoLabel>
      </InfoContainer>
      <Info>
        {clinicalMap.map((item, i) => {
          const Icon = item.icon
          return (
            <Topic to={`/flashcards/${item.slug}`} key={i}>
              <Icon size='35' color='#FFFFFF' />
              <span>{item.title}</span>
            </Topic>
          )
        })}
      </Info>
    </Header>
  )
}
