import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import mixpanel from "utils/mixpanel"
import useAppCtx from "hooks/useAppCtx"
import useLayoutCtx from "hooks/useLayoutCtx"

import useSimulationsCtx from "../../hooks/useSimulationsCtx"
import useUniversityCtx from 'hooks/useUniversityCtx'
import List from "../List"

export default function () {
  const ctx = useAppCtx()
  const history = useHistory()
  const { currCourse, currTopic } = useSimulationsCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()

  useEffect(() => {
    if (currTopic) {
      setLayoutState({ title: currTopic.name, subTitle: 'Laboratório Virtual', backLink: '/laboratorio-virtual' })
      mixpanel(ctx, 'Simulations_Topic', { "Topic_Name": currTopic.name, "University": universities.name })
    } else {
      setLayoutState({ title: 'Laboratório Virtual' })
      mixpanel(ctx, 'Simulations_Home', { "University": universities.name })
    }
    return (() => {
      setLayoutState({})
    })
  // eslint-disable-next-line
  }, [currTopic, setLayoutState])

  return (
    <>
      <List simulations={currTopic.simulations} history={history} course={currCourse} topic={currTopic}></List>
    </>
  )
}
