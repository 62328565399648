import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { ArrowRight } from "styled-icons/fa-solid"

import usePromise from "hooks/usePromise"
import useAppCtx from "hooks/useAppCtx"
import useLayoutCtx from "hooks/useLayoutCtx"
import CertificatesAPI from "datasources/certificates"
import ProgressCircle from "routes/Authenticated/Exercises/components/ProgressCircle"
import { getImgixUrl } from "utils/imgix"
import mixpanel from "utils/mixpanel"

import Lesson from "./Lesson"
import Modal from "./Modal"

function useCertificatesApi() {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    return CertificatesAPI.list(ctx)
  })

  return {
    topics: result && result.data && result.data.courses[0].topics,
    nameConfirmed: result && result.data && result.data.nameConfirmed,
    isFetching: !isResolved,
  }
}

const Topics = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const Topic = styled.div`
  width: 343px;
  margin-right: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
`

const TopicHeader = styled.div`
  background-color: #AAAAAA;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  height: 100px;
  padding: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  ${({ topicId, image }) => image && css`
    background-image: url("${getImgixUrl(`topics/${topicId}/image`, { w: 343, h: 100})}");
    background-position: center;
  `}
`
const CustomProgressCircle = styled(ProgressCircle)`
  margin-left: auto;
`

const Lessons = styled.div`
  background-color: white;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Actions = styled.div`
  background-color: #FFF;
  border-bottom: solid 1px #e8eaee;
  padding: 15px 20px;
`

const Button = styled.div`
  background-color: #1CACE3;
  color: #FFF;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
`

const ButtonLink = styled.a`
  background-color: #1CACE3;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
  display: block;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;
`

const Exercises = styled(Link)`
  border-bottom: solid 1px #e8eaee;
  color: #9da3a8;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 20px;
  padding: 15px 20px;
  user-select: none;
  width: 100%;
  text-decoration: none;

`

const ExercisesPercent = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  ${({ complete }) => complete && css`
    color: #4EC437;
  `}
`

const ArrowRightIcon = styled(ArrowRight)`
  align-self: center;
  color: #676a74;
  flex-shrink: 0;
  margin-left: 10px;
  ${({ isOpen }) => isOpen && css`
    transform: rotate(180deg);
  `}
`

export default function() {
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { topics, nameConfirmed, isFetching } = useCertificatesApi()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [justConfirmedName, setJustConfirmedName] = useState(false)

  useEffect(() => {
    setLayoutState({ title: 'Certificados' })
    return (() => {
      setLayoutState({})
    })
  }, [setLayoutState])

  useEffect(() => {
    mixpanel(ctx, "Certificates")
  }, [ctx])

  return (
    <>
      {isModalOpen && <Modal close={() => setIsModalOpen(false)} onSuccess={() => setJustConfirmedName(true)}/>}
      {!isFetching &&
        <Topics>
          {topics.map(topic =>
            <Topic key={topic.id}>
              <TopicHeader topicId={topic.id} image={topic.image}>
                {topic.name}
                <CustomProgressCircle
                  circleFill="rgba(0, 0, 0, .3)"
                  percent={topic.watchedPercentage}
                  text={`${topic.watchedPercentage}%`}
                />
              </TopicHeader>
              {topic.watchedPercentage === 100 && (!topic.exercises || topic.exercises.percentage === 100) && <Actions>
                {!justConfirmedName && !nameConfirmed &&
                  <Button onClick={() => setIsModalOpen(true)}>Solicitar Certificado</Button>
                }
                {(!!justConfirmedName || !!nameConfirmed) && !topic.certificateIssued &&
                  "Certificado em produção."
                }
                {(!!justConfirmedName || !!nameConfirmed) && topic.certificateIssued &&
                  <ButtonLink target="_blank" href={`https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/certificates/${ctx.auth.user.id}/${topic.id}/certificate.pdf`}>
                    Baixar Certificado
                  </ButtonLink>
                }
              </Actions>}
              <Lessons>
                {topic.exercises && <Exercises to={`/exercicios/5/disciplinas/${topic.id}`}>
                  Exercícios
                  <ExercisesPercent complete={topic.exercises.percentage === 100}>
                    {topic.exercises.percentage}%
                  </ExercisesPercent>
                  <ArrowRightIcon size="10"/>
                </Exercises>}
                {topic.lessons.map(lesson =>
                  <Lesson
                    key={lesson.id}
                    topicId={topic.id}
                    lesson={lesson}
                  />
                )}
              </Lessons>
            </Topic>
          )}
        </Topics>
      }
    </>
  )
}