import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import CloseSVG from "components/icons/CloseSVG"
import FlexRow from "components/FlexRow"
import FlexColumn from "components/FlexColumn"

import useCoursesCtx from "../../hooks/useCoursesCtx"
import Lesson from "../../components/Lesson"


const Container = styled(FlexColumn)`
  background-color: #f2f7fa;
  border-radius: 10px;
  padding: 40px;
  @media only screen and (max-width: 565px) {
    padding: 20px
  }
`

const Header = styled(FlexRow)`
`

const Title = styled.h2`
  color: #1CACE3;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
`

const Close = styled(Link)`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: solid 1px #d7dde2;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  & > svg {
    fill: #9da3a8;
  }
  @media only screen and (max-width: 565px) {
    display: none;
  }
`

const Lessons = styled(FlexRow)`
  flex-grow: 0;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`

const CustomLesson = styled(Lesson)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 15px;
  margin-top: 15px;
  @media only screen and (max-width: 565px) {
    width: 100%;
  }
`

export default function() {
  const { currCourse, currTopic } = useCoursesCtx()

  return(
    <Container>
      <Header>
        <Title>
          {currTopic.name}
        </Title>
        <Close to={`/videos/${currCourse.id}`}>
          <CloseSVG/>
        </Close>
      </Header>
      <Lessons>
        {currTopic.lessons.map(lesson => (
          <CustomLesson key={lesson.id} topicId={currTopic.id} {...lesson}/>
        ))}
      </Lessons>
    </Container>
  )
}