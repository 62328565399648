import React from "react"
import styled from "styled-components"

import FlexRow from 'components/FlexRow'
import FlexColumn from "components/FlexColumn"

import usePlaylistsCtx from '../../hooks/usePlaylistsCtx'
import Playlists from '../../components/Playlists'
import PlaylistItemType from '../../components/PlaylistItemType'
import NotFound from './notFound.js'


const Container = styled(FlexRow)`
  background-color: #f5f9fa;
  padding: 30px 0;
  border-radius: 4px;
  @media only screen and (max-width: 750px) {
    margin: 0 -10px;
    padding: 15px 10px;
    flex-direction: column-reverse;
    width: 100%;
    border-left: none;
    box-sizing: content-box;
  }
`

const SideContainer = styled(FlexRow)`
  max-width: 350px;
  @media only screen and (max-width: 750px) {
    max-width: none;
    width: 100%;
  }
`

const MainContainer = styled(FlexColumn)`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7px;
  @media only screen and (max-width: 750px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
`

export default function ({ history }) {
  const { currPlaylistType,
    currPlaylist,
    currPlaylistItemType, } = usePlaylistsCtx()

  return (
    <div>
      {currPlaylistType !== undefined  && (
        <Container>
          <SideContainer>
            <Playlists playlists={currPlaylistType.playlists} />
          </SideContainer>
          <MainContainer>
            {currPlaylistItemType &&
              <PlaylistItemType playlist={currPlaylist} itemType={currPlaylistItemType} history={history} />
            }
          </MainContainer>
        </Container>
      )}

      { currPlaylistType === undefined && (
        <NotFound />
      )}
    </div>
  )
}
