import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import CoreBtn from './Btn'
// import useClinicalCaseCtx from '../../hooks/useClinicalCaseCtx'

const Overlay = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  height: 472px;
  left: 0;
  padding: 48px;
  position: absolute;
  z-index: 99999;
  top: 0;
  width: 880px;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    padding:15px;
    height:100%;
    max-width: 100%;
  }
`

const Bg = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  ${({ isCorrect }) =>
    isCorrect &&
    css`
      border-left: 20px solid #41ad49;
    `}
  ${({ isCorrect }) =>
    !isCorrect &&
    css`
      border-left: 20px solid #e44061;
    `}
`

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  ${({ isCorrect }) =>
    isCorrect &&
    css`
      color: green;
    `}
  ${({ isCorrect }) =>
    !isCorrect &&
    css`
      color: red;
    `}

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    font-size: 20px;
  }

`

const Message = styled.div`
  font-size: 16px;
  margin-bottom: 16px;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    font-size: 14px;
  }

`

const Btn = styled(CoreBtn)`
  margin-top: auto;
  ${({ isCorrect }) =>
    isCorrect &&
    css`
      color: white;
      background-color: #41ad49;
    `}
  ${({ isCorrect }) =>
    !isCorrect &&
    css`
      color: white;
      background-color: #e44061;
    `}
`
const Img = styled.img`
  max-width: 100%;
`

function Modal(props) {
  // const { caseFile } = useClinicalCaseCtx()
  // const imgAnswer = props.isCorrect ? 'check.png' : 'closeInc.png'
  return (
    props.visible && (
      <Overlay>
        <Bg isCorrect={props.isCorrect}>
          <Title isCorrect={props.isCorrect}>
            {props.isCorrect ? 'Certo!' : 'Incorreto ...'}
            <Img
              src={`${process.env.PUBLIC_URL}/file-imgs/${props.isCorrect ? 'check.png' : 'closeInc.png'}`}
            />
          </Title>
          <Message>
            <ReactMarkdown>{props.message}</ReactMarkdown>
          </Message>
          <Btn onClick={props.btnCb} isCorrect={props.isCorrect}>
            {props.isCorrect ? 'Avançar' : 'Tentar Novamente'}
          </Btn>
        </Bg>
      </Overlay>
    )
  )
}

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string,
  isCorrect: PropTypes.bool,
  btnCb: PropTypes.func,
}

Modal.defaultProps = {
  visible: false,
}

export default Modal
