import React from "react";

export default function LogoSVG({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="109.783px" height="130.435px" viewBox="0 0 109.783 130.435" display="block">
      <path fillRule="evenodd" clipRule="evenodd" fill="#00B0E2" d="M69.482,124.381c-9.657,2.859-19.486,2.795-28.535,0.316
        c1.682-0.582,3.337-1.211,4.955-1.89c0.837-0.346,1.658-0.71,2.47-1.086c7.087,1.089,14.52,0.666,21.85-1.506
        c26.318-7.793,41.458-35.224,34.179-61.57c0.094,0.299,0.191,0.602,0.277,0.902C112.863,87.172,97.104,116.197,69.482,124.381"/>
      <path fillRule="evenodd" clipRule="evenodd" fill="#00B0E2" d="M31.578,77.75c2.098-2.246,4.393-4.338,6.81-6.311
        c7.329,1.389,14.333,5.604,21.171,11.572c-6.346-7.062-11.668-12.004-17.048-14.764c2.388-1.763,5.95-6.317,5.253-6.904
        c-2.908,1.809-5.834,3.602-8.678,5.451c-2.074,1.349-4.105,2.72-6.046,4.151c-3.468,2.546-6.654,5.261-9.327,8.288
        c-2.136,2.415-3.942,5.029-5.3,7.916c-0.461,0.98-0.873,2-1.225,3.048c-0.127,0.372-0.243,0.746-0.348,1.119
        c-0.091,0.3-0.173,0.597-0.246,0.894c-0.077,0.299-0.145,0.599-0.21,0.897c-0.009,0.042-0.018,0.084-0.027,0.126
        c-0.058,0.278-0.112,0.555-0.161,0.832c-0.002,0.014-0.005,0.025-0.007,0.037c-0.002,0.011-0.003,0.021-0.005,0.031
        c-0.026,0.152-0.051,0.303-0.074,0.453c-0.002,0.015-0.004,0.027-0.006,0.041c-0.005,0.028-0.008,0.057-0.012,0.086
        c-0.022,0.144-0.043,0.287-0.062,0.432c-0.016,0.125-0.03,0.251-0.044,0.377c-0.012,0.105-0.025,0.213-0.035,0.32l-0.001,0.005
        c-0.016,0.165-0.031,0.334-0.044,0.502c-0.001,0.014-0.001,0.028-0.002,0.042c-0.006,0.072-0.01,0.145-0.015,0.216
        c-0.008,0.121-0.017,0.243-0.022,0.366c-0.001,0.023-0.001,0.046-0.002,0.068c-0.013,0.285-0.023,0.568-0.026,0.854
        c-0.018,1.673,0.147,3.359,0.496,5.094c-3.79-4.836-6.745-10.451-8.595-16.697c-7.797-26.317,6.961-53.952,33.018-62.198
        c-0.3,0.083-0.605,0.166-0.908,0.256C12.229,32.532-3.531,61.56,4.65,89.182c2.935,9.908,8.552,18.291,15.827,24.586
        c0.706,1.3,1.49,2.643,2.358,4.025c-0.541-1-0.987-2.064-1.346-3.176c-0.74-2.277-1.123-4.744-1.253-7.205
        c-0.067-1.266-0.072-2.529-0.016-3.762c0.08-1.939,0.292-3.804,0.579-5.488c0.156-0.91,0.333-1.762,0.522-2.549
        c1.13-0.35,2.284-0.63,3.473-0.839c11.045-1.942,21.277,3.021,31.156,11.649C44.056,93.192,35.777,87.398,23.939,89.481
        c-0.16,0.028-0.319,0.06-0.478,0.09c0.932-2.028,2.03-3.95,3.26-5.769c0.564-0.135,1.131-0.25,1.707-0.354
        c11.048-1.945,21.278,3.02,31.16,11.645C49.078,83.407,41.392,77.527,31.578,77.75"/>
      <path fillRule="evenodd" clipRule="evenodd" fill="#fff" d="M99.272,61.906c-0.078-0.264-0.163-0.524-0.244-0.788
        c6.358,23.022-6.871,46.992-29.867,53.806c-5.139,1.521-10.335,2.06-15.389,1.725c7.827-4.688,13.889-10.758,16.358-18.094
        c5.401-16.061-6.215-27.838-15.368-38.578c7.428-4.792,14.51-9.333,19.432-14.586c1.298-1.387,2.447-2.821,3.412-4.325
        c0.979-1.524,1.767-3.116,2.331-4.796c0.055-0.163,2.052-5.159,1.004-11.565c-0.305-1.863-0.897-3.725-1.699-5.635
        C77.1,13.956,73.295,8.536,67.988,2.08c3.639,5.715,6.298,11.368,7.529,16.969c-8.606,0.659-15.924,6.356-25.656,16.8
        c8.501-7.165,17.211-11.69,26.373-11.601c0.076,1.417,0.05,2.827-0.086,4.237c-0.034,0.36-0.079,0.719-0.128,1.079
        c-8.479-0.555-16.532,3.925-27.63,12.606c9.019-5.446,17.852-8.521,26.442-7.534c-0.684,2.035-1.579,3.936-2.659,5.732
        c-6.585-0.045-14.005,2.587-23.67,7.207c7.351-2.479,14.323-3.728,20.754-3.086c-4.244,5.194-10.005,9.464-16.178,13.486
        c-5.538-6.704-9.605-13.079-7.298-19.939c1.289-3.834,4.731-6.703,7.869-9.241c-4.555,2.732-10.005,5.507-11.66,10.428
        c-3.618,10.755,7.44,20.396,12.869,28.408c6.161,9.092,10.103,19.426,6.436,30.33c-2.572,7.631-7.641,13.269-14.403,17.676
        c-1.506,0.983-3.096,1.908-4.764,2.777c-0.782,0.407-1.577,0.805-2.391,1.192c-1.908,0.903-3.901,1.749-5.971,2.548
        c-5.678,2.2-11.916,4.042-18.463,5.759c7.278-0.446,15.062-1.868,22.442-4.202c2.454-0.777,4.866-1.656,7.198-2.634
        c0.985-0.41,1.953-0.84,2.904-1.286c6.681,1.155,13.725,0.827,20.666-1.229C92.652,111.41,106.424,86.047,99.272,61.906"/>
      </svg>
  )
}