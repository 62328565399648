import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useClinicalCaseCtx from '../../hooks/useClinicalCaseCtx'
import * as Core from '../Core'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const MainCol = styled(Core.MainCol)`
  padding: 0px;
  box-shadow: 0px 0px 3px -1px white;
  @media only screen and (max-width: 896px) and (orientation: landscape) {
    margin-top: 0;
    position: inherit;
    height: 80vh;
  }
`

// const Title = styled(Core.Title)`
// `

// const Contents = styled.span`
//   line-height: 22px;
//   text-align: justify;
//   font-weight: 500;

//   @media only screen and (max-width: 812px) and (orientation: landscape) {
//     & > p, & > ul {
//       font-size:0.8em;
//     }
//     & > h3 {
//       font-size:1em;
//     }
//   }
// `

const Img = styled.img`
  max-width: 100%;
  align-self: flex-end;
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    position: absolute;
    margin: -37px -1px;
  }
  @media only screen and (max-width: 896px) and (orientation: landscape) {
    position: absolute;
    margin: -37px -1px;
    width: 100%;
  }
`

function CaseIntro(props) {
  const { clinicalCase } = useClinicalCaseCtx()
  const [modalInfoProps] = useState({ visible: false })

  return (
    <Core.Frame
      backAmount={props.backAmount}
      nextAmount={props.nextAmount}
      modalInfoProps={modalInfoProps}
      intro={0}
    >
      <Wrapper>
        <MainCol>
          {props.image === true && (
            <Img
              src={`https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/clinical-case/${clinicalCase.id}/case-frame/${props.id}/image`}
            />
          )}
        </MainCol>
      </Wrapper>
    </Core.Frame>
  )
}

CaseIntro.propTypes = {
  backAmount: PropTypes.number.isRequired,
  nextAmount: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
}

CaseIntro.defaultProps = {
  backAmount: 1,
  nextAmount: 0,
  hasInfo: 0,
}

export default CaseIntro
