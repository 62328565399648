import styled from "styled-components"
import TextareaComponent from "routes/Anonymous/components/Textarea"
import SubmitComponent from "routes/Anonymous/components/Submit"

export const PostContainer = styled.div`
  margin-bottom: 15px;
`
export const RepliesContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
export const Header = styled.div`
  margin-bottom: 20px;
`
export const Title = styled.div`
  color: #003558;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.125;
`

export const Textarea = styled(TextareaComponent)`
  font-family: 'Montserrat', sans-serif;
  min-height: 60px;
`

export const Field = styled.div`
  background: white;
  border-radius: 8px;
  border: 1px solid rgb(227,227,227);
  margin-bottom: 10px;
`
export const ReplyContainer = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(227,227,227);
  display: flex;
  flex-direction: column;
`

export const Submit = styled(SubmitComponent)``

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`