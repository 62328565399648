import React from "react"
import styled from "styled-components"

import FlexRow from "components/FlexRow"

const Background = styled.div`
  background-color: #1CACE3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='40' viewBox='0 0 50 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23bce0fd' fill-opacity='0.05'%3E%3Cpath d='M40 10L36.67 0h-2.11l3.33 10H20l-2.28 6.84L12.11 0H10l6.67 20H10l-2.28 6.84L2.11 10 5.44 0h-2.1L0 10l6.67 20-3.34 10h2.11l2.28-6.84L10 40h20l2.28-6.84L34.56 40h2.1l-3.33-10H40l2.28-6.84L47.89 40H50l-6.67-20L50 0h-2.1l-5.62 16.84L40 10zm1.23 10l-2.28-6.84L34 28h4.56l2.67-8zm-10.67 8l-2-6h-9.12l2 6h9.12zm-12.84-4.84L12.77 38h15.79l2.67-8H20l-2.28-6.84zM18.77 20H30l2.28 6.84L37.23 12H21.44l-2.67 8zm-7.33 2H16l-4.95 14.84L8.77 30l2.67-8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  color: #FFFFFF;
  line-height: 1.5;
  min-height: 1vh;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  height:100vh;
  overflow-y:auto;

  @media only screen and (max-width: 565px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

const Container = styled(FlexRow)`
  align-items: center;
  ${'' /* height: 100%; */}
  justify-content: center;

  @media only screen and (max-height: 768px) {
    align-items: stretch;
    height: 1000px;
  }
`

const HTMLForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
`

export default function Form({ children, onSubmit }) {
  return (
    <Background>
      <Container>
        <HTMLForm onSubmit={onSubmit}>
          {children}
        </HTMLForm>
      </Container>
    </Background>
  )
}