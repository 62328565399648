import React from "react"

import ClinicalCases from "./components/ClinicalCases"
import { Provider } from "./contexts/ClinicalCases"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <ClinicalCases match={match} history={history}/>
    </Provider>
  )
}