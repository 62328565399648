import React from "react"
import styled from "styled-components"
import FlexRow from 'components/FlexRow'
import TopicCard from 'components/TopicCard'
import usePlaylistItemssCtx from "../../../hooks/usePlaylistItemssCtx"

const ItemTypes = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 0 -15px;
  > * {
    margin: 15px 15px;
  }
`

const CustomTopicCard = styled(TopicCard)`
  height: 220px;
  box-shadow: 1px 2px 3px 0px #967f7f;
  @media only screen and (max-width: 565px) {
    width: 100%;
  }
`

function Questions() {
  const {
    playlistItems,
  } = usePlaylistItemssCtx()

  return (
    <ItemTypes>
      { playlistItems.items.map((item) => {
        return (
          <CustomTopicCard
            key={item.id}
            to={item.uri}
            topic={item.topic}
          >
            {item.title}
          </CustomTopicCard>
        )
      })}
    </ItemTypes>
  )
}

export default Questions
