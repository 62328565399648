import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import useClinicalCaseCtx from '../../hooks/useClinicalCaseCtx'
import * as Core from '../Core'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    align-items: stretch;
  }
`

const LeftCol = styled(Core.LeftCol)`
`

const RightCol = styled(Core.RightCol)`
`

const Img = styled.img`
  max-width: 100%;
`

const Contents = styled.div`
  margin-bottom: 16px;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    & > p, & > ul {
      font-size:0.8em;
    }
  }
`

const MainCol = styled(Core.MainCol)`
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    margin: 0;
  }
`

const Title = styled(Core.Title)`
`

const BtnAnswer = styled(Core.Btn)`
  width: 200px;
  margin-bottom: 8px;
  font-size: 14px;
  padding-top: 3px;
  margin-right: 0;
  background-color: #fff9ec;
  box-shadow: 0px 3px 4px -3px black;
  border: 1px solid #f9efef;
  &:hover {
    background-color: #c0f9df;
  }

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    height: auto;
    margin-left:0;
  }
`

function Question2Answers(props) {
  const { clinicalCase, next } = useClinicalCaseCtx()
  const [modalProps, setModalProps] = useState({ visible: false })

  function answerClicked(answer) {
    answer.nextAmount = 1
    setModalProps({
      visible: true,
      message: answer.explanation,
      isCorrect: answer.isCorrect,
      btnCb: () => {
        if (answer.isCorrect) return setModalProps({ visible: false }) || next(answer.nextAmount)

        return setModalProps({ visible: false })
      },
    })
  }

  return (
    <Core.Frame
      modalProps={modalProps}
      backAmount={props.backAmount}
      nextAmount={0}
    >
      <Wrapper>
        {props.image !== true && (
          <MainCol>
            <Title>
              <ReactMarkdown>{props.title}</ReactMarkdown>
            </Title>
            <Contents>
              <ReactMarkdown>{props.content}</ReactMarkdown>
            </Contents>
            {props.caseFramesAnswers.map((answer, index) => {
              return (
                <BtnAnswer onClick={() => answerClicked(answer)} key={answer.id}>
                  {answer.content}
                </BtnAnswer>
              )
            })}
          </MainCol>
        )}
        {props.image === true && (
          <LeftCol>
            <Title>
              <ReactMarkdown>{props.title}</ReactMarkdown>
            </Title>
            <Contents>
              <ReactMarkdown>{props.content}</ReactMarkdown>
            </Contents>
            {props.caseFramesAnswers.map((answer, index) => {
              return (
                <BtnAnswer onClick={() => answerClicked(answer)} key={answer.id}>
                  {answer.content}
                </BtnAnswer>
              )
            })}
          </LeftCol>
        )}
        {props.image === true && (
          <RightCol>
            <Img
              src={`https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/clinical-case/${clinicalCase.id}/case-frame/${props.id}/image`}
            />
          </RightCol>
        )}
      </Wrapper>
    </Core.Frame>
  )
}

Question2Answers.propTypes = {
  backAmount: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
}

Question2Answers.defaultProps = {
  backAmount: 1,
  nextAmount: 0,
}

export default Question2Answers
