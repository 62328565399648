import React, { useEffect, useMemo, createContext } from "react"

import VideosAPI from "datasources/videos"
import usePromise from "hooks/usePromise"
import useAppCtx from "hooks/useAppCtx"
import byId from "utils/byId"

const Context = createContext()

function useCoursesList() {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    console.log("Calling Videos API")
    return VideosAPI.list(ctx)
  })

  return {
    courses: result && result.data,
    isFetchingCourses: !isResolved,
  }
}

export function Provider({ children, history, match }) {
  const { courseId, topicId } = match.params

  const {
    courses,
    isFetchingCourses
} = useCoursesList()

  useEffect(() => {
    if (courses && !courseId) {
      history.replace(`/videos/${courses[0].id}`)
    }
  }, [history, courses, courseId])

  const currCourse = useMemo(() => {
    if (courses) {
      return courses.find(byId(courseId))
    }
  }, [courses, courseId])

  const currTopic = useMemo(() => {
    if (currCourse) {
      return currCourse.topics.find(byId(topicId))
    }
  }, [currCourse, topicId])

  const value = {
    courses,
    currCourse,
    currTopic,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingCourses && courseId && children}
    </Context.Provider>
  )
}

export default Context