import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'

import FlexRow from 'components/FlexRow'

const Container = styled.div`
  margin-bottom: 25px;
  width: 100%;
`

const Pill = styled(FlexRow)`
  background-color: #ffffff;
  border: solid 1px transparent;
  color: #bce0fd;
  fill: #bce0fd;
  height: 50px;
  padding: 15px;
  stroke: #bce0fd;
  width: 100%;
  &:focus-within {
    color: #1cace3;
    fill: #1cace3;
    stroke: #1cace3;
  }
  > svg {
    flex-shrink: 0;
    margin-left: auto;
    height: 20px;
    ${({ iconWidth }) => css`
      width: ${iconWidth}px;
    `}
    align-self: center;
  }
  ${({ hasError }) =>
    hasError &&
    css`
      border: solid 1px #ff595e;
    `}
`

const HTMLInput = styled.input`
  box-sizing: border-box;
  background: none;
  border: none;
  color: #1cace3;
  font-size: 16px;
  height: 20px;
  min-height: 20px;
  left: 0;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 0;
  padding-right: 20px;
  top: 0;
  width: 100%;
  &::placeholder {
    color: #bce0fd;
  }
  &:focus::placeholder {
    color: #1cace3;
  }
`

const HTMLMaskInput = styled(InputMask)`
  box-sizing: border-box;
  background: none;
  border: none;
  color: #1cace3;
  font-size: 16px;
  height: 20px;
  min-height: 20px;
  left: 0;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 0;
  padding-right: 20px;
  top: 0;
  width: 100%;
  &::placeholder {
    color: #bce0fd;
  }
  &:focus::placeholder {
    color: #1cace3;
  }
`

const Error = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffca3a;
  margin-top: 5px;
`

export default function Input({
  id,
  placeholder,
  onChange,
  value,
  icon: Icon,
  iconWidth = 20,
  autoFocus,
  mask,
  errors = [],
  type = 'text',
  className,
}) {
  const hasError = useMemo(() => errors && errors.length > 0, [errors])

  const InputComponent = useMemo(() => (mask ? HTMLMaskInput : HTMLInput), [
    mask,
  ])

  return (
    <Container className={className}>
      <Pill hasError={hasError} iconWidth={iconWidth}>
        <InputComponent
          autoFocus={autoFocus}
          placeholder={placeholder}
          id={id}
          onChange={onChange}
          value={value}
          type={type}
          mask={mask}
          maskPlaceholder={null}
          iconWidth={iconWidth}
        />
        {Icon && <Icon />}
      </Pill>
      {hasError && <Error>{errors[0]}</Error>}
    </Container>
  )
}
