import React, { useEffect, useMemo, createContext } from "react"

import ClinicalCasesAPI from "datasources/clinicalCases"
import usePromise from "hooks/usePromise"
import useAppCtx from "hooks/useAppCtx"
import byId from "utils/byId"

const Context = createContext()

function useCaseGroupsList() {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function(){
    return ClinicalCasesAPI.list(ctx)
  })

  return {
    caseGroups: result && result.data,
    isFetchingCaseGroups: !isResolved,
  }
}

export function Provider({ children, history, match }) {
  const { caseGroupId } = match.params

  const {
    caseGroups,
    isFetchingCaseGroups
  } = useCaseGroupsList()

  const currCaseGroup = useMemo(() => {
    if (caseGroups) {
      return caseGroups.find(byId(caseGroupId))
    }
  }, [caseGroups, caseGroupId])

  useEffect(() => {
    if (caseGroups && !caseGroupId) {
      if (caseGroups.length > 0)
        history.replace(
          `/estudos-de-caso/${caseGroups[0].id}`
        )
      else
        history.replace(
          `/estudos-de-caso/0`
        )
    }
  }, [history, caseGroups, caseGroupId])

  const value = {
    caseGroups,
    currCaseGroup,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingCaseGroups && caseGroupId && children}
    </Context.Provider>
  )
}

export default Context