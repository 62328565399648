import { Atom, Dna, Flask, Skull, Capsules, UserNurse, Bone, UserMd, Syringe, Microscope, Bacon } from "styled-icons/fa-solid"

export const topicsMap  = [
  {
    id: 1,
    title: 'Biologia',
    slug: 'biology',
    icon: Atom,
  },
  {
    id: 2,
    title: 'Genética',
    slug: 'genetics',
    icon: Dna,
  },
  {
    id: 3,
    title: 'Bioquímica',
    slug: 'biochemistry',
    icon: Flask,
  },
  {
    id: 4,
    title: 'Anatomia',
    slug: 'anatomy',
    icon: Skull,
  },
  {
    id: 5,
    title: 'Farmacologia',
    slug: 'pharmacology',
    icon: Capsules,
  },
  {
    id: 6,
    title: 'Imunologia',
    slug: 'immunology',
    icon: Syringe,
  },
  {
    id: 7,
    title: 'Microbiologia',
    slug: 'microbiology',
    icon: Microscope,
  },
  {
    id: 8,
    title: 'Parasitologia',
    slug: 'parasitology',
    icon: Bacon,
  },
]

export const clinicalMap = [
  {
    id: 1,
    title: 'Enfermagem',
    slug: 'nursing',
    icon: UserNurse,
  },
  {
    id: 2,
    title: 'Fisioterapia',
    slug: 'physicalTherapy',
    icon: Bone,
  },
  {
    id: 3,
    title: 'Medicina',
    slug: 'medicine',
    icon: UserMd,
  },
]

export default { topicsMap, clinicalMap }