import React, { useReducer, useCallback, createContext } from "react"

function reducer(state, action) {
  switch (action) {
    case "inc":
      return state + 1
    case "dec":
      return state - 1
    default:
      return state
  }
}

const Context = createContext()

export function Provider({ children }) {
  const [count, dispatch] = useReducer(reducer, 0)

  const value = {
    count,
    inc: useCallback(() => dispatch("inc"), []),
    dec: useCallback(() => dispatch("dec"), []),
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default Context