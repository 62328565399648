// TODO - update default breakpoints
export const breakpoints = {
  desktopHD: '1200px',
  desktop: '992px',
  tablet: '768px',
  phone: '576px',
}

// TODO - add default breakpoints
export const media = {
  min: breakpoint => `@media (min-width: ${breakpoint})`,
  max: breakpoint => `@media (max-width: ${breakpoint})`,
  between: (min, max) => `@media (max-width: ${max}) and (min-width: ${min})`,
}
