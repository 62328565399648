import React from "react"
import styled from "styled-components"

const CheckBoxWrapper = styled.div`
  position: relative;
`

const CheckBoxWrapperLeft = styled(CheckBoxWrapper)`
  float: left;
`

const CheckBoxWrapperRight = styled(CheckBoxWrapper)`
  float: right;
  padding-right: 70px;
`

const CheckBoxSlider = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3.3px 3px 3px 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxSlider} {
    background: #044c74;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`

const CheckboxLabel = styled.label`
  position: absolute;
  padding-top: 0.2em;
`

export default function Switch({ id, label, callback, checked, floatType, ...rest}) {
  let content = <div>
                  <CheckBox id={id} onChange={e => callback()} type="checkbox" />
                  <CheckBoxSlider htmlFor={id}></CheckBoxSlider>
                  <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
                </div>

  let wrapper = <CheckBoxWrapper>{content}</CheckBoxWrapper>

  switch(floatType) {
    case 'left':
      return <CheckBoxWrapperLeft>{content}</CheckBoxWrapperLeft>;
    case 'right':
      return <CheckBoxWrapperRight>{content}</CheckBoxWrapperRight>;
    default:
      return (wrapper);
  }
}