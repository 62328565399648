import styled from 'styled-components'

const Btn = styled.div`
  align-items: center;
  background-color: #fff;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  width: auto;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
  line-height: 2.4em;
  padding-left: 1em;
  padding-right: 1em;
  color: #584141;
  font-weight: 600;
  border: 1px solid #cccccc;
`

export default Btn
