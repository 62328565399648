import React from "react"
import styled, { css } from "styled-components"
import FlexRow from 'components/FlexRow'
import FlexColumn from "components/FlexColumn"
import { Microscope, PencilAlt, Video, ClinicMedical } from "styled-icons/fa-solid"
import usePlaylistsCtx from '../../hooks/usePlaylistsCtx'

const Header = styled(FlexRow)`
  padding: 10px 0 10px 10px;
  @media only screen and (max-width: 750px) {
    border-right: none;
    padding: 5px 0;
  }
`

const Accordion = styled(FlexRow)`
  align-items: center;
  padding-right: 15px;
  & > svg {
    flex-shrink: 0;
  }
`

const HeaderText = styled(FlexColumn)`
  cursor: pointer;
  padding-left: 10px;

  @media only screen and (max-width: 750px) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  color: #676a74;
  font-weight: 600;

  ${({ selected }) => selected && css`
    color: #1cace3;
  `}
`

const ItemTypes = styled(FlexColumn)`
  display: flex;
  padding-bottom: 20px;
`

const ItemType = styled(FlexRow)`
  color: #9da3a8;
  font-size: 14px;
  line-height: 32px;
  padding-left: 36px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  & svg {
    flex-shrink: 0;
    fill: #676a74;
  }

  ${({ selected }) => selected && css`
    color: #1cace3;
    & svg {
      fill: #1cace3;
    }
  `}

  @media only screen and (max-width: 750px) {
    padding-left: 15px;
  }
`

const Ellipsis = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  display: inline-block;
  padding-right: 25px;
`

const Main = styled(FlexColumn)`
  @media only screen and (max-width: 750px) {
    border-top: solid 1px #e8eaee;
    padding: 15px 0 0;
  }
`

const PencilAltIcon = styled(PencilAlt)`
  align-self: center;
  margin-right: 10px;
`

const VideoIcon = styled(Video)`
  align-self: center;
  margin-right: 10px;
`

const ClinicMedicalIcon = styled(ClinicMedical)`
  align-self: center;
  margin-right: 10px;
`

const MicroscopeIcon = styled(Microscope)`
  align-self: center;
  margin-right: 10px;
`

const itemTypesMapper = {
  questions: { Icon: PencilAltIcon, props: { size: 16 } },
  subjects: { Icon: VideoIcon, props: { size: 15 } },
  clinicalCases: { Icon: ClinicMedicalIcon, props: { size: 16 } },
  simulations: { Icon: MicroscopeIcon, props: { size: 16 } },
}

export default function({playlist}) {
  const { currPlaylist, currPlaylistItemType, changePlaylist } = usePlaylistsCtx()

  const isPlaylistActived = currPlaylist && currPlaylist.id === playlist.id

  return (
    <FlexRow>
      <Main>
        <Header>
          <Accordion onClick={() => {changePlaylist(playlist)}}>
            <HeaderText >
              <Title selected={isPlaylistActived}>{playlist.name}</Title>
            </HeaderText>
          </Accordion>
        </Header>
        <ItemTypes>
          {playlist.itemTypes.map((itemType, j) => {
            const isItemActived = isPlaylistActived && (currPlaylistItemType && currPlaylistItemType.id === itemType.id)

            const { Icon = null, props = {} } = itemTypesMapper[itemType?.type] || {}

            return (
              <ItemType key={itemType.id} onClick={() => {changePlaylist(playlist, itemType)}} selected={isItemActived}>
                <Icon {...props} />
                <Ellipsis>{itemType.name} ({itemType.items.length})</Ellipsis>
              </ItemType>
            )
          })}
        </ItemTypes>
      </Main>
    </FlexRow>
  )
}