import React, { useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import { InfoCircle } from 'styled-icons/fa-solid'

import mixpanel from 'utils/mixpanel'
import FlexRow from 'components/FlexRow'
import useAppCtx from 'hooks/useAppCtx'
import useLayoutCtx from 'hooks/useLayoutCtx'

import CourseRoute from '../routes/Course'
import TopicRoute from '../routes/Topic'
import useCoursesCtx from '../hooks/useCoursesCtx'
import useUniversityCtx from 'hooks/useUniversityCtx'
import Tabs from 'components/Tabs'

const NavBar = styled(FlexRow)`
  @media only screen and (max-width: 565px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 566px) and (max-width: 1024px) {
    flex-wrap: wrap;
  }
`

const Courses = styled(Tabs)`
  height: 40px;
  margin-bottom: 40px;
`

const Course = styled(Tabs.item)`
  background-color: #E8EAEE;
  color: #044c74;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  margin-right: 0px;
  padding: 11px 20px;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  ${({ selected }) => selected && css`
    border-top-color: #044c74;
    border-top: 1px solid;
    background-color: #FFFFFF;
    color: #044c74;
    cursor: pointer;
  `}
  ${({ disabled }) => disabled && css`
    border-color: #9da3a8;
    background-color: #9da3a8;
    color: white;
    cursor: not-allowed;
  `}

  @media only screen and (min-width: 566px) and (max-width: 1024px) {
    display: flex;
    align-items: center;
    line-height: 20px;
  }
`

const TopicsDropdown = styled.select`
  -webkit-appearance: none;
  background-color: #fff;
  color: #044c74;
  display: block;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  max-width: 260px;
  outline: none;
  padding-left: 15px;
  padding-right: 45px;
  border: 1px solid #1cace3;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;

  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
  }

  @media only screen and (min-width: 566px) and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`

const Disclaimer = styled(FlexRow)`
  background-color: #1CACE3;
  color: #FFFFFF;
  padding: 10px 20px;
  margin-bottom: 40px;
  align-content: center;
  & > svg {
    margin-right: 10px;
    flex-shrink: 0;
  }
  @media only screen and (max-width: 565px) {
    padding: 5px 10px;
  }
`

export default function({ history }) {
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()

  const {
    courses,
    currCourse,
    currTopic,
  } = useCoursesCtx()

  useEffect(() => {
    mixpanel(ctx, 'Landing_Videos', { 'University': universities.name })
    // eslint-disable-next-line
  }, [])

  const courseChanged = useCallback(id => e =>
    history.push(`/videos/${id}`)
  , [history])

  const topicChanged = useCallback(e =>
    e.target.value ?
      history.push(`/videos/${currCourse.id}/disciplinas/${e.target.value}`) :
      history.push(`/videos/${currCourse.id}`)
  , [history, currCourse])

  useEffect(() => {
    setLayoutState({ title: 'Vídeos' })
    return (() => {
      setLayoutState({})
    })
  }, [setLayoutState])

  return (
    <>
      {currCourse && currCourse.message &&
        <Disclaimer>
          <InfoCircle size='20'/>
          {currCourse.message}
        </Disclaimer>
      }
      <NavBar>
        <Courses>
          {courses.map(course => {
            const isCurrCourse = currCourse.id === course.id
            return (
              <Course
                key={course.id}
                selected={isCurrCourse}
                disabled={!course.enabled}
                onClick={!isCurrCourse && course.enabled ? courseChanged(course.id) : undefined}
              >
                <Tabs.bullet />
                {course.name}
              </Course>
            )
          })}
        </Courses>
        <TopicsDropdown value={(currTopic && currTopic.id) || ''} onChange={topicChanged}>
          <>
            <option value=''>Todas Disciplinas</option>
            {currCourse.topics.map(topic => (
              topic.enabled && <option
                key={topic.id}
                value={topic.id}
              >
                {topic.name}
              </option>
            ))}
          </>
        </TopicsDropdown>
      </NavBar>
      <Switch>
        <Route exact path='/videos/:courseId/disciplinas/:topicId' component={TopicRoute}/>
        <Route exact path='/videos/:courseId' component={CourseRoute}/>
      </Switch>
    </>
  )
}