import React from "react"

export default function CloseSVG({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.711" height="18.71" viewBox="0 0 18.711 18.71">
      <g id="close" transform="translate(0 0)">
        <path id="União_3" data-name="União 3" d="M9.355,11.056,1.7,18.711,0,17.009,7.654,9.355,0,1.7,1.7,0,9.355,7.654,17.009,0l1.7,1.7L11.056,9.355l7.655,7.654-1.7,1.7Z"/>
      </g>
    </svg>
  )
}
