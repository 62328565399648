export default function getCourseName() {
  const course = localStorage.getItem('fieldId')

  const field = 'key:$1'.replace('$1', course)
  const fieldMap = {
    'key:1': 'Nutrição',
    'key:2': 'Medicina',
    'key:3': 'Biomedicina',
    'key:4': 'Fisioterapia',
    'key:5': 'Enfermagem',
    'key:6': 'Estética',
    'key:7': 'Educação Física',
    'key:8': 'Psicologia',
    'key:9': 'Farmácia',
    'key:10': 'Odontologia',
    'key:11': 'Biologia',
    'key:12': 'Med. Veterinária',
    'key:13': 'Outros',
  }
  return fieldMap[field] ? fieldMap[field] : null
}
