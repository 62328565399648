import React from "react"
import styled from "styled-components"
import { Link } from 'react-router-dom'
import FlexRow from 'components/FlexRow'
import { NotesMedical } from "styled-icons/fa-solid"
import usePlaylistItemssCtx from "../../../hooks/usePlaylistItemssCtx"

const ItemTypes = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 0 -15px;
  > * {
    margin: 15px 15px;
  }
`

const ItemType = styled(Link)`
  text-decoration: none;
  padding: 33px;
  width: 255px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  box-shadow: 2px 3px 2px 0px #545454;
  background-color: #632121;
  span {
    margin-left: 10px;
  }
  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    flex-flow: column;
    width: 150px;
    margin-right: 0;
    padding: 15px;
    span {
      margin-left: 0;
      font-size: 15px;
    }
  }
`

function ClinicalCases() {
  const {
    playlistItems,
  } = usePlaylistItemssCtx()

  return (
    <ItemTypes>
      { playlistItems.items.map((item) => {
        return (
          <ItemType key={item.id} to={item.uri}>
            <NotesMedical size="16" color="#FFFFFF"/>
            <span>{item.title}</span>
          </ItemType>
        )
      })}
    </ItemTypes>
  )
}

export default ClinicalCases
