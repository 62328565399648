import React, { useEffect, useMemo, createContext } from "react"

import BooksAPI from "datasources/books"
import usePromise from "hooks/usePromise"
import useAppCtx from "hooks/useAppCtx"
import byId from "utils/byId"

const Context = createContext()

function useBooksApi() {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    return BooksAPI.list(ctx)
  })

  return {
    courses: result && result.data,
    isFetchingCourses: !isResolved,
  }
}

export function Provider({ children, history, match }) {
  const { courseId, topicId, bookId } = match.params

  const {
    courses,
    isFetchingCourses
  } = useBooksApi()

  useEffect(() => {
    if (courses && !courseId) {
      history.replace(`/livros/${courses[0].id}`)
    }
  }, [history, courses, courseId])

  const currCourse = useMemo(() => {
    if (courses) {
      return courses.find(byId(courseId))
    }
  }, [courses, courseId])

  const currTopic = useMemo(() => {
    if (currCourse) {
      return currCourse.topics.find(byId(topicId))
    }
  }, [currCourse, topicId])

  const currBook = useMemo(() => {
    if (currTopic) {
      return currTopic.books.find(byId(bookId))
    }
  }, [currTopic, bookId])

  const value = {
    courses,
    currCourse,
    currTopic,
    currBook,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingCourses && courseId && children}
    </Context.Provider>
  )
}

export default Context