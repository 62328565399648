import { get, post, del, buildUrl } from "./common"

export default {
  async list(ctx) {
    return await get(ctx, buildUrl(`/public/posts`))
  },

  async get(ctx, { postId }) {
    return await get(ctx, buildUrl(`/public/posts/${postId}`))
  },

  async create(ctx, body) {
    return await post(ctx, buildUrl('/public/posts/'), { body })
  },

  async delete(ctx, { postId }) {
    return await del(ctx, buildUrl(`/public/posts/${postId}`))
  },

  async replies(ctx, { postId }) {
    return await get(ctx, buildUrl(`/public/replies?postId=${postId}`))
  },

  async createReply(ctx, body) {
    return await post(ctx, buildUrl('/public/replies'), { body })
  },

  async deleteReply(ctx, { replyId }) {
    return await del(ctx, buildUrl(`/public/replies/${replyId}`))
  },

  async topics(ctx) {
    return await get(ctx, buildUrl('/public/topics'))
  },

}