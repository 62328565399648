import React, { useEffect } from "react"
import styled from "styled-components"

import FlexRow from "components/FlexRow"
import FlexColumn from "components/FlexColumn"
import useAppCtx from "hooks/useAppCtx"
import mixpanel from "utils/mixpanel"

import useUniversityCtx from 'hooks/useUniversityCtx'
import useBooksCtx from "../../hooks/useBooksCtx"
import Book from "../../components/Book"

const Container = styled(FlexColumn)`
  background-color: #f2f7fa;
  border-radius: 10px;
  padding: 30px 40px;
  @media only screen and (max-width: 565px) {
    padding: 20px
  }
`

const Books = styled(FlexRow)`
  flex-grow: 0;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`

const CustomBook = styled(Book)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 15px;
  margin-top: 15px;
  @media only screen and (max-width: 565px) {
    width: 100%;
  }
`

export default function() {
  const ctx = useAppCtx()
  const { currCourse, currTopic } = useBooksCtx()
  const { universities } = useUniversityCtx()


  useEffect(() => {
    mixpanel(ctx, "Books_Topic_Detail", { "Topic_Name": currTopic.name, "University": universities.name })
  }, [ctx, currTopic, universities])

  return(
    <Container>
      <Books>
        {currTopic.books.map(book => (
          <CustomBook
            key={book.id}
            courseId={currCourse.id}
            topicId={currTopic.id}
            {...book}
          />
        ))}
      </Books>
    </Container>
  )
}