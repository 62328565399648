import React from "react"

import ClinicalCase from "../../components/ClinicalCase"
import { Provider } from "../../contexts/ClinicalCase"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <ClinicalCase match={match} history={history}/>
    </Provider>
  )
}