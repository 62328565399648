import { useReducer } from "react"
import validate from "validate.js"
import parse from "date-fns/parse"
import format from "date-fns/format"
import useSet from "./useSet"

function mapErrors(errorsMap = {}) {
  return function(errors) {
    return Object.entries(errors).reduce((acc, [k, v]) => {
      acc[k] = v.map(x => errorsMap[x] || `Erro Inesperado: ${x}`)
      return acc
    }, {})
  }
}

function createReducer(actionHandlers) {
  return (state, action) => {
    if (!actionHandlers[action.type]) {
      throw new Error("Invalid action")
    }

    return actionHandlers[action.type](state, action)
  }
}

const defaultOptions = { fullMessages: false }
validate.validators.presence.options = {message: "Campo obrigatório."};
validate.validators.format.options = {message: "Valor inválido."};
validate.extend(validate.validators.datetime, {
  parse: value => {
    const date = parse(value)
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
      0
    )
  },
  format: (value, options) => {
    const formatString = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss"
    return format(value, formatString)
  },
})

const reducer = createReducer({
  change: (state, action) => ({
    ...state,
    values: { ...state.values, ...action.payload },
  }),
  setAllErrors: (state, action) => ({ ...state, errors: action.payload }),
  setErrors: (state, action) => ({
    ...state,
    errors: { ...state.errors, ...action.payload },
  }),
  addError: (state, action) => ({
    ...state,
    errors: {
      ...state.errors,
      [action.payload.field]: [
        ...(state.errors[action.payload.field] || []),
        action.payload.message,
      ],
    },
  }),
})

const setToObj = set =>
  [...set].reduce((acc, curr) => ({ ...acc, [curr]: true }), {})

export function useForm(initialValues = {}, { constraints = {}, errorsMap = {} } = {}) {
  const initialState = { values: initialValues, errors: {} }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [dirty, dirtyOps] = useSet()
  const [touched, touchedOps] = useSet()

  const { errors, values } = state

  const setAllErrors = payload => dispatch({ type: "setAllErrors", payload })
  const setErrors = payload => dispatch({ type: "setErrors", payload })
  const addError = (field, message) =>
    dispatch({ type: "addError", payload: { field, message } })
  const change = payload => dispatch({ type: "change", payload })

  const onChange = k => e => {
    const v = e && e.target ? e.target.value : e
    v === initialState[k] ? dirtyOps.remove(k) : dirtyOps.add(k)
    touchedOps.add(k)
    // _validateField(k, v)
    change({ [k]: v })
  }

  const _validateField = (k, v) => {
    const validateErrors = validate(
      { [k]: v },
      { [k]: constraints[k] },
      defaultOptions
    )
    setErrors({ [k]: validateErrors ? validateErrors[k] : null })
    return !validateErrors
  }

  const validateField = k => {
    const v = values[k]
    touchedOps.add(k)
    return _validateField(k, v)
  }

  const onSubmit = fn => e => {
    e.preventDefault()

    const validateErrors = validate(values, constraints, defaultOptions)
    setAllErrors(validateErrors || {})
    !validateErrors && fn()
  }

  const fields = Object.entries(values).reduce(
    (prev, [k, v]) => ({
      ...prev,
      [k]: { name: k, value: v, errors: errors[k], onChange: onChange(k) },
    }),
    {}
  )

  const reset = () => {
    change(initialValues)
    setAllErrors({})
  }

  return {
    values,
    reset,
    errors,
    dirty: setToObj(dirty),
    touched: setToObj(touched),
    change,
    onChange,
    onSubmit,
    addError,
    setErrors,
    setAllErrors: x => setAllErrors(mapErrors(errorsMap)(x)),
    validateField,
    fields,
  }
}

export default useForm