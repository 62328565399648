import React, { useEffect, useMemo, createContext, useCallback } from "react"
import { useHistory } from "react-router-dom"
import seedrandom from "seedrandom"

import ExercisesAPI from "datasources/exercises"
import QuestionsAPI from "datasources/questions"
import TopicsAPI from "datasources/topics"
import useAppCtx from "hooks/useAppCtx"
import usePromise from "hooks/usePromise"
import byId from "utils/byId"

const Context = createContext()

function sortQuestions(questions, seed) {
  return questions.sort(x => seedrandom(`${x.id}${seed}`)() - 0.5)
}

function useExercise({ topicId, lessonsHash }) {
  const ctx = useAppCtx()

  const questions = usePromise(function() {
    return QuestionsAPI.get(ctx, { lessonsHash })
  })

  const topic = usePromise(function() {
    return TopicsAPI.get(ctx, topicId)
  })

  return {
    topic: topic.result && topic.result.data,
    questions: questions.result && questions.result.data,
    isFetchingExercise: !questions.isResolved || !topic.isResolved,
  }
}

export function Provider({ children, match }) {
  const ctx = useAppCtx()
  const history = useHistory()

  const {
    courseId,
    topicId,
    lessonsHash,
    seed,
    questionId,
  } = match.params

  const {
    topic,
    questions: _questions,
    isFetchingExercise,
  } = useExercise({ topicId, lessonsHash })

  const questions = useMemo(() => {
    return _questions && sortQuestions(_questions, seed)
  }, [_questions, seed])

  useEffect(() => {
    if (questions && !questionId) {
      history.replace(
        `/questoes/${courseId}/${topicId}/${lessonsHash}/${seed}/${questions[0].id}`
      )
    }
  }, [history, questions, courseId, topicId, lessonsHash, questionId, seed])

  const currQuestionIdx = useMemo(() => {
    if (questions && questionId) {
      return questions.findIndex(byId(questionId))
    }
  }, [questions, questionId])

  const currQuestion = useMemo(() => {
    return(
      questions &&
      questionId &&
      questions[currQuestionIdx]
    )
  }, [questions, questionId, currQuestionIdx])

  const nextQuestion = useMemo(() => {
    if (questions && questionId && currQuestionIdx + 1 !== questions.length) {
      return questions[currQuestionIdx + 1]
    }
  }, [questions, questionId, currQuestionIdx])

  const progress = useMemo(() => {
    if (questions && currQuestionIdx !== undefined) {
      return Math.trunc(currQuestionIdx / questions.length * 100)
    }
  }, [questions, currQuestionIdx])

  const nextOrFinish = useCallback(answeredCorrectly => {
    ExercisesAPI.put(ctx, { questionId: currQuestion.id, answeredCorrectly })

    if (nextQuestion) {
      history.replace(
        `/questoes/${courseId}/${topicId}/${lessonsHash}/${seed}/${nextQuestion.id}`
      )
    } else {
      history.push(
        `/exercicios/${courseId}/disciplinas/${topicId}`
      )
    }
  }, [ctx, seed, history, currQuestion, nextQuestion, courseId, topicId, lessonsHash])

  const value = {
    courseId,
    topic,
    questions,
    progress,
    currQuestion,
    nextOrFinish,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingExercise && currQuestion && children}
    </Context.Provider>
  )
}

export default Context