import React from "react"

import ItemsArea from './ItemsArea'
// import * as Items from '../../components/PlaylistItemType/Items'

import { Provider } from "../../contexts/PlaylistItems"

// function RenderItems(itemType, history) {
//   const itemList = useMemo(() => itemType)
//   const Item = useMemo(() => Items[itemType.type], [itemType.type])
//   return [<Item key={itemType.id} {...itemList} {...history}></Item>]
// }

export default function({playlist, itemType, history}) {
  return (
    <Provider key={`${playlist?.name}-${itemType?.name}`} playlist={playlist} itemType={itemType} history={history}>
      <ItemsArea />
    </Provider>
  )
}