import { useState } from "react"
 
export function useSet(initialState = []) { 
  const [data, setData] = useState(new Set(initialState)) 
  const replace = v => setData(new Set(v)) 
  const add = v => data.add(v) && setData(data) 
  const remove = v => data.delete(v) && setData(data) 
  const clear = () => setData(new Set()) 
  return [data, { add, remove, replace, clear }] 
} 
 
export default useSet