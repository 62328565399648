import styled from "styled-components"

export const Button = styled.div`
  background-color: #757575;
  color: #fff;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 25px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;
`

export default Button