import { get, buildUrl } from "./common"

export default {
  async list(ctx) {
    return await get(ctx, buildUrl(`/public/simulations`))
  },

  async get(ctx, { simulationId }) {
    return await get(ctx, buildUrl(`/public/simulations/${simulationId}`))
  },
}