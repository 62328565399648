import React, { createContext } from 'react'
import UniversityAPI from 'datasources/universities'
import usePromise from 'hooks/usePromise'
import useAppCtx from 'hooks/useAppCtx'

const Context = createContext()

function useUniversityApi() {
  const ctx = useAppCtx()

  const universities = usePromise(function () {
    return UniversityAPI.get(ctx)
  })

  return {
    universities: universities.result && universities.result.data,
    isFetchingUniversities: !universities.isResolved,
  }
}

export function Provider({ children }) {
  const { universities, isFetchingUniversities } = useUniversityApi()

  const value = {
    universities,
    isFetchingUniversities,
  }

  return (
    !isFetchingUniversities && (
      <Context.Provider value={value}>{children}</Context.Provider>
    )
  )
}

export default Context
