import { get, buildUrl } from "./common"

export default {
  async list(ctx) {
    return await get(ctx, buildUrl(`/public/certificates`))
  },

  async get(ctx, code) {
    return await get(ctx, buildUrl(`/public/certificates/${code}`), { disabledJWT: true })
  },
}