import React from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

import { getImgixUrl } from "utils/imgix"

const Container = styled(Link)`
  align-self: center;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  height: 210px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  width: 150px;
  ${({ bookId, image }) => image && css`
    background-image: url("${getImgixUrl(`books/${bookId}/image`, { w: 150, h: 210})}");
    background-size: 100% 100%;
  `};
  background-color: #AAAAAA;
`

export function Book({ id: bookId, courseId, topicId, image, className }) {
  return (
    <Container
      image={image}
      className={className}
      bookId={bookId}
      to={`/livros/${courseId}/disciplinas/${topicId}/${bookId}`}/>
  )
}

export default Book