import { post, put, buildUrl } from "datasources/common"

export default {
  async createToken(ctx, body) {
    return await post(ctx, buildUrl("/public/tokens"), { disabledJWT: true, body })
  },
  async createPasswordReset(ctx, body) {
    return await post(ctx, buildUrl("/public/reset-password-tokens"), { disabledJWT: true, body })
  },
  async updatePasswordReset(ctx, body) {
    return await put(ctx, buildUrl("/public/password"), { disabledJWT: true, body })
  },
}