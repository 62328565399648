import { post, put, buildUrl } from './common'

export const UsersAPI = {
  create(ctx, body) {
    return post(ctx, buildUrl('/public/users'), { body })
  },

  putName(ctx, body) {
    return put(ctx, buildUrl('/public/user/name'), { body })
  },
}

export default UsersAPI