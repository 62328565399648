import React, { useCallback, useMemo, createContext, useEffect } from "react"

import VideosAPI from "datasources/videos"
import MaterialsAPI from "datasources/materials"
import useApi from "hooks/useApi"
import useLayoutCtx from "hooks/useLayoutCtx"
import byId from "utils/byId"

const Context = createContext()

function useTopic(topicId) {
  const getTopic = useCallback(ctx => {
    return VideosAPI.get(ctx, topicId)
  }, [topicId])

  const {
    data: topic,
    isFetching: isFetchingTopic,
  } = useApi(getTopic)

  const getMaterials = useCallback(ctx => {
    return MaterialsAPI.get(ctx, topicId)
  }, [topicId])

  const {
    data: materials,
    isFetching: isFetchingMaterials,
  } = useApi(getMaterials)

  return {
    topic,
    materials,
    isFetchingTopic: isFetchingMaterials || isFetchingTopic,
  }
}

export function Provider({ children, history, match }) {
  const { topicId, lessonId, subjectId } = match.params

  const { setLayoutState } = useLayoutCtx()

  const {
    topic,
    materials,
    isFetchingTopic
  } = useTopic(topicId)

  const currLesson = useMemo(() => {
    if (topic) {
      return topic.lessons.find(byId(lessonId))
    }
  }, [topic, lessonId])

  const currSubject = useMemo(() => {
    if (currLesson) {
      return currLesson.subjects.find(byId(subjectId))
    }
  }, [currLesson, subjectId])

  useEffect(() => {
    if (currLesson && !subjectId) {
      history.replace(
        `/disciplinas/${topicId}/modulos/${currLesson.id}/assuntos/${currLesson.subjects[0].id}`
      )
    }
  }, [history, topicId, currLesson, subjectId])

  useEffect(() => {
    if (topic && currLesson) {
      const title = topic.name
      const subTitle = `Módulo ${currLesson.position+1} - ${currLesson.name}`
      setLayoutState({ title, subTitle, backLink: true })
    }
    return (() => {
      setLayoutState({})
    })
  }, [topic, currLesson, setLayoutState])

  const value = {
    topic,
    materials,
    currLesson,
    currSubject,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingTopic &&
        currLesson &&
        currSubject &&
        children
      }
    </Context.Provider>
  )
}

export default Context