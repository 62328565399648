import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { getImgixUrl } from 'utils/imgix'

const Container = styled(({ topicId, hasImage, ...rest }) => (
  <Link {...rest} />
))`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 300px;
  padding: 0 15px;
  text-decoration: none;
  width: 220px;
  ${({ topicId, image }) =>
    image &&
    css`
      background-image: url('${getImgixUrl(`topics/${topicId}/image`, {
        w: 220,
        h: 300,
      })}');
    `};
  background-color: #aaa;
`

const Name = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: auto;
  scroll-behavior: smooth;
  text-transform: uppercase;
`

export default function ({ topic, to, className }) {
  const { id, name, image } = topic
  return (
    <Container to={to} topicId={id} image={image} className={className}>
      <Name>{name}</Name>
    </Container>
  )
}
