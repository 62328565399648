import React from 'react'

import { Provider as LoaderProvider } from 'contexts/Loader'
import { Provider as AuthProvider } from 'contexts/Auth'
import { Provider as UniversityProvider } from 'contexts/University'

export default function ({ children }) {
  return (
    <LoaderProvider>
      <AuthProvider>
        <UniversityProvider>{children}</UniversityProvider>
      </AuthProvider>
    </LoaderProvider>
  )
}
