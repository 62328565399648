import React from "react"

import Courses from "./components/Courses"
import { Provider } from "./contexts/Courses"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <Courses match={match} history={history}/>
    </Provider>
  )
}