import React, { useMemo } from "react"
import styled from "styled-components"

const SVG = styled.svg`
  flex-shrink: 0;
`

const Circle = styled.circle`
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #D7DDE2;
  stroke-width: 4px;
`

const Bar = styled.circle`
  transition: stroke-dashoffset 1s linear;
  stroke: #1CACE3;
  stroke-width: 4px;
`

const Text = styled.text`
  fill: #9da3a8;
  font-size: 14px;
`

const radius = 32
const strokeDasharray = Math.PI * (radius * 2)

export default function({ text, percent, className, circleFill = "transparent" }) {
  const strokeDashoffset = useMemo(() => {
    const c = Math.PI * (radius * 2)
    return (( 100 - percent ) / 100) * c
  }, [percent])
  return (
    <SVG width="80" height="80" viewPort="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
      <Circle r={radius} cx="40" cy="40" fill={circleFill} strokeDasharray={strokeDasharray} strokeDashoffset="0"></Circle>
      <g transform="rotate(180 40 40)">
        <Bar style={{ strokeDashoffset: `${strokeDashoffset}` }} r={radius} cx="40" cy="40" fill="transparent" strokeDasharray={strokeDasharray} ></Bar>
      </g>
      <Text x="50%" y="50%" dy="5px" textAnchor="middle">{text}</Text>
    </SVG>
  )
}