import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  UserAlt,
  Check,
  AddressCard,
  CalendarAlt,
  Tag,
} from 'styled-icons/fa-solid'
import { CcMastercard, CcVisa } from 'styled-icons/fa-brands'

import useForm from 'hooks/useForm'
import useAppCtx from 'hooks/useAppCtx'
import usePromise from 'hooks/usePromise'
import SubscriptionsAPI from 'datasources/subscriptions'
import CouponsAPI from 'datasources/coupons'
import ProductOptionsAPI from 'datasources/productOptions'

import FlexRow from 'components/FlexRow'
import FlexColumn from 'components/FlexColumn'
import Logo from 'routes/Anonymous/components/Logo'
import Input from 'routes/Anonymous/components/Input'
import Form from 'routes/Anonymous/components/Form'
import Title from 'routes/Anonymous/components/Title'
import Subtitle from 'routes/Anonymous/components/Subtitle'
import Submit from 'routes/Anonymous/components/Submit'
import Button from 'routes/Anonymous/components/Button'
import AltAction from 'routes/Anonymous/components/AltAction'
import mixpanel from 'utils/mixpanel'
import fbq from 'utils/fbq'

const initial = {
  optionIdx: 1,
  identity: '',
  cardNum: '',
  cardName: '',
  cardCvv: '',
  cardExp: '',
  couponCode: '',
}

const constraints = {
  identity: {
    presence: { allowEmpty: false },
    format: /\d{3}.\d{3}.\d{3}-\d{2}/,
  },
  cardNum: { presence: { allowEmpty: false } },
  cardName: { presence: { allowEmpty: false } },
  cardCvv: { presence: { allowEmpty: false }, format: /[0-9]{3,4}/ },
  cardExp: { presence: { allowEmpty: false }, format: /\d{2}\/\d{2}/ },
}

const errorsMap = {
  chargeReturnCode05: 'Não Autorizada pelo Banco',
  chargeReturnCode51: 'Saldo Insuficiente',
  chargeReturnCode54: 'Cartão Expirado',
  chargeReturnCode57: 'Autorização Negada',
  chargeReturnCode70: 'Problemas com o Cartão de Crédito',
  chargeReturnCode77: 'Cartão Cancelado',
  chargeReturnCode78: 'Cartão Bloqueado',
  chargeReturnCode79: 'Falha de Comunicação',
  chargeReturnCodeXX: 'Bandeira Não Suportada',
  shouldBeValid: 'É Inválido',
}

const Wrapper = styled(FlexRow)``

const FormRow = styled(FlexRow)`
  display: block;
  @media only screen and (min-width: 565px) {
    display: flex;
    & > *:first-child {
      margin-right: 20px;
    }
  }
`

const Bundles = styled(FlexRow)`
  border-radius: 5px;
  color: #555555;
  margin-bottom: 25px;
  overflow: hidden;
`

Bundles.Item = styled(FlexColumn)`
  background-color: #bce0fd;
  cursor: pointer;
  flex-grow: 1;
  position: relative;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #ffca3a;
    `}
`

Bundles.Item.Header = styled.div`
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
`

Bundles.Item.SelectedIcon = styled(Check).attrs({ size: '31' })`
  align-self: center;
  margin: 5px 0;
`

Bundles.Item.Price = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 10px;
`

Bundles.Item.Price.CurrencySymbol = styled.span`
  font-size: 12px;
  line-height: 24px;
`

Bundles.Item.Price.Period = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: relative;
  text-align: center;
`

const BundleSummary = styled(FlexColumn)`
  border-radius: 5px;
  border: solid 1px #ffffff;
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  padding: 10px 20px;
  padding-bottom: 0;
  margin-bottom: 25px;
`

const BundleAlert = styled.div`
  background-color: #ffca3a;
  color: #555555;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 25px;
  & > svg {
    margin-right: 10px;
  }
  @media only screen and (max-width: 565px) {
    font-size: 13px;
  }
`

BundleAlert.Text = styled.div``

BundleSummary.Row = styled(FlexRow)`
  margin-bottom: 10px;
`

BundleSummary.Label = styled.div`
  flex-grow: 1;
`

BundleSummary.Value = styled.div`
  text-align: right;
  flex-grow: 1;
`

// const BUNDLES = {
//   10: {
//     productId: 1,
//     label: 'Pagamento único',
//     confirmLabel: 'Mensal',
//     installments: 1,
//     valueFloat: 16.99,
//     value: '16,99',
//     totalValue: '16,99',
//   },
//   14: {
//     productId: 4,
//     label: 'Parcelado (40% off)',
//     confirmLabel: 'Anual',
//     installments: 12,
//     valueFloat: 7.99,
//     value: '7,99',
//     totalValue: '95,88',
//     alert: 'Você economizou R$60,00!',
//   },
//   12: {
//     productId: 3,
//     label: 'Parcelado (20% off)',
//     confirmLabel: 'Semestral',
//     installments: 6,
//     valueFloat: 9.99,
//     value: '9,99',
//     totalValue: '59,94',
//     alert: 'Você economizou R$18,00!',
//   },
// }

const BrandWrapper = styled(FlexRow)`
  width: 45px;
  & svg:first-child {
    margin-right: 5px;
  }
`

function Brands() {
  return (
    <BrandWrapper>
      <CcVisa size='20' />
      <CcMastercard size='20' />
    </BrandWrapper>
  )
}

function formatMoney(cents) {
  return `${(cents / 100).toFixed(2).replace('.', ',')}`
}

function BundleSelect({ onChange, value, options }) {
  const option = options[value]

  return (
    <div>
      <Bundles>
        {options.map(({ wire, totalPrice, installments }, i) => (
          <Bundles.Item selected={value === i} onClick={() => onChange(i)}>
            <Bundles.Item.Header>
              {wire ? 'Transf. Banc.' : `Cartão ${installments}x`}
            </Bundles.Item.Header>
            {value === i ? (
              <Bundles.Item.SelectedIcon />
            ) : (
              <Bundles.Item.Price>
                <Bundles.Item.Price.CurrencySymbol>
                  R$
                </Bundles.Item.Price.CurrencySymbol>
                {formatMoney(totalPrice / installments)}
                <Bundles.Item.Price.Period></Bundles.Item.Price.Period>
              </Bundles.Item.Price>
            )}
          </Bundles.Item>
        ))}
      </Bundles>
      <BundleSummary>
        {option.products.map(product => (
          <BundleSummary.Row>
            <BundleSummary.Label>{product.name}</BundleSummary.Label>
            <BundleSummary.Value>
              {product.price === 0 ? 'Gratuito' : `R$ ${product.price / 100}`}
            </BundleSummary.Value>
          </BundleSummary.Row>
        ))}
        <BundleSummary.Row>
          <BundleSummary.Label>&nbsp;</BundleSummary.Label>
          <BundleSummary.Value>&nbsp;</BundleSummary.Value>
        </BundleSummary.Row>
        {option.installments > 1 && (
          <BundleSummary.Row>
            <BundleSummary.Label>
              {option.installments} parcelas
            </BundleSummary.Label>
            <BundleSummary.Value>
              R$ {formatMoney(option.totalPrice / option.installments)}
            </BundleSummary.Value>
          </BundleSummary.Row>
        )}
        <BundleSummary.Row>
          <BundleSummary.Label>Total a pagar</BundleSummary.Label>
          <BundleSummary.Value>
            R$ {option.totalPrice / 100}
          </BundleSummary.Value>
        </BundleSummary.Row>
      </BundleSummary>
    </div>
  )
}

const CreditCardForm = ({ submitting, fields }) => (
  <div>
    <Input
      id='identity'
      placeholder='CPF'
      icon={AddressCard}
      autoFocus
      mask='999.999.999-99'
      {...fields.identity}
    />
    <Input
      id='cardNum'
      placeholder='Número do Cartão'
      icon={Brands}
      iconWidth={50}
      mask='9999 9999 9999 9999'
      {...fields.cardNum}
    />
    <Input
      id='cardName'
      placeholder='Nome no Cartão'
      icon={UserAlt}
      {...fields.cardName}
    />
    <FormRow>
      <Input
        id='cardExp'
        placeholder='Vencimento'
        icon={CalendarAlt}
        mask='99/99'
        {...fields.cardExp}
      />
      <Input
        id='cardCvv'
        placeholder='CVV'
        icon={Check}
        mask='9999'
        {...fields.cardCvv}
      />
    </FormRow>
    <Input
      id='couponCode'
      placeholder='Cupom'
      icon={Tag}
      {...fields.couponCode}
    />
    <Submit value='Continuar' disabled={submitting} />
  </div>
)

const BankDetails = () => (
  <BundleSummary>
    <BundleSummary.Row>
      <BundleSummary.Label>Banco Bradesco</BundleSummary.Label>
      <BundleSummary.Value>237</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>Agência</BundleSummary.Label>
      <BundleSummary.Value>0831</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>Conta Corrente</BundleSummary.Label>
      <BundleSummary.Value>12825-2</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>CNPJ</BundleSummary.Label>
      <BundleSummary.Value>28.484.498/0001-24</BundleSummary.Value>
    </BundleSummary.Row>
    <BundleSummary.Row>
      <BundleSummary.Label>Titular</BundleSummary.Label>
      <BundleSummary.Value>
        Instituto Brasileiro de Saúde Euclides Leite
      </BundleSummary.Value>
    </BundleSummary.Row>
    <p>
      Favor enviar o comprovante e o número do seu CPF para:{' '}
      <b>contato@ibsel.com.br</b>
    </p>
  </BundleSummary>
)

const Plan = styled.div`
  font-size: 28px;
  margin-bottom: 20px;
`

const Confirmation = ({ coupon, option, values, submitting, setIsFilled }) => (
  <div>
    {option.products.map(product => (
      <Plan>{product.name}</Plan>
    ))}
    <Plan>
      Total: {option.installments}x R${' '}
      {formatMoney(option.totalPrice / option.installments)}
    </Plan>
    {coupon && (
      <div>
        <Plan>Cupom: {coupon.code.toUpperCase()}</Plan>
        <Plan>
          c/ Desconto: {option.installments}x R${' '}
          {(
            (option.totalPrice / option.installments) *
            (1 - coupon.amount / 100)
          )
            .toFixed(2)
            .replace('.', ',')}
        </Plan>
      </div>
    )}
    <BundleSummary>
      <BundleSummary.Row>
        <BundleSummary.Label>CPF</BundleSummary.Label>
        <BundleSummary.Value>{values.identity}</BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>Nome</BundleSummary.Label>
        <BundleSummary.Value>{values.cardName}</BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>Cartão</BundleSummary.Label>
        <BundleSummary.Value>
          **** **** **** {values.cardNum.substr(values.cardNum.length - 4)}
        </BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>CVV</BundleSummary.Label>
        <BundleSummary.Value>***</BundleSummary.Value>
      </BundleSummary.Row>
      <BundleSummary.Row>
        <BundleSummary.Label>Expiração</BundleSummary.Label>
        <BundleSummary.Value>{values.cardExp}</BundleSummary.Value>
      </BundleSummary.Row>
    </BundleSummary>
    <FlexRow>
      <Button onClick={() => !submitting && setIsFilled(false)}>Voltar</Button>
      <Submit value='Efetuar Pagamento' disabled={submitting} />
    </FlexRow>
  </div>
)

function Checkout({ history, options }) {
  const ctx = useAppCtx()

  const [submitting, setSubmitting] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [coupon, setCoupon] = useState()

  const { fields, values, setAllErrors, onSubmit } = useForm(initial, {
    constraints,
    errorsMap,
  })

  useEffect(function () {
    fbq('InitiateCheckout')
  }, [])

  const submit = async () => {
    if (!submitting) {
      setSubmitting(true)
      const host = `${window.location.protocol}//${window.location.host}`
      const { products, installments } = options[values.optionIdx]
      const productIds = products.map(x => x.id)
      const captchaToken = await window.grecaptcha.execute(
        '6LeMp9UZAAAAACQjjLnJCa16UQFbRN5e5alriPxS',
        { action: 'submit' },
      )
      try {
        const { data: tokens } = await SubscriptionsAPI.create(ctx, {
          ...values,
          productIds,
          installments,
          host,
          captchaToken,
        })
        ctx.auth.auth(tokens)
        fbq('Purchase')
        mixpanel(ctx, 'Subscribe', { 'Product Ids': productIds })
        history.push('/')
      } catch (err) {
        setSubmitting(false)
        setIsFilled(false)
        console.log(err)
        setAllErrors(err)
      }
    }
  }

  useEffect(() => {
    ;(async function () {
      if (values.couponCode) {
        try {
          const { data } = await CouponsAPI.get(ctx, values.couponCode)
          setCoupon(data)
          return
        } catch {
          setCoupon(undefined)
        }
      }
      setCoupon(undefined)
    })()
    // eslint-disable-next-line
  }, [values.couponCode])

  const isBankPayment = options[values.optionIdx].wire

  return (
    <Wrapper>
      <Form
        onSubmit={
          isFilled ? onSubmit(submit) : onSubmit(() => setIsFilled(true))
        }
      >
        <Logo />
        <Title>Quase Pronto</Title>
        <Subtitle>Insira seus dados para pagamento do curso</Subtitle>
        {!isFilled && <BundleSelect options={options} {...fields.optionIdx} />}
        {isBankPayment && <BankDetails />}
        {!isBankPayment && !isFilled && <CreditCardForm fields={fields} />}
        {!isBankPayment && isFilled && (
          <Confirmation
            coupon={coupon}
            setIsFilled={setIsFilled}
            option={options[values.optionIdx]}
            values={values}
            submitting={submitting}
          />
        )}
        <AltAction>
          Gostaria de experimentar mais um pouco? <Link to='/'>Voltar</Link>
        </AltAction>
      </Form>
    </Wrapper>
  )
}

function useProductOptionsApi(optionsHash) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(
    function () {
      return ProductOptionsAPI.get(ctx, optionsHash)
    },
    [optionsHash],
  )

  return {
    options: result && result.data,
    isFetching: !isResolved,
  }
}

export default function ({ history, match }) {
  const { optionsHash } = match.params

  const { options, isFetching } = useProductOptionsApi(optionsHash)

  return !isFetching && <Checkout history={history} options={options} />
}
