import React from "react"

export default function ListMarkerSVG({ className }) {
  return (
    <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="Padding__x26__Artboard"/>
    <g id="Icons">
    <g><path d="M23.99976,12.4834c-6.3501,0-11.5166,5.16602-11.5166,11.5166s5.1665,11.5166,11.5166,11.5166    c6.35059,0,11.51709-5.16602,11.51709-11.5166S30.35034,12.4834,23.99976,12.4834z M28.36108,24.62402l-6.30322,3.67969    c-0.11328,0.06543-0.23779,0.09863-0.3623,0.09863c-0.12256,0-0.24512-0.03223-0.35693-0.0957    c-0.22607-0.12988-0.36084-0.36328-0.36084-0.62402v-7.36523c0-0.26074,0.13525-0.49316,0.36133-0.62305    c0.22559-0.12988,0.49512-0.12891,0.71973,0.00293l6.30176,3.68652c0.22266,0.12988,0.35547,0.36133,0.35596,0.61914    C28.71655,24.26172,28.58374,24.49316,28.36108,24.62402z" /></g></g>
    </svg>
  )
}
