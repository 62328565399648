import { useRef, useEffect, useCallback } from "react"

export default function() {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  return (
    useCallback(() =>
      mounted.current
    , [])
  )
}