import React from "react"
import styled, { css } from "styled-components"

import Playlist from "./Playlist"

import FlexColumn from "components/FlexColumn"


const ItemList = styled(FlexColumn)`
  position: relative;
  /* border-right: solid 1px #e8eaee; */
  ${({ small }) =>
    small &&
    css`
      border-right: none;
    `}
  @media only screen and (max-width: 750px) {
    border-right: none;
  }
`

export default function({playlists, changeItems, changePlaylist, selected, open}) {
  return (
    <ItemList>
      {playlists.map((playlist, i) => {
        return (
          <Playlist playlist={playlist} changeItems={changeItems} changePlaylist={changePlaylist} key={playlist.id} selected={selected} open={open} />
        )
      })}
    </ItemList>
  )
}