import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import FlexRow from 'components/FlexRow'
import FlexColumn from 'components/FlexColumn'
import { Clock, Check, Video } from 'styled-icons/fa-solid'
import usePlaylistItemsCtx from '../../../hooks/usePlaylistItemssCtx'

const Container = styled(FlexColumn)`
  width: 100%;
`

const ItemTypes = styled(FlexColumn)`
  display: flex;
  padding-bottom: 20px;
  padding-left: 5px;
`

const VideoIcon = styled(Video)`
  align-self: center;
  color: #afafaf;
  width: 10px;
`

const CheckIcon = styled(Check)`
  align-self: center;
  color: #afafaf;
  width: 10px;
`	

const Status = styled.div`
  position: absolute;
  top: 0;
  left: -12px;
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`

const ItemType = styled(FlexRow)`
  /* align-items: center; */
  padding-bottom: 30px;
  padding-left: 25px;
  border-left: 1px solid #e8e8e8;
  position: relative;

  &:last-child {
    border-left: none;
  }

  &:hover {
    opacity: 0.8;
  }

  ${({ actived }) => actived && css`
    border-color: #4ec437;

    ${Status} {
      border-color: #4ec437;
    }

    ${VideoIcon} {
      color: #4ec437;
    }

    ${CheckIcon} {
      color: #4ec437;
    }
  `}
`

const ItemLink = styled(NavLink)`
  min-width: 0;
  display: flex;
  text-decoration: none;
  color: #3f424a;
  line-height: 22px;
  font-weight: 600;
  width: 100%;
`

const DescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2px;
`

const DurationRow = styled(FlexRow)`
  font-size: 12px;
  cursor: pointer;
  & svg {
    flex-shrink: 0;
    fill: #676a74;
  }
  line-height: 0px;
  font-weight: 600;
  color: #3f424a;
  margin-bottom: 10px;
`
const Duration = styled.span`
  align-self: center;
`

const ClockIcon = styled(Clock)`
  align-self: center;
  margin-right: 5px;
`

const LessonRow = styled(FlexRow)`
  color: #9da3a8;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  padding-bottom: 5px;
`

const Lesson = styled.span`
  align-self: center;
`

const Title = styled(NavLink)`
  white-space: normal;
  min-width: 0;
  display: inline-block;
  padding-right: 15px;
  text-decoration: none;
  color: #3f424a;
  line-height: 22px;
  font-weight: 600;

  @media only screen and (max-width: 750px) {
    font-size: 14px;
    line-height: 19px;
  }
`

const ThumbnailColumn = styled(FlexColumn)`
  width: 130px;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 750px) {
    width: 140px;
    margin-right: 10px;
  }
`

const Thumbnail = styled.img`
  /* height: 100px; */
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;

  @media only screen and (max-width: 750px) {
    width: 140px;
    height: 80px;
  }
`

const PlayButton = styled.div`
  width: 40px;
  height: 40px;
  background: white;
  border: none;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 3px 3px 8px;

  &::after {
    content: '';
    display: inline-block;
    position: relative;
    top: 1px;
    left: 3px;
    border-style: solid;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent #1cace3;
  }
`

const Content = styled.div``
const Header = styled.div`
  padding: 10px 0px;
  margin-bottom: 10px;
`
const LessonName = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #3f424a;
`

function Subjects() {
  const { playlistItems } = usePlaylistItemsCtx()

  // separate items by lesson - copilot
  const itemsByLesson = playlistItems.items.reduce((acc, item) => {
    const { lesson } = item
    if (!acc[lesson]) {
      acc[lesson] = []
    }
    acc[lesson].push(item)
    return acc
  }, {})

  return (
    <Container>
      {Object.keys(itemsByLesson).map((lesson, index) => {
        const items = itemsByLesson[lesson]
        return (
          <Content key={index}>
            <Header>
              <LessonName>{lesson}</LessonName>
            </Header>
            <ItemTypes>
              {items.map(item => {
                return (
                  <ItemType key={item.id} actived={item.watched}>
                    <Status>
                      {item.watched ? <CheckIcon /> : <VideoIcon />}
                    </Status>
                    <ItemLink to={item.uri}>
                      <ThumbnailColumn>
                        <PlayButton />
                        <Thumbnail
                          src={`${process.env.PUBLIC_URL}/file-imgs/thumb.jpg`}
                        />
                      </ThumbnailColumn>
                      <DescriptionColumn>
                        <DurationRow>
                          <ClockIcon size='10' />
                          <Duration>
                            {new Date(item.duration * 1000)
                              .toISOString()
                              .substr(11, 8)
                              .substring(3)}
                          </Duration>
                        </DurationRow>
                        <LessonRow>
                          <Lesson>{item.lesson}</Lesson>
                        </LessonRow>
                        <FlexRow>
                          <Title to={item.uri}>{item.title}</Title>
                        </FlexRow>
                      </DescriptionColumn>
                    </ItemLink>
                  </ItemType>
                )
              })}
            </ItemTypes>
          </Content>
        )
      })}
    </Container>
  )
}

export default Subjects
