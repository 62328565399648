import { get, buildUrl } from "./common"

export default {
  async list(ctx) {
    return await get(ctx, buildUrl(`/public/clinical-cases`))
  },

  async get(ctx, { clinicalCaseId }) {
    return await get(ctx, buildUrl(`/public/clinical-cases/${clinicalCaseId}`))
  },
}