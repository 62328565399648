import React, { useEffect, useMemo } from 'react'

import mixpanel from 'utils/mixpanel'
import useAppCtx from 'hooks/useAppCtx'
import useLayoutCtx from 'hooks/useLayoutCtx'

import useClinicalCaseCtx from '../hooks/useClinicalCaseCtx'

import useUniversityCtx from 'hooks/useUniversityCtx'

import * as Frames from './Frames'
import { createGlobalStyle } from 'styled-components'

const GlobalTheme = createGlobalStyle`
  @media only screen and (max-width: 896px) and (orientation: landscape) {
    #jvlabelWrap, .button_5fdd, .globalClass_5042, .eLZjEv, .gYrZVQ, .sc-gGBfsJ, .kKnxxA {
      display: none !important;
    }
    .grecaptcha-badge {
      visibility: hidden;
    }
    .eVenXp {
      padding-left: 0;
    }
    .eWhAdL {
      padding: 0px;
    }
    .LzdgL {
      min-width: auto;
    }
    .gFTjMB {
      padding: 0;
    }
    .bcVslU {
      max-height: 325px;
    }
    .bNSwXv {
      width: 175px;
      margin-right: 12px;
    }
  }
`

function Render() {
  const { clinicalCase = {}, curr } = useClinicalCaseCtx()
  
  const frameDef = useMemo(
    () => clinicalCase.caseFrames[curr],
    // eslint-disable-next-line
    [clinicalCase.id, curr],
  )
  const Frame = useMemo(() => Frames[frameDef.type], [frameDef.type])
  // return <Frame {...frameDef}></Frame>
  return [<GlobalTheme />, <Frame {...frameDef}></Frame>]
}

export default function () {
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()

  const { clinicalCase = {} } = useClinicalCaseCtx()

  useEffect(() => {
    const { title, caseGroupId } = clinicalCase

    setLayoutState({
      title,
      subTitle: 'Casos Clínicos',
      backLink: '/estudos-de-caso/' + caseGroupId,
    })
    mixpanel(ctx, 'ClinicalCases_' + title, { University: universities.name })

    return () => {
      setLayoutState({})
    }
    // eslint-disable-next-line
  }, [clinicalCase, setLayoutState])

  return <Render />
}
