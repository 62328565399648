import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'

import getFileUrl from 'utils/getFileUrl'
import getMimeType from 'utils/getMime'

// import SimulationsAPI from 'datasources/simulations'
// import usePromise from 'hooks/usePromise'
// import useAppCtx from 'hooks/useAppCtx'
import useLayoutCtx from 'hooks/useLayoutCtx'
// import Preview from './Preview/Preview'

import FlexRow from 'components/FlexRow'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ListContainer = styled.div`
  display: grid;
  grid-gap: 22px;
  ${({ theme }) => theme.media.max('480px')} {
    grid-template-columns: repeat(auto-fill, 100%);
  }
  ${({ theme }) => theme.media.between('480px', '800px')} {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 23px), 1fr));
  }
  ${({ theme }) => theme.media.between('800px', '1100px')} {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(33.33333% - 23px), 1fr)
    );
  }
  ${({ theme }) => theme.media.between('1100px', '1920px')} {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 23px), 1fr));
  }
  ${({ theme }) => theme.media.min('1920px')} {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(16.66667% - 23px), 1fr)
    );
  }
`

const ListItem = styled.div`
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1),
    0 7px 18px 0 rgba(97, 108, 121, 0.13);
  transition: box-shadow 0.2s ease;
  position: relative;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1),
      0 7px 18px 0 rgba(21, 111, 170, 0.21);
  }
`

const Thumb = styled.div`
  background: rgba(0, 0, 0, 0.5);
  flex: 1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  object-fit: contain;
  max-height: calc(100% - 48px);
  object-position: center;
  background-position: 0% center;
  overflow: hidden;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    max-height: 166px;
    object-fit: cover;
    object-position: center;
  }
`

const Resume = styled.div`
  display: flex;
  background: white;
  height: 48px;
  padding: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
`

const Header = styled(FlexRow)`
  background-color: #f5f9fa;
  border-radius: 2px;
  padding: 40px;
  flex-direction: column;
  margin-bottom: 20px;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`

// function useSimulations() {
//   const ctx = useAppCtx()

//   const { isResolved, result } = usePromise(function () {
//     return SimulationsAPI.list(ctx)
//   }, [])

//   return {
//     simulations: result && result.data,
//     isFetchingSimulations: !isResolved,
//   }
// }

export const Simulation = ({ history, simulations, course, topic }) => {
  const { setLayoutState } = useLayoutCtx()

  useEffect(() => {
    setLayoutState({ title: 'Laboratório Virtual' })
  }, [setLayoutState])

  const goToDetail = useCallback(
    id => e => history.push(`/laboratorio-virtual/${course.id}/disciplinas/${topic.id}/simulacao/${id}`),
    // TODO: Performance test
    // eslint-disable-next-line
    [history],
  )

  return (
    <Container>
      <Header>
        Seja bem vindo ao Laboratório Virtual do IBSEL Atlas! <br /> <br />
        Aqui você pode interagir com modelos tridimensionais (3D) para aprender
        melhor sobre as estruturas das células e muito mais!
      </Header>
      <ListContainer>
        {simulations.map(item => {
          const { id: simulationId, assets = [], title } = item
          const { id: assetId, mimeType } =
            (assets?.reverse() || []).find(obj => {
              return  ["image/jpg", "image/jpeg", "image/png"].includes((obj || {}).mimeType)
            }) || {}


          const ext = getMimeType(mimeType)
          const thumbUrl = getFileUrl(
            `/simulations/${simulationId}/assets/${assetId}.${ext}`,
          )
          return (
            <ListItem key={simulationId} onClick={goToDetail(simulationId)}>

              <Thumb>
                <img src={thumbUrl} alt={title} />
              </Thumb>
              <Resume>{title}</Resume>
            </ListItem>
          )
        })}
      </ListContainer>
    </Container>
  )
}

export default Simulation
