import React, { useRef, useEffect, useState } from 'react'
import Switch from 'routes/Anonymous/components/Switch'
import styled from 'styled-components'
import * as BABYLON from 'babylonjs'
import 'babylonjs-loaders'

const Viewer = styled.canvas`
  width: 100%;
  height: 100%;
  outline: none;
`

const prepareLighting = scene => {
  if (!scene.environmentTexture) {
    scene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(
      'https://assets.babylonjs.com/environments/environmentSpecular.env',
      scene,
    )
  }
  scene.environmentTexture &&
    scene.createDefaultSkybox(
      scene.environmentTexture,
      true,
      (scene.activeCamera.maxZ - scene.activeCamera.minZ) / 2,
      0.3,
      false,
    )
  scene.createDefaultLight()
}

const setSceneFog = scene => {
  scene.fogMode = BABYLON.Scene.FOGMODE_LINEAR
  scene.fogStart = 0
  scene.fogEnd = 1000.0
  scene.fogColor = new BABYLON.Color3(0.01, 0.01, 0.17)
}

const setDefaultFog = scene => {
  scene.fogMode = BABYLON.Scene.FOGMODE_NONE
}

const createScene = function (canvas, modelName, meshes) {
  var engine = new BABYLON.Engine(canvas)
  var scene = new BABYLON.Scene(engine)
  var camera = new BABYLON.ArcRotateCamera(
    'Camera',
    Math.PI / 2,
    Math.PI / 2,
    150,
    BABYLON.Vector3.Zero(),
    scene,
  )

  window.addEventListener('resize', () => {
    engine.resize()
  })

  camera.attachControl(canvas, true)
  camera.useFramingBehavior = true

  var loader = new BABYLON.AssetsManager(scene)

  prepareLighting(scene)
  setDefaultFog(scene)

  if (meshes.length === 1) {
    loader.addMeshTask('skull task', '', `${meshes[0].url}.glb`, '')
  } else {
    for (var i = 0; i < meshes.length - 1; i++) {
      loader.addMeshTask('skull task', '', `${meshes[i].url}.glb`, '')
    }
  }

  loader.onFinish = function () {
    engine.clearInternalTexturesCache()
    var framingBehavior = camera.getBehaviorByName('Framing')
    framingBehavior.framingTime = 0
    framingBehavior.elevationReturnTime = -1

    if (scene.meshes.length) {
      camera.lowerRadiusLimit = null

      var worldExtends = scene.getWorldExtends(function (mesh) {
        return mesh.isVisible && mesh.isEnabled()
      })

      framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max)
    }

    camera.pinchPrecision = 200 / camera.radius
    camera.upperRadiusLimit = 4 * camera.radius
    camera.lowerRadiusLimit = null

    camera.wheelDeltaPercentage = 0.01
    camera.pinchDeltaPercentage = 0.01
    camera.minZ = 0.1
    engine.runRenderLoop(function () {
      scene.render()
    })
  }

  loader.load()

  return scene
}

const Preview = props => {
  const { modelName, meshes, ...rest } = props
  const canvasRef = useRef()
  const [scene, setScene] = useState(null)
  const [legenda, setLegenda] = useState(null)
  const [fog, setFog] = useState(null)

  function disableMesh() {
    if (scene === undefined || scene === null) return

    if (legenda === null || legenda === undefined) {
      var loader = new BABYLON.AssetsManager(scene)
      loader.addMeshTask(
        'skull task',
        '',
        `${meshes[meshes.length - 1].url}.glb`,
        '',
      )
      loader.onFinish = loaderFinished()
      loader.load()
      setLegenda(true)
      return
    }

    const pos = scene.rootNodes.length - 1
    scene.rootNodes[pos].setEnabled(!legenda)
    setLegenda(!legenda)
    scene.render()
  }

  function switchFog() {
    if (scene === null || scene === undefined) return

    if (fog) {
      setDefaultFog(scene)
      scene.render()
      setFog(!fog)
      return
    }

    setSceneFog(scene)
    setFog(!fog)
    scene.render()
  }

  function loaderFinished() {
    scene.render()
  }

  useEffect(() => {
    const result = createScene(canvasRef.current, modelName, meshes)
    setScene(result)
    // eslint-disable-next-line
  }, [modelName])

  return (
    <div>
      <Viewer key={modelName} id='previewCanvas' ref={canvasRef} {...rest} />
      <Switch
        id='swt-contraste'
        label='Contraste'
        callback={switchFog}
        floatType='left'
        {...rest}
      ></Switch>
      {meshes.length > 1 && (
        <Switch
          id='swt-legenda'
          label='Legenda'
          floatType='right'
          callback={disableMesh}
          {...rest}
        ></Switch>
      )}
    </div>
  )
}

export default Preview
