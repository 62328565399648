import React, { useState, createContext } from "react"

const Context = createContext()

export function Provider({ children }) {
  const [layoutState, setLayoutState] = useState({})

  const value = {
    layoutState,
    setLayoutState,
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default Context