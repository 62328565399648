import React, { useCallback, useEffect, useState } from 'react'
import { Route, Switch, useParams } from "react-router-dom"
import styled from "styled-components"

import mixpanel from "utils/mixpanel"
import usePromise from "hooks/usePromise"
import CommunityAPI from "datasources/community"
import useAppCtx from "hooks/useAppCtx"
import useLayoutCtx from "hooks/useLayoutCtx"
import useForm from "hooks/useForm"

import useCommunityCtx from "../hooks/useCommunityCtx"
import PostRoute from "../routes/PostDetail"
import FlexRow from "components/FlexRow"
import Post from 'components/Post'

import * as S from './styles'

const Container = styled.div``
const List = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`

const modalStyles = {
  display: 'flex',
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(10,10,10,.66)',
  },
}

const Header = styled(FlexRow)`
  background-color: #f5f9fa;
  border-radius: 2px;
  padding: 40px;
  flex-direction: column;
  margin-bottom: 20px;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`;


const initial = {
  title: "",
  content: "",
  topics: "",
}

const constraints = {
  topics: { presence: { allowEmpty: false } },
  title: { presence: { allowEmpty: false } },
  content: { presence: { allowEmpty: false } },
}

const errorsMap = {}

function useTopics() {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    return CommunityAPI.topics(ctx)
  }, [])

  return {
    topics: result && result.data,
    isFetchingTopics: !isResolved,
  }
}


export const Community = ({ history }) => {
  S.QuestionModal.setAppElement('#root')
  const ctx = useAppCtx()
  const { postId } = useParams()
  const { posts } = useCommunityCtx()
  const { topics: topicsOptions } = useTopics()
  const { setLayoutState } = useLayoutCtx()
  const [list, setList] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false)

  const postChange = useCallback(id => e =>
    history.push(`/comunidade/${id}`)
  , [history])


  useEffect(() => {
    mixpanel(ctx, "Community Landing")
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!postId) {
      setLayoutState({ title: 'Comunidade', subTitle: 'Perguntas' })
      setList(posts)
    }
  }, [posts, postId, setLayoutState])

  const {
    fields,
    values,
    setAllErrors,
    onSubmit
  } = useForm(initial, { constraints, errorsMap })

  const submit = async () => {
    const { topics, ...rest } = values
    const topicIds = [parseInt(topics)]
    const payload = { ...rest, topicIds }
    try {
     const { data: post } = await CommunityAPI.create(ctx, { ...payload })
     setList(posts => [post, ...posts])
     fields.title.onChange('')
     fields.content.onChange('')
     setIsOpen(false)
     // mixpanel(ctx, "Reply_Created", {})
    } catch(err) {
      setAllErrors(err)
    }
  }

  const deletePost = ({ id }) => {
    const index = list.findIndex(obj => obj.id === id);
    setList([
      ...list.slice(0, index),
      ...list.slice(index + 1)
    ])
  }

  return (
    <Container>
      {!postId &&
        <List>
          <S.QuestionModal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={modalStyles}
            contentLabel="Example Modal"
          >
            <form onSubmit={onSubmit(submit)}>
              <S.ModalHeader>
                <S.ModalTitle>
                  Criar pergunta
                </S.ModalTitle>
                <S.CloseModal title='Fechar' onClick={() => setIsOpen(false)} />
              </S.ModalHeader>
              <S.ModalContent>
              <S.Field>
                  <S.Select
                    id="topics"
                    label="Selecione o tópico"
                    // changeTransform={x => x ? parseInt(x) : ""}
                    options={topicsOptions}
                    {...fields.topics}
                  />
                </S.Field>
                <S.Field>
                  <S.Input
                    id="title"
                    placeholder="Título da sua pergunta"
                    type="text"
                    {...fields.title}
                  />
                </S.Field>
                <S.Field>
                  <S.Textarea
                    id="content"
                    placeholder="Detalhe a sua pergunta"
                    type="text"
                    {...fields.content}
                  />
                </S.Field>
              </S.ModalContent>
              <S.ModalFooter>
                <S.Submit value="Perguntar"/>
              </S.ModalFooter>
            </form>
          </S.QuestionModal>
          <Header>
            Seja bem vindo à comunidade do IBSEL! <br /> <br />
            Aqui você pode fazer perguntas, tirar dúvidas e interagir com os professores e outros alunos.
          </Header>
          <S.AskQuestionBtn onClick={() => setIsOpen(true)}>Criar pergunta</S.AskQuestionBtn>
          {list.map(post =>
            <Post data={post} key={post.id} onClick={postChange(post.id)} onDelete={deletePost} />
          )}
        </List>
      }
      <Switch>
        <Route exact path="/comunidade/:postId" component={PostRoute}/>
      </Switch>
    </Container>
  )
}

export default Community