import React, { useCallback, useEffect } from "react"
import { Route, Switch, useParams } from "react-router-dom"
import styled, { css } from "styled-components"

import mixpanel from "utils/mixpanel"
import FlexRow from "components/FlexRow"
import useAppCtx from "hooks/useAppCtx"
import useLayoutCtx from "hooks/useLayoutCtx"

import useClinicalCasesCtx from "../hooks/useClinicalCasesCtx"
import CaseGroupRoute from "../routes/CaseGroup"
import NotFound from "../routes/CaseGroup/notFound"

import useUniversityCtx from 'hooks/useUniversityCtx'
import Tabs from "components/Tabs"

const NavBar = styled(FlexRow)`

`

const CaseGroups = styled(Tabs)`
  height: 40px;
  margin-bottom: 30px;
`

const CaseGroup = styled(Tabs.item)`
  background-color: #E8EAEE;
  color: #044c74;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  margin-right: 0px;
  padding: 11px 20px;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  ${({ selected }) => selected && css`
    border-top-color: #044c74;
    border-top: 1px solid;
    background-color: #FFFFFF;
    color: #044c74;
    cursor: pointer;
  `}
`

export default function({ history }) {
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()
  const { clinicalCaseId } = useParams()

  const baseUrl = "/estudos-de-caso"

  const {
    caseGroups,
    currCaseGroup,
    isFetchingCaseGroups
  } = useClinicalCasesCtx()

  const caseGroupChanged = useCallback(id => e =>
    history.push(`${baseUrl}/${id}`)
  , [history])

  useEffect(() => {
    setLayoutState({ title: 'Casos Clínicos' })
    mixpanel(ctx, 'ClinicalCases_Home', { "University": universities.name })

    return (() => {
      setLayoutState({})
    })
  // eslint-disable-next-line
  }, [currCaseGroup, setLayoutState])

  return (
    <div>
      {!isFetchingCaseGroups && !clinicalCaseId &&
        <NavBar>
          <CaseGroups>
            {caseGroups.map(caseGroup => {
              const isCurrCaseGroup = currCaseGroup.id === caseGroup.id
              return (
                <CaseGroup
                  key={caseGroup.id}
                  selected={isCurrCaseGroup}
                  onClick={!isCurrCaseGroup ? caseGroupChanged(caseGroup.id) : undefined}
                >
                  <Tabs.bullet />
                  {caseGroup.title}
                </CaseGroup>
              )
            })}
          </CaseGroups>
        </NavBar>
      }

      <Switch>
        <Route path={baseUrl + "/:caseGroupId"} component={CaseGroupRoute}/>
        <Route path={baseUrl} exact component={NotFound}/>
      </Switch>
    </div>
  )
}