import React from 'react'
import styled from 'styled-components'

import FlexRow from 'components/FlexRow'
import TopicCard from 'components/TopicCard'

import useExercisesCtx from '../../hooks/useExercisesCtx'

const Container = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 0 -15px;
  > * {
    margin: 15px 15px;
  }
`

const CustomTopicCard = styled(TopicCard)`
  height: 220px;
  box-shadow: 1px 2px 3px 0px #967f7f;
  @media only screen and (max-width: 565px) {
    width: 100%;
  }
`

export default function () {
  const { currCourse } = useExercisesCtx()

  return (
    <Container>
      {currCourse.topics.map((topic, i) => {
        return (
          <CustomTopicCard
            key={i}
            to={`/exercicios/${currCourse.id}/disciplinas/${topic.id}`}
            topic={topic}
          >
            {topic.name}
          </CustomTopicCard>
        )
      })}
    </Container>
  )
}
