import { useState, useEffect, useCallback } from "react"
import useIsMounted from "hooks/useIsMounted"

const initialState = { isResolved: false }

export default function(fn, deps = []) {
  const isMounted = useIsMounted()
  const [state, setState] = useState(initialState)
  const cb = useCallback(fn, deps)

  useEffect(() => {
    (async () => {
      try {
        const result = await cb()
        isMounted() && setState({ isResolved: true, result })
      } catch(error) {
        isMounted() && setState({ isResolved: true, error })
      }
    })()
  }, [cb, isMounted])

  return state
}