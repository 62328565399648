import React, { useRef, useMemo } from 'react'
import { F } from 'ramda'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { isBefore } from 'date-fns'
import { ChevronLeft, ChevronRight, Video } from 'styled-icons/fa-solid'
import useAuthCtx from 'hooks/useAuthCtx'
import WelcomeModal from 'components/WelcomeModal'
import FlexRow from 'components/FlexRow'
import FlexColumn from 'components/FlexColumn'
import { getImgixUrl } from 'utils/imgix'

import Lesson from '../../components/Lesson'
import useCoursesCtx from '../../hooks/useCoursesCtx'

const Topics = styled(FlexColumn)`
  width: 100%;`

const Topic = styled(FlexRow)`
  background-color: #F2F7FA;
  border-radius: 10px;
  height: 300px;
  margin-bottom: 40px;
  position: relative;
  @media only screen and (max-width: 565px) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 260px;
  }
`

const TopicCard = styled.div`
  background-color: #AAAAAA;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 15px;
  text-decoration: none;
  width: 220px;
  ${({ topicId, image }) => image && css`
    background-image: url("https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/topics/${topicId}/image");
    background-image: url("${getImgixUrl(`topics/${topicId}/image`, { w: 220, h: 300})}");
  `};
  @media only screen and (max-width: 565px) {
    display: none;
  }
`

const TopicMobileCard = styled.div`
  background-color: #F2F7FA;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #1CACE3;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  padding-left: 20px;
  padding-top: 20px;
  position: relative;
  text-transform: uppercase;
  @media only screen and (min-width: 565px) {
    display: none;
  }
`

const TopicNav = styled(FlexRow)`
  margin-left: 40px;
  margin-right: 40px;
  align-self: center;
  width: 90px;
  @media only screen and (max-width: 565px) {
    display: none;
  }
`

const TopicNavButton = styled(FlexRow)`
  align-items: center;
  background-color: #1CACE3;
  border-radius: 35px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  width: 35px;
`

const TopicCardName = styled(FlexColumn)`
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-top: auto;
  scroll-behavior: smooth;
`

const Lessons = styled(FlexRow)`
  overflow-x: scroll;
  padding-right: 40px;
  width: 1px;
  flex-grow: 1;
  @media only screen and (max-width: 565px) {
    padding-left: 20px;
  }
`

const CustomLesson = styled(Lesson)`
  &:first-child {
    margin-left: 0;
  }
  &:last-child:after {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    right: -55px;
    width: 55px;
  }
  @media only screen and (max-width: 565px) {
    &:last-child:after {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      right: -20px;
      width: 20px;
    }
  }
`

const VideoCount = styled(FlexRow)`
  margin-top: 20px;
`

const VideoCounter = styled.div`
  align-items: center;
  color: #FFFFFF;
  display: flex;
  height: 24px;
  justify-content: center;
  line-height: 24px;
  margin-left: auto;
  & > svg {
    margin-left: 10px;
  }
`

const OverlayContainer = styled(FlexRow)`
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  border-radius: 10px;
  color: white;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 565px) {
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    font-size: 24px;
    span {
      width: 100%;
    }
  }

  span {
    background: rgba(0,0,0,0.6);
    padding: 0 8px;
  }
`

const OverlayHeader = styled(FlexRow)`
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  border-radius: 10px;
  color: white;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 565px) {
    border-radius: 0;
  }
`

const BuyCourseButton = styled(Link)`
  display: inline-block;
  color: #044c74 !important;
  background: #f1f03e;
  font-size: 17px;
  border-radius: 3px;
  text-decoration: none;
  padding: 14px 26px;
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  margin-top: 20px;

  &:hover {
    opacity: 0.8;
  }
`

function getOverlayStatus({ enabled, paid, productOptions, disabledMessage, name }, isPaid) {
  const topicDisabled = !enabled
  const topicPaidRequired = paid

  const disabled = topicDisabled || topicPaidRequired

  if (!disabled) {
    return { disabled, Overlay: F }
  }

  if (topicPaidRequired) {
    if (isPaid) {
      return { disabled: false, Overlay: F }
    }

    return {
      disabled: true,
      Overlay: () => (
        <OverlayContainer>
          <span>{name}</span>
          <span>{disabledMessage}</span>
          <BuyCourseButton to={productOptions ? `/comprar-curso/${productOptions}` : F}>
           {productOptions ? 'Adquira já!' : 'Em breve'}
          </BuyCourseButton>
        </OverlayContainer>
      )
    }
  }

  if (topicDisabled) {
    return {
      disabled: true,
      Overlay: () => (
        <OverlayContainer>
          {disabledMessage}
        </OverlayContainer>
      )
    }
  }
}

function TopicRow({ topic = {} }) {
  const { currCourse = {} } = useCoursesCtx()
  const { bundles } = useAuthCtx()
  const lessonsRef = useRef()

  function scrollRight() {
    lessonsRef.current.scrollBy({
      left: lessonsRef.current.offsetWidth - 220,
      behavior: 'smooth'
    })
  }

  function scrollLeft() {
    lessonsRef.current.scrollBy({
      left: -lessonsRef.current.offsetWidth + 220,
      behavior: 'smooth'
    })
  }

  const ibselBundle = useMemo(() => {
    return bundles.find(x => x.topicsIds.includes(topic.id))
  }, [bundles, topic.id])

  const expired = useMemo(() => {
    if (!ibselBundle) return true
    return !isBefore(new Date(), new Date(ibselBundle.expiresAt))
  }, [ibselBundle])

  const { disabled, Overlay } = getOverlayStatus(topic, ibselBundle && !expired)

  return (
    <>
      <TopicMobileCard>
        {disabled && <OverlayHeader/>}
        {topic.name}
      </TopicMobileCard>
      <Topic>
        <Overlay />
        <TopicCard
          as={disabled ? 'div' : Link}
          to={disabled ? F : `/videos/${currCourse?.id}/disciplinas/${topic.id}`} topicId={topic.id} image={topic.image}
        >
          <VideoCount>
            <VideoCounter>{topic.subjectsCount}<Video size='24'/></VideoCounter>
          </VideoCount>
          <TopicCardName>
            {topic.name}
          </TopicCardName>
        </TopicCard>
        <TopicNav>
          <TopicNavButton onClick={scrollLeft}>
            <ChevronLeft size='16'/>
          </TopicNavButton>
          <TopicNavButton onClick={scrollRight}>
            <ChevronRight size='16'/>
          </TopicNavButton>
        </TopicNav>
        <Lessons ref={lessonsRef}>
          {topic.lessons.map(lesson => (
            <CustomLesson key={lesson.id} topicId={topic.id} {...lesson} disabled={disabled} />
          ))}
        </Lessons>
      </Topic>
    </>
  )
}

export default function() {
  const { currCourse } = useCoursesCtx()
  return(
    <Topics>
      <WelcomeModal/>
      {currCourse.topics.map(topic => (
        <TopicRow key={topic.id} topic={topic}/>
      ))}
    </Topics>
  )
}