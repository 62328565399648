const API_URL = process.env.REACT_APP_API_URL

export const buildUrl = (endpoint, params = {}) => {
  const url = new URL(
    endpoint.indexOf("http") === 0 ? endpoint : API_URL + endpoint
  )

  Object.entries(params).forEach(([k, v]) => {
    const isArray = v instanceof Array
    isArray
      ? v.forEach(item => url.searchParams.append(k, item))
      : url.searchParams.append(k, v)
  })

  return url
}

const mapHeaders = response => {
  const headers = {}

  response.headers.forEach((value, key) => {
    headers[key] = value
  })

  return headers
}

const parseTextResponse = async response => {
  const headers = mapHeaders(response)
  const data = await response.text()

  return { headers, data }
}

const parseJSONResponse = async response => {
  const headers = mapHeaders(response)
  const data = await response.json()

  if (response.status === 422) {
    throw(data)
  }

  return { headers, data }
}

const parseResponse = async response => {
  const contentType = (response.headers.get("content-type") || "").split(";")[0]

  if (contentType === "" || contentType.indexOf("text/plain") > -1) {
    return await parseTextResponse(response)
  }

  return await parseJSONResponse(response)
}

const DEFAULT_CONFIG = {
  headers: {
    Accept: "application/json",
  },
}

const getRequestConfig = (accessToken, custom = {}) => {
  const config = {
    ...DEFAULT_CONFIG,
    ...custom,
    headers: {
      ...DEFAULT_CONFIG.headers,
      ...(custom ? custom.headers : {}),
    },
  }

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  if (config.body) {
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json"
    }

    config.body = JSON.stringify(config.body)
  }

  return config
}

export const request = async (ctx, endpoint, config = { disabledJWT: false }) => {
  ctx.loader.inc()
  const url = typeof endpoint === "string" ? buildUrl(endpoint) : endpoint
  const accessToken = !config.disabledJWT ? await ctx.auth.getJWT() : undefined
  const response = await fetch(url, getRequestConfig(accessToken, config))
  ctx.loader.dec()
  if (!response.ok) {
    const errResponse = await parseResponse(response)
    throw (errResponse.data)
  }
  return parseResponse(response)
}

export const get = (ctx, endpoint, config = {}) =>
  request(ctx, endpoint, { ...config, method: "GET" })

export const head = (ctx, endpoint, config = {}) =>
  request(ctx, endpoint, { ...config, method: "HEAD" })

export const post = (ctx, endpoint, config = {}) =>
  request(ctx, endpoint, { ...config, method: "POST" })

export const put = (ctx, endpoint, config = {}) =>
  request(ctx, endpoint, { ...config, method: "PUT" })

export const patch = (ctx, endpoint, config = {}) =>
  request(ctx, endpoint, { ...config, method: "PATCH" })

export const del = (ctx, endpoint, config = {}) =>
  request(ctx, endpoint, { ...config, method: "DELETE" })