import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Layout from 'components/Layout'
import Courses from 'routes/Authenticated/Courses'
import BookList from 'routes/Authenticated/BookList'
import Community from 'routes/Authenticated/Community'
import Topic from 'routes/Authenticated/Topic'
import Exercises from 'routes/Authenticated/Exercises'
import Questions from 'routes/Authenticated/Questions'
import Flashcards from 'routes/Authenticated/Flashcards'
import CertificateList from 'routes/Authenticated/CertificateList'
import Subscribe from 'routes/Anonymous/routes/Subscribe'
import BuyCourse from 'routes/Anonymous/routes/BuyCourse'
import Certificate from 'routes/Anonymous/routes/Certificate'
import Simulation from 'routes/Authenticated/Simulation'
import ClinicalCases from 'routes/Authenticated/ClinicalCases'
import ClinicalCase from 'routes/Authenticated/ClinicalCases/routes/Detail'
import Playlists from 'routes/Authenticated/Playlists'
import Home from 'routes/Authenticated/Home'
import Lti from "routes/Authenticated/LTI"

import { Provider as LayoutContext } from 'contexts/Layout'

export default function () {
  return (
    <Switch>
      <Route exact path='/assinatura' component={Subscribe} />
      <Route path='/comprar-curso/:optionsHash' component={BuyCourse} />
      <Route exact path='/validacao/:certificateCode' component={Certificate} />
      <Route path='/*'>
        <LayoutContext>
          <Layout>
            <Switch>
              <Route exact path='/videos/:courseId?' component={Courses} />
              <Route
                exact
                path='/videos/:courseId/disciplinas/:topicId'
                component={Courses}
              />
              <Route exact path='/livros/:courseId?' component={BookList} />
              <Route
                exact
                path='/livros/:courseId/disciplinas/:topicId'
                component={BookList}
              />
              <Route
                exact
                path='/livros/:courseId/disciplinas/:topicId/:bookId'
                component={BookList}
              />
              <Route exact path='/comunidade' component={Community} />
              <Route exact path='/comunidade/:postId' component={Community} />
              <Route
                exact
                path='/exercicios/:courseId?'
                component={Exercises}
              />
              <Route
                exact
                path='/exercicios/:courseId/disciplinas/:topicId'
                component={Exercises}
              />
              <Route
                exact
                path='/questoes/:courseId/:topicId/:lessonsHash/:seed/:questionId?'
                component={Questions}
              />
              <Route
                exact
                path='/disciplinas/:topicId/modulos/:lessonId'
                component={Topic}
              />
              <Route
                exact
                path='/disciplinas/:topicId/modulos/:lessonId/assuntos/:subjectId'
                component={Topic}
              />
              <Route exact path='/certificados' component={CertificateList} />
              <Route path='/flashcards' component={Flashcards} />
              <Route
                exact
                path='/laboratorio-virtual/:courseId?'
                component={Simulation}
              />
              <Route
                exact
                path='/laboratorio-virtual/:courseId/disciplinas/:topicId'
                component={Simulation}
              />
              <Route
                exact
                path='/laboratorio-virtual/:courseId/disciplinas/:topicId/simulacao/:simulationId'
                component={Simulation}
              />
              <Route path='/estudos-de-caso/:caseGroupId/caso/:clinicalCaseId' component={ClinicalCase} />
              <Route path='/estudos-de-caso/:caseGroupId' component={ClinicalCases} />
              <Route path='/estudos-de-caso' component={ClinicalCases} exact />
              <Route path='/listas-de-reproducao' component={Playlists} exact />
              <Route path='/listas-de-reproducao/:playlistType' component={Playlists} exact />
              <Route exact path="/lti" component={Lti}/>
              <Route exact path='/' component={Home} />
              <Route exact path='/*' component={Home} />
            </Switch>
          </Layout>
        </LayoutContext>
      </Route>
    </Switch>
  )
}
