import React, { useMemo } from "react"
import styled from "styled-components"

import FlexColumn from "components/FlexColumn"
import * as Items from './Items'

import usePlaylistItemssCtx from "../../hooks/usePlaylistItemssCtx"


const ItemsArea = styled(FlexColumn)`
  position: relative;
  margin-bottom: 10px;
  @media only screen and (max-width: 750px) {
    margin-bottom: 0;
  }
`

const PlaylistTitle = styled.h2`
  color: #3f424a;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0px;
  margin-top: 0;
`

const ItemTypeTitle = styled.h1`
  color: #9da3a8;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
  margin-top: 0;
`

function RenderItems(itemType, history) {
  // TODO: Test
  const itemList = useMemo(() => itemType, [itemType])
  const Item = useMemo(() => Items[itemType.type], [itemType.type])
  return [<Item key={itemType.id} {...itemList} {...history}></Item>]
}

export default function() {
  const {
    playlist,
    itemType,
    history,
  } = usePlaylistItemssCtx()

  return (
    <ItemsArea>
      <FlexColumn>
        <PlaylistTitle>{playlist.name}</PlaylistTitle>
        <ItemTypeTitle>{itemType.name}</ItemTypeTitle>
      </FlexColumn>
      <RenderItems {...itemType} {...history} key={`${playlist.name}-${itemType.name}`}/>
    </ItemsArea>
  )
}