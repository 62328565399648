import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Envelope, Lock } from "styled-icons/fa-solid"

import AuthAPI from "datasources/auth"
import useAppContext from "hooks/useAppCtx"
import useForm from "hooks/useForm"
import Logo from "routes/Anonymous/components/Logo"
import Form from "routes/Anonymous/components/Form"
import Submit from "routes/Anonymous/components/Submit"
import Title from "routes/Anonymous/components/Title"
import Subtitle from "routes/Anonymous/components/Subtitle"
import Input from "routes/Anonymous/components/Input"
import AltAction from "routes/Anonymous/components/AltAction"

const initialStep1 = {
  email: "",
}

const initialStep2 = {
  password: "",
}

const constraintsStep1 = {
  email: { presence: { allowEmpty: false } },
}

const constraintsStep2 = {
  password: { presence: { allowEmpty: false } },
}

const errorsMap = {
  shouldBeValid: "E-mail de troca de senha inválido ou expirado.",
}

export default function({ match, history }) {
  const [submitted, setSubmitted] = useState(false)
  const { params: { resetPasswordToken } } = match
  const ctx = useAppContext()

  const initial = useMemo(() => {
    return !resetPasswordToken ? initialStep1 : initialStep2
  }, [resetPasswordToken])

  const constraints = useMemo(() => {
    return !resetPasswordToken ? constraintsStep1 : constraintsStep2
  }, [resetPasswordToken])

  const submit = async () => {
    try {
      if (!resetPasswordToken) {
        const host = `${window.location.protocol}//${window.location.host}`
        await AuthAPI.createPasswordReset(ctx, { ...values, host })
        setSubmitted(true)
      } else {
        await AuthAPI.updatePasswordReset(ctx, { ...values, resetPasswordToken })
        setSubmitted(true)
      }
    } catch(err) {
      setAllErrors(err)
    }
  }

  const {
    fields,
    values,
    setAllErrors,
    onSubmit,
  } = useForm(initial, { constraints, errorsMap })

  return (
    <Form onSubmit={onSubmit(submit)}>
      <Logo/>
      <Title>
        {!resetPasswordToken &&
          (!submitted ? "Esqueceu sua senha?" : "Verifique seu e-mail")}
        {resetPasswordToken &&
          (!submitted ? "Nova senha" : "Senha atualizada")}
      </Title>
      <Subtitle>
        {!resetPasswordToken &&
          (!submitted ?
            "Insira o seu e-mail no campo abaixo para enviarmos instruções para troca da mesma." :
            "Enviaremos um e-mail para o endereço informado, caso exista em nosso sistema.")}
        {resetPasswordToken &&
          (!submitted ?
            "Crie uma nova senha." :
            "Faça login com sua nova senha.")}
        {resetPasswordToken && ""}
      </Subtitle>
      {!resetPasswordToken && !submitted && <Input
        id="email"
        placeholder="Email"
        icon={Envelope}
        type="email"
        autoFocus
        {...fields.email}
      />}
      {resetPasswordToken && !submitted && <Input
        id="password"
        placeholder="Password"
        icon={Lock}
        type="password"
        autoFocus
        {...fields.password}
      />}
      {!submitted && <Submit value={!resetPasswordToken ? "Enviar" : "Entrar" }/>}
      {!submitted && <AltAction>
        Lembrou sua senha? <Link to="/entrar">Fazer login</Link>
      </AltAction>}
      {resetPasswordToken && submitted && <AltAction>
        <Link to="/entrar">Fazer login</Link>
      </AltAction>}
    </Form>
  )
}