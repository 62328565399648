import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

import FlexRow from "components/FlexRow"
import FlexColumn from "components/FlexColumn"

import useTopicCtx from "../hooks/useTopicCtx"

import { Play, Pause, Check, Video, ChevronDown, Lock } from "styled-icons/fa-solid"

const PlayIcon = styled(Play)`
  align-self: center;
  color: #676a74;
  margin-left: 10px;
  margin-right: 5px;
`

const PauseIcon = styled(Pause)`
  align-self: center;
  color: #676a74;
  margin-left: 10px;
  margin-right: 5px;
`

const CheckIcon = styled(Check)`
  align-self: center;
  color: #4EC437;
  margin-right: 10px;
`

const VideoIcon = styled(Video)`
  align-self: center;
  margin-right: 10px;
`

const LockIcon = styled(Lock)`
  align-self: center;
  margin-right: 10px;
`

const ChevronIcon = styled(ChevronDown)`
  align-self: center;
  color: #676a74;
  margin-right: 10px;
  ${({ isOpen }) => isOpen && css`
    transform: rotate(180deg);
  `}
`

const Container = styled(FlexColumn)`
  border-bottom: solid 1px #e8eaee;
  cursor: pointer;
  flex-shrink: 0;
`

const Header = styled(FlexRow)`
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`

const Accordion = styled(FlexRow)`
  align-items: center;
  padding-right: 15px;
  & > svg {
    flex-shrink: 0;
  }
  ${({ isOpen }) => isOpen && css`
    & svg:last-child {
      transform: rotate(180deg);
    }
  `}
`

const HeaderText = styled(FlexColumn)`
  cursor: pointer;
  padding-left: 10px;
`

const Index = styled.h3`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
  color: #9da3a8;
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  color: #676a74;
`

const Subjects = styled(FlexColumn)`
  display: none;
  ${({ isOpen }) => isOpen && css`
    display: flex;
    padding-bottom: 20px;
  `}
`

const Subject = styled(FlexRow)`
  color: #9da3a8;
  font-size: 16px;
  line-height: 40px;
  padding-left: 46px;
  & svg {
    flex-shrink: 0;
    fill: #676a74;
  }
  ${({ selected }) => selected && css`
    background-color: #ff595e;
    color: #fff;
    & svg {
      fill: #fff;
    }
  `}
`

const Ellipsis = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  display: inline-block;
  padding-right: 25px;
`



const Main = styled(FlexColumn)``

export default function Lesson({ id, name, position, subjects, play, pause, isPlaying, isSubjectRestricted, ready, justWatched = [], history }) {
  const {
    topic,
    currLesson,
    currSubject,
  } = useTopicCtx()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (id === currLesson.id) {
      setIsOpen(true)
    }
  }, [id, currLesson])

  return(
    <Container>
      <FlexRow>
        <Main>
          <Header>
            {id === currLesson.id ?
              isPlaying ?
                <PauseIcon onClick={pause} size="10"/> :
                  <PlayIcon onClick={play} size="10"/> :
                    <PlayIcon onClick={() => ready && history.push(`/disciplinas/${topic.id}/modulos/${id}/assuntos/${subjects[0].id}`)} size="10"/>}
            <Accordion onClick={() => setIsOpen(!isOpen)}>
              <HeaderText >
                <Index>Módulo {position + 1}</Index>
                <Title>{name}</Title>
              </HeaderText>
              <ChevronIcon isOpen={isOpen} size="10"/>
            </Accordion>
          </Header>
          <FlexRow>
            <Subjects isOpen={isOpen}>
              {subjects.map(subject =>
                <Subject key={subject.id} selected={currSubject.id === subject.id} onClick={() => ready && (history.push(`/disciplinas/${topic.id}/modulos/${id}/assuntos/${subject.id}`) || window.scrollTo(0, 0))}>
                  {
                    isSubjectRestricted(subject) ?
                      <LockIcon size="15"/> :
                      subject.watched || justWatched.includes(subject.id) ?
                        <CheckIcon size="15"/> :
                          <VideoIcon size="15"/>
                  }
                  <Ellipsis>{subject.name}</Ellipsis>
                </Subject>
              )}
            </Subjects>
          </FlexRow>
        </Main>
      </FlexRow>

    </Container>
  )
}