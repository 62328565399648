import styled from 'styled-components'

const Title = styled.h2`
font-weight: 16px;
color: #696060;
border-bottom: 1px solid #e4e4e4;
margin: 0;

@media only screen and (max-width: 812px) and (orientation: landscape) {
  font-size:1em;
}
`

export default Title