import React, { useState, useMemo, useCallback } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import img from './WelcomeModal.jpg'
import useAppCtx from "hooks/useAppCtx"
import useAuthCtx from "hooks/useAuthCtx"
import isBefore from "date-fns/is_before"
import mixpanel from "utils/mixpanel"

const Overlay = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  width: 600px;
  height: 600px;
  background-image: url(${img});
  background-size: 100% 100%;
  border-radius: 5px;
  cursor: pointer;
  @media only screen and (max-width: 565px) {
    width: 300px;
    height: 300px;
  }
`

export default function() {
  const history = useHistory()
  const ctx = useAppCtx()
  const { bundles } = useAuthCtx()
  const expired = useMemo(
    () =>  {
      const ibselBundle = bundles.find(x => x.bundleId === 1)
      if (!ibselBundle) return true
      return !isBefore(new Date(), new Date(ibselBundle.expiresAt))
    }
  , [bundles])
  const [isOpen, setIsOpen] = useState(expired)
  // TODO: React Hook useCallback does nothing when called with only one argument. Did you forget to pass an array of dependencies?
  // eslint-disable-next-line
  const onClick = useCallback((e) => {
    e.stopPropagation()
    mixpanel(ctx, "Banner_Click")
    history.push("/assinatura")
  })

  return(
    isOpen && <Overlay onClick={() => setIsOpen(false)}>
      <Box onClick={onClick}>
      </Box>
    </Overlay>
  )
}