import React from "react"
import styled from "styled-components"
import { CheckCircle, TimesCircle } from "styled-icons/fa-solid"
import { format } from "date-fns"

import useAppCtx from "hooks/useAppCtx"
import usePromise from "hooks/usePromise"
import Logo from "routes/Anonymous/components/Logo"
import CertificatesAPI from "datasources/certificates"

const Wrapper = styled.div`
  align-items: center;
  background-color: #1CACE3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='40' viewBox='0 0 50 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23bce0fd' fill-opacity='0.05'%3E%3Cpath d='M40 10L36.67 0h-2.11l3.33 10H20l-2.28 6.84L12.11 0H10l6.67 20H10l-2.28 6.84L2.11 10 5.44 0h-2.1L0 10l6.67 20-3.34 10h2.11l2.28-6.84L10 40h20l2.28-6.84L34.56 40h2.1l-3.33-10H40l2.28-6.84L47.89 40H50l-6.67-20L50 0h-2.1l-5.62 16.84L40 10zm1.23 10l-2.28-6.84L34 28h4.56l2.67-8zm-10.67 8l-2-6h-9.12l2 6h9.12zm-12.84-4.84L12.77 38h15.79l2.67-8H20l-2.28-6.84zM18.77 20H30l2.28 6.84L37.23 12H21.44l-2.67 8zm-7.33 2H16l-4.95 14.84L8.77 30l2.67-8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  color: #FFFFFF;
  display: flex;
  line-height: 1.5;
  justify-content: center;
  min-height: 1vh;
  padding-bottom: 25px;
  padding-top: 25px;
  width: 100%;
  @media only screen and (max-width: 565px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
`

const Title = styled.h1`
  color: white;
  font-size: 26px;
  margin-bottom: 40px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
`

const Fields = styled.div`
  color: white;
  line-height: 20px;
  margin-bottom: 20px;
`

const FieldLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`

const FieldValue = styled.div`
  margin-bottom: 20px;
  & > svg {
    margin-left: 5px;
  }
`

// const Button = styled.a`
//   background-color: #FFCA3A;
//   border-radius: 5px;
//   color: #555555;
//   font-size: 18px;
//   font-weight: bold;
//   height: 50px;
//   line-height: 50px;
//   padding: 0 25px;
//   text-align: center;
// `

const NotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  margin-top: 60px;
  text-align: center;
  & > svg {
    margin-bottom: 20px;
  }
  & > small {
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
  }
`

function useCertificatesApi(code) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function() {
    return CertificatesAPI.get(ctx, code)
  }, [code])

  return {
    certificate: result && result.data,
    isFetching: !isResolved,
  }
}

export default function ({ match, history }) {
  const { certificateCode } = match.params

  const { certificate, isFetching} = useCertificatesApi(certificateCode)
  // const failed = true

  if (isFetching)
    return <></>

  return (<Wrapper>
    <Container>
      <Logo/>
      <Title>Instituto Brasileiro de Saúde Euclides Leite</Title>
      {certificate ? <>
        <Fields>
          <FieldLabel>Status</FieldLabel>
          <FieldValue>Concluído <CheckCircle size="20"/></FieldValue>
          <FieldLabel>Nome</FieldLabel>
          <FieldValue>{certificate.user.name}</FieldValue>
          <FieldLabel>Curso</FieldLabel>
          <FieldValue>{certificate.topic.name}</FieldValue>
          <FieldLabel>Carga Horária</FieldLabel>
          <FieldValue>8 horas</FieldValue>
          <FieldLabel>Data de Conclusão</FieldLabel>
          <FieldValue>{format(certificate.userTopic.certificateDate, 'DD/MM/YYYY')}</FieldValue>
          <FieldLabel>Código de Verificação</FieldLabel>
          <FieldValue>{certificateCode}</FieldValue>
        </Fields>
      </> :
        <NotFound>
          <TimesCircle size="60"/>
          Certificado Inválido
          <small>Verifique o código de verificação e tente novamente.</small>
        </NotFound>
      }
    </Container>
  </Wrapper>)
}