import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import useAuthCtx from "hooks/useAuthCtx"

import Login from "./routes/Login"
import Lti from "./routes/LTI"
import Register from "./routes/Register"
import ConfirmAccount from "./routes/ConfirmAccount"
import Subscribe from "./routes/Subscribe"
import BuyCourse from "./routes/BuyCourse"
import ResetPassword from "./routes/ResetPassword"
import Certificate from "./routes/Certificate"

export default function() {
  const { authed, user, emailConfirmed } = useAuthCtx()

  return (
    <Switch>
      <Route render={() =>
        authed ? user && emailConfirmed ?
          <Switch>
            <Route path="/comprar-curso/:optionsHash" component={BuyCourse}/>
            <Route path="/assinatura" component={Subscribe}/>
            <Redirect to="/assinatura"/>
          </Switch> :
          <Switch>
            <Route path="/confirmar-email/:accountConfirmationToken?" component={ConfirmAccount}/>
            <Redirect to="/confirmar-email"/>
          </Switch> :
          <Switch>
            <Route exact path="/entrar" component={Login}/>
            <Route exact path="/registrar" component={Register}/>
            <Route exact path="/lti" component={Lti}/>
            <Route exact path="/reiniciar-senha/:resetPasswordToken?" component={ResetPassword}/>
            <Route path="/confirmar-email/:accountConfirmationToken?" component={ConfirmAccount}/>
            <Route path="/validacao/:certificateCode" component={Certificate}/>
            <Redirect to="/registrar"/>
          </Switch>
      }/>
    </Switch>
  )
}