import React, { useMemo } from "react"
import styled, { css } from "styled-components"

import FlexRow from "components/FlexRow"

const Container = styled.div`
  width: 100%;
`

const Pill = styled(FlexRow)`
  border-radius: 5px;
  border: solid 1px transparent;
  color: #BCE0FD;
  fill: #BCE0FD;
  min-height: 50px;
  padding: 15px;
  stroke: #BCE0FD;
  width: 100%;
  &:focus-within {
    color: #1CACE3;
    fill: #1CACE3;
    stroke: #1CACE3;
  }
  > svg {
    flex-shrink: 0;
    margin-left: auto;
    height: 20px;
    ${({ iconWidth }) => css`
      width: ${iconWidth}px;
    `}
    align-self: center;
  }
  ${({ hasError }) => hasError && css`
    border: solid 1px #FF595E;
  `}
`

const HTMLTextarea = styled.textarea`
  box-sizing: border-box;
  background: none;
  border: none;
  color: #1CACE3;
  font-size: 16px;
  min-height: 50px;
  left: 0;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 0;
  padding-right: 20px;
  top: 0;
  width: 100%;
  &::placeholder {
    color: #BCE0FD;
  }
  &:focus::placeholder {
    color: #1CACE3;
  }
`

const Error = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #FFCA3A;
  margin-top: 5px;
`

export default function Textarea({ id, placeholder, onChange, value, rows, icon: Icon, iconWidth = 20, autoFocus, mask, errors = [], type = "text", ...rest }) {
  const hasError = useMemo(() => (
    errors && errors.length > 0
  ), [errors])

  return (
    <Container>
      <Pill hasError={hasError} iconWidth={iconWidth}>
        <HTMLTextarea
          autoFocus={autoFocus}
          placeholder={placeholder}
          id={id}
          onChange={onChange}
          value={value}
          type={type}
          mask={mask}
          maskPlaceholder={null}
          iconWidth={iconWidth}
          rows={rows}
          {...rest}
        />
        {Icon && <Icon/>}
      </Pill>
      {hasError && <Error>{errors[0]}</Error>}
    </Container>
  )
}