import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "./routes/List"
import Detail from "./routes/Detail"

export default function() {
  return (
    <Switch>
      <Route exact path="/flashcards/:slug" component={Detail}/>
      <Route exact path="/flashcards" component={List}/>
    </Switch>
  )
}