import React, { useState } from "react"
import { Link } from "react-router-dom"
import styled, { css} from "styled-components"
import { ChevronDown, Check } from "styled-icons/fa-solid"

const Wrapper = styled.div`
  color: #9da3a8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  border-bottom: solid 1px #e8eaee;
`

const Lesson = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 20px;
`

const LessonName = styled.div`

`

const Percent = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  ${({ complete }) => complete && css`
    color: #4EC437;
  `}
`

const ChevronIcon = styled(ChevronDown)`
  align-self: center;
  color: #676a74;
  flex-shrink: 0;
  margin-left: 10px;
  ${({ isOpen }) => isOpen && css`
    transform: rotate(180deg);
  `}
`

const Subjects = styled.div``

const Subject = styled(Link)`
  color: #9da3a8;
  display: block;
  overflow: hidden;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 15px;
  padding-top: 10px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  min-width: 0;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
`

const CheckIcon = styled(Check)`
  position: absolute;
  top: 13px;
  left: 20px;
  color: #4EC437;
  width: 10px;
`

export default function({ topicId, lesson }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Wrapper>
      <Lesson onClick={() => setIsOpen(!isOpen)}>
        <LessonName>{lesson.name}</LessonName>
        <Percent complete={lesson.watchedPercentage===100}>
          {lesson.watchedPercentage}%
        </Percent>
        <ChevronIcon size="10" isOpen={isOpen}/>
      </Lesson>
      {isOpen &&
        <Subjects>
          {lesson.subjects.map(subject =>
            <Subject to={`/disciplinas/${topicId}/modulos/${lesson.id}/assuntos/${subject.id}`}>
              {!!subject.watched && <CheckIcon size="14"/>}
              {subject.name}
            </Subject>)
          }
        </Subjects>
      }
    </Wrapper>
  )
}