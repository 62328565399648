import React from "react"
import styled, { css } from "styled-components"

import useAppCtx from "hooks/useAppCtx"
import UserAPI from "datasources/users"
import useForm from "hooks/useForm"

const Overlay = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  width: 600px;
  height: auto;
  background-color: white;
  border-radius: 5px;
  padding: 40px 60px;
`

const Instructions = styled.div`
  line-height: 1.8;
  margin-bottom: 40px;
`

const Header = styled.div`
  font-size: 26px;
  margin-bottom: 40px;
`

const Input = styled.input`
  border-bottom: solid 2px #1CACE3;
  width: 100%;
  font-size: 20px;
  padding-bottom: 10px;
  margin-bottom: 40px;
  ${({ errors }) => errors && errors.length > 0 && css`
    border-color: #ff595e;
    margin-bottom: 10px;
  `}
`

const Button = styled.button`
  display: block;
  background-color: #1CACE3;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
  font-size: 18px;
  line-height: 2;
  margin-left: auto;
  padding: 5px 10px;
  text-align: center;
`

const Error = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ff595e;
  margin-bottom: 30px;
`


const initial = {
  name: '',
}

const constraints = {
  name: {
    presence: { allowEmpty: false },
  }
}

export default function({ close, onSuccess }) {
  const ctx = useAppCtx()

  const {
    fields,
    values,
    errors,
    setAllErrors,
    onSubmit,
  } = useForm(initial, { constraints })

  async function submit() {
    try {
      await UserAPI.putName(ctx, values)
      onSuccess()
      close()
    } catch(err) {
      setAllErrors(err)
    }
  }

  return(
    <Overlay onClick={close}>
      <Box onClick={e => e.stopPropagation()}>
        <Header>Confirme seu nome completo</Header>
        <Instructions>
          Para que seu certificado seja aceito em sua universidade é essencial que seu nome esteja <strong>COMPLETO</strong>:
        </Instructions>
        <form onSubmit={onSubmit(submit)}>
          <Input placeholder="João Aurélio da Silva" {...fields.name}/>
          {errors && errors.name && errors.name.length > 0 && <Error>{errors.name[0]}</Error>}
          <Button>
            Confirmar
          </Button>
        </form>
      </Box>
    </Overlay>
  )
}