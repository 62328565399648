import React, { useEffect, useCallback } from "react"
import styled from "styled-components"

import { getImgixUrl } from "utils/imgix"
import mixpanel from "utils/mixpanel"
import useAppCtx from "hooks/useAppCtx"
import FlexRow from "components/FlexRow"

import useBooksCtx from "../../hooks/useBooksCtx"
import useUniversityCtx from 'hooks/useUniversityCtx'

const Container = styled(FlexRow)`
  background-color: #f2f7fa;
  border-radius: 10px;
  padding: 60px;
  @media only screen and (max-width: 565px) {
    display: block;
    padding: 20px;
  }
`

const Cover = styled.img`
  align-self: flex-start;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  height: 560px;
  margin-right: 80px;
  position: relative;
  width: 400px;
  @media only screen and (max-width: 565px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`

const Body = styled.div`
  color: #9DA3A8;
  & > h1, & > h2 {
    color: #3F424A;
  }
  & > h1 {
    font-size: 30px;
    margin-top: 0;
  }
  & > h2 {
    font-size: 20px;
  }
  & > pre, & > p {
    font-size: 16px;
    font-weight: normal
    line-height: 22px;
    white-space: pre-wrap;
  }
`

const Button = styled.a`
  background-color: #1CACE3;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  outline: 0;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  @media only screen and (max-width: 565px) {
    width: 100%;
    margin-top: 20px;
  }
`

export default function() {
  const ctx = useAppCtx()
  const { currBook, currTopic } = useBooksCtx()
  const { universities } = useUniversityCtx()

  const onBuy = useCallback(() => {
    mixpanel(ctx, "Books_Book_Buy", { "Topic_Name": currTopic.name, "Book_Title": currBook.title, "University": universities.name })
    // TODO: Performance test
    // eslint-disable-next-line
  }, [ctx, currBook, currTopic])

  useEffect(() => {
    mixpanel(ctx, "Books_Book_Detail", { "Topic_Name": currTopic.name, "Book_Title": currBook.title, "University": universities.name })
    // eslint-disable-next-line
  }, [ctx, currBook, currTopic])

  return(
    <Container>
      <Cover src={getImgixUrl(`books/${currBook.id}/image`, { w: 400, h: 560})}/>
      <Body>
        <h1>{currBook.title}</h1>
        <p>Páginas: {currBook.pageCount}</p>
        <br/>
        <h2>Autor</h2>
        <pre>{currBook.authors}</pre>
        <br/>
        <h2>Descrição</h2>
        <pre>{currBook.description}</pre>
        <br/>
        <br/>
        {universities && universities.name !== 'Varejo' &&
        <Button
          onClick={onBuy}
          href={currBook.affiliateUrl}
          target="_blank"
        >

          Acessar Biblioteca
        </Button>
        }
      </Body>
    </Container>
  )
}