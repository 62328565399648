import styled from "styled-components"

export const Subtitle = styled.h2`
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 60px;
  margin-top: 0;
  text-align: center;
`

export default Subtitle