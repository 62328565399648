import React from "react"

import Questions from "./components/Questions"
import { Provider } from "./contexts/Questions"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <Questions match={match} history={history}/>
    </Provider>
  )
}