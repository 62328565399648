import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import useVimeo from 'hooks/useVimeo'
import useClinicalCaseCtx from '../../hooks/useClinicalCaseCtx'
import * as Core from '../Core'
import { VolumeOff, VolumeUp } from 'styled-icons/fa-solid'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const MainCol = styled(Core.MainCol)`
`

const Title = styled(Core.Title)`
`

const Contents = styled.span`
  line-height: 22px;
  text-align: justify;
  font-weight: 500;
`

// const Img = styled.img`
//   max-width: 100%;
//   align-self: flex-end;
// `

const Unmute = styled.div`
  background-color: rgba(23, 35, 34, 0.74902);
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  padding: 20px 33px;
  position: absolute;
  right: 16px;
  top: 57px;
  z-index: 10;
  &:hover {
    background-color: #1cace3;
  }
`

function VideoStatic(props) {
  const {
    ref,
    setVimeoId,
    setEndedCallback,
    // isPlaying,
    isMuted,
    // play,
    // pause,
    mute,
    unmute,
    // ready,
  } = useVimeo({ debug: true, controls: false })

  const { next } = useClinicalCaseCtx()

  const advance = useCallback(() => next(props.nextAmount), [
    next,
    props.nextAmount,
  ])

  useEffect(() => {
    setVimeoId(props.videoId)
    setEndedCallback(() => advance)
    // eslint-disable-next-line
  }, [props.videoId])

  return (
    <Core.Frame backAmount={props.backAmount} nextAmount={props.nextAmount}>
      <Wrapper>
        <MainCol>
          <Title>
            <ReactMarkdown>{props.title}</ReactMarkdown>
          </Title>
          <br />
          <Contents>
            <div ref={ref}>
              <Unmute onClick={() => (isMuted ? unmute() : mute())}>
                {isMuted ? <VolumeOff size='30' /> : <VolumeUp size='30' />}
              </Unmute>
            </div>
          </Contents>
        </MainCol>
      </Wrapper>
    </Core.Frame>
  )
}

VideoStatic.propTypes = {
  backAmount: PropTypes.number.isRequired,
  nextAmount: PropTypes.number.isRequired,
  videoId: PropTypes.string.isRequired,
}

VideoStatic.defaultProps = {
  backAmount: 1,
  nextAmount: 1,
}

export default VideoStatic
