import React, { useState, createContext } from "react"

import ClinicalCasesAPI from "datasources/clinicalCases"
import usePromise from "hooks/usePromise"
import useAppCtx from "hooks/useAppCtx"

const Context = createContext()

function useClinicalCase(clinicalCaseId) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function () {
    return ClinicalCasesAPI.get(ctx, { clinicalCaseId })
  }, [])

  return {
    clinicalCase: result && result.data,
    isFetchingClinicalCase: !isResolved,
  }
}

export function Provider({ children, history, match }) {
  const { clinicalCaseId } = match.params

  const {
    clinicalCase,
    isFetchingClinicalCase
  } = useClinicalCase(clinicalCaseId)

  const localStorageKey = `clinicalCase/${clinicalCaseId}`
  const initial = parseInt(localStorage.getItem(localStorageKey) || 0)
  const [curr, setCurr] = useState(initial)

  const next = amount => {
    const newIdx = curr + amount
    localStorage.setItem(localStorageKey, newIdx)
    setCurr(newIdx)
  }
  const back = amount => {
    const newIdx = curr - amount
    localStorage.setItem(localStorageKey, newIdx)
    setCurr(newIdx)
  }

  const clear = () => {
    localStorage.removeItem(`clinicalCase/${clinicalCaseId}`)
    window.location.reload()
  }

  const value = {
    clinicalCase,
    isFetchingClinicalCase,
    curr,
    next,
    back,
    clear,
  }

  return (
    <Context.Provider value={value}>
      {!isFetchingClinicalCase && clinicalCaseId && children }
    </Context.Provider>
  )
}

export default Context