import styled from "styled-components"

export const Submit = styled.input.attrs({ type: 'submit' })`
  background-color: #FFCA3A;
  border-radius: 5px;
  color: #555555;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 25px;
  font-weight: 500;
`

export default Submit