import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import useClinicalCaseCtx from '../../hooks/useClinicalCaseCtx'
import * as Core from '../Core'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const MainCol = styled(Core.MainCol)`
`

const Title = styled(Core.Title)`
`

const Contents = styled.span`
  line-height: 22px;
  text-align: justify;
  font-weight: 500;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    & > p, & > ul {
      font-size:0.8em;
    }
    & > h3 {
      font-size:1em;
    }
  }
`

const Img = styled.img`
  max-width: 100%;
  align-self: flex-end;
`

function TxtFullStatic(props) {
  const { clinicalCase } = useClinicalCaseCtx()
  const [modalInfoProps] = useState({ visible: false })

  return (
    <Core.Frame
      backAmount={props.backAmount}
      nextAmount={props.nextAmount}
      modalInfoProps={modalInfoProps}
    >
      <Wrapper>
        <MainCol>
          <Title>
            <ReactMarkdown>{props.title}</ReactMarkdown>
          </Title>
          <Contents>
            <ReactMarkdown>{props.content}</ReactMarkdown>
          </Contents>
          {props.image === true && (
            <Img
              src={`https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/clinical-case/${clinicalCase.id}/case-frame/${props.id}/image`}
            />
          )}
        </MainCol>
      </Wrapper>
    </Core.Frame>
  )
}

TxtFullStatic.propTypes = {
  backAmount: PropTypes.number.isRequired,
  nextAmount: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
}

TxtFullStatic.defaultProps = {
  backAmount: 1,
  nextAmount: 0,
  hasInfo: 0,
}

export default TxtFullStatic
