import React, { useMemo } from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  margin-bottom: 25px;
  width: 100%;
`

const HTMLSelect = styled.select`
  -webkit-appearance: none;
  background-color: #FFFFFF;
  background-image: linear-gradient(45deg, transparent 50%, #BCE0FD 50%), linear-gradient(135deg, #BCE0FD 50%, transparent 50%);
  background-position: calc(100% - 25px) 22px, calc(100% - 19px) 22px;
  background-repeat: no-repeat;
  background-size: 6px 6px, 6px 6px;
  border-radius: 5px;
  border: solid 1px transparent;
  color: #1CACE3;
  font-size: 16px;
  height: 50px;
  outline: none;
  padding: 15px;
  width: 100%;
  &:focus-within {
    color: #1CACE3;
    background-image: linear-gradient(45deg, transparent 50%, #1CACE3 50%), linear-gradient(135deg, #1CACE3 50%, transparent 50%);
  }
  ${({ value }) => value === "" && css`
    color: #BCE0FD;
  `}
  ${({ hasError }) => hasError && css`
    border: solid 1px #FF595E;
  `}
`

const Error = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #FFCA3A;
  margin-top: 5px;
`

export default function Select({ id, label, onChange, value, errors = [], options = [], changeTransform = x => x, valueKey = "id", labelKey = "name"}) {
  const hasError = useMemo(() => (
    errors && errors.length > 0
  ), [errors])

  return (
    <Container>
      <HTMLSelect id={id} onChange={e => onChange(changeTransform(e.target.value))} value={value} hasError={hasError}>
        <option value="">{label}</option>
        {options?.map(x => (
          <option key={x[valueKey]} value={x[valueKey]}>
            {x[labelKey]}
          </option>
        ))}
      </HTMLSelect>
      {hasError && <Error>{errors[0]}</Error>}
    </Container>
  )
}