import React from 'react'
import styled from 'styled-components'

import FlexRow from 'components/FlexRow'
import NotFound from './notFound.js'
import { Link } from 'react-router-dom'
import { NotesMedical } from "styled-icons/fa-solid"
import useClinicalCasesCtx from "../../hooks/useClinicalCasesCtx"

const Topic = styled(Link)`
  text-decoration: none;
  padding: 33px;
  width: 255px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  box-shadow: 2px 3px 2px 0px #545454;
  background-color: #632121;
  span {
    margin-left: 10px;
  }
  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    flex-flow: column;
    width: 150px;
    margin-right: 0;
    padding: 15px;
    span {
      margin-left: 0;
      font-size: 15px;
    }
  }
`

const Container = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 0 -15px;
  > * {
    margin: 15px 15px;
  }
`

export default function () {
  const { currCaseGroup } = useClinicalCasesCtx()

  return (
    <Container>
      { currCaseGroup !== undefined && currCaseGroup.clinicalCases.map((clinicalCase, i) => {
        return (
          <Topic to={`/estudos-de-caso/${currCaseGroup.id}/caso/${clinicalCase.id}`} key={i}>
            <NotesMedical size="25" color="#FFFFFF"/>
            <span>{clinicalCase.title}</span>
          </Topic>
        )
      })}

      { currCaseGroup === undefined && (
        <NotFound />
      )}
    </Container>
  )
}
