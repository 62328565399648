import React, { useEffect, useMemo, createContext, useState } from 'react'

import PlaylistsAPI from 'datasources/playlists'
import usePromise from 'hooks/usePromise'
import useAppCtx from 'hooks/useAppCtx'
import byId from 'utils/byId'
// import useApi from 'hooks/useApi'

const Context = createContext()

function usePlaylistList() {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function () {
    return PlaylistsAPI.list(ctx)
  })

  return {
    playlistTypes: result && result.data,
    isFetchingPlaylists: !isResolved,
  }
}

export function Provider({ children, history, match }) {
  const { playlistType } = match.params
  const [currPlaylist, setCurrPlaylist] = useState(null)
  const [currPlaylistItemType, setCurrPlaylistItemType] = useState(null)

  const { playlistTypes, isFetchingPlaylists } = usePlaylistList()

  const currPlaylistType = useMemo(() => {
    if (playlistTypes) {
      return playlistTypes.find(byId(playlistType))
    }
  }, [playlistTypes, playlistType])

  function changePlaylist(playlist, playlistItemType) {
    setCurrPlaylist(playlist)
    setCurrPlaylistItemType(playlistItemType || playlist.itemTypes[0])
  }

  const value = {
    playlistTypes,
    currPlaylistType,
    currPlaylist,
    currPlaylistItemType,
    changePlaylist,
  }

  useEffect(() => {
    if (playlistTypes && !playlistType) {
      if (playlistTypes.length > 0)
        history.replace(`/listas-de-reproducao/${playlistTypes[0].id}`)
      else history.replace(`/listas-de-reproducao/0`)
    }
  }, [history, playlistTypes, playlistType])

  useEffect(() => {
    if (currPlaylistType !== undefined && !currPlaylist && playlistTypes) {
      const [item] = playlistTypes
      const [playlist] = item.playlists
      const { itemTypes = [] } = playlist || {}

      setCurrPlaylist(playlist)
      setCurrPlaylistItemType(itemTypes[0])
    }
  }, [currPlaylistType, currPlaylist, playlistTypes])

  return (
    <Context.Provider value={value}>
      {!isFetchingPlaylists && children}
    </Context.Provider>
  )
}

export default Context
