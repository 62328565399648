import styled from 'styled-components'

export const AltAction = styled.div`
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
  & > a,
  & > span {
    text-decoration: underline;
    cursor: pointer;
    color: #ffffff;
  }
`

export default AltAction
