import { get, buildUrl } from './common'

export default {
  async list(ctx) {
    return await get(ctx, buildUrl(`/public/playlists`))
  },
  async get(ctx, playlistId, itemTypeId) {
    return await get(
      ctx,
      buildUrl(`/public/playlists/${playlistId}/types/${itemTypeId}`),
    )
  },
}
