import React, { useCallback, useEffect } from "react"
import styled, { css } from "styled-components"
import { Route, Switch } from "react-router-dom"

import FlexRow from "components/FlexRow"
import useLayoutCtx from "hooks/useLayoutCtx"

import BookDetailRoute from "../routes/BookDetail"
import TopicDetailRoute from "../routes/TopicDetail"
import TopicListRoute from "../routes/TopicList"
import useBooksCtx from "../hooks/useBooksCtx"

import Tabs from "components/Tabs"

const NavBar = styled(FlexRow)`
  @media only screen and (max-width: 565px) {
    flex-direction: column;
  }
`

const Courses = styled(Tabs)`
  height: 40px;
  margin-bottom: 40px;
`

const Course = styled(Tabs.item)`
  background-color: #E8EAEE;
  color: #044c74;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  margin-right: 0px;
  padding: 11px 20px;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  ${({ selected }) => selected && css`
    border-top-color: #044c74;
    border-top: 1px solid;
    background-color: #FFFFFF;
    color: #044c74;
    cursor: pointer;
  `}
`

const TopicsDropdown = styled.select`
  -webkit-appearance: none;
  background-color: #fff;
  color: #9da3a8;
  display: block;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  max-width: 260px;
  outline: none;
  padding-left: 15px;
  padding-right: 45px;
  border: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;

  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
  }
`

export default function({ history }) {
  const { setLayoutState } = useLayoutCtx()

  const {
    courses,
    currCourse,
    currTopic,
    currBook,
  } = useBooksCtx()

  const courseChanged = useCallback(id => e =>
    history.push(`/livros/${id}`)
  , [history])

  const topicChanged = useCallback(e =>
    e.target.value ?
      history.push(`/livros/${currCourse.id}/disciplinas/${e.target.value}`) :
      history.push(`/livros/${currCourse.id}`)
  , [history, currCourse])

  useEffect(() => {
    if (currBook) {
      setLayoutState({ title: currBook.title, subTitle: 'Livros', backLink: '/livros' })
    } else if (currTopic) {
      setLayoutState({ title: currTopic.name, subTitle: 'Livros', backLink: '/livros' })
    } else {
      setLayoutState({ title: 'Livros' })
    }
    return (() => {
      setLayoutState({})
    })
  }, [currBook, currTopic, setLayoutState])

  return (
    <>
      {!currBook && <NavBar>
        <Courses>
          {courses.map(course => {
            const isCurrCourse = currCourse.id === course.id
            return (
              <Course
                key={course.id}
                selected={isCurrCourse}
                onClick={!isCurrCourse ? courseChanged(course.id) : undefined}
              >
                <Tabs.bullet />
                {course.name}
              </Course>
            )
          })}
        </Courses>
        <TopicsDropdown value={(currTopic && currTopic.id) || ""} onChange={topicChanged}>
          <>
            <option value="">Todas Disciplinas</option>
            {currCourse.topics.map(topic => (
              <option
                key={topic.id}
                value={topic.id}
              >
                {topic.name}
              </option>
            ))}
          </>
        </TopicsDropdown>
      </NavBar>}
      <Switch>
        <Route exact path="/livros/:courseId/disciplinas/:topicId/:bookId" component={BookDetailRoute}/>
        <Route exact path="/livros/:courseId/disciplinas/:topicId" component={TopicDetailRoute}/>
        <Route exact path="/livros/:courseId" component={TopicListRoute}/>
      </Switch>
    </>
  )
}