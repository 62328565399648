import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { InfoCircle } from 'styled-icons/fa-solid'

import useClinicalCaseCtx from '../../hooks/useClinicalCaseCtx'
import CoreBtn from './Btn'
import Modal from './Modal'
import ModalInfo from './ModalInfo'
import { NavLink } from 'react-router-dom'

const Disclaimer = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;
  background-color: #1cace3;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 40px;
  align-content: center;

  & > svg {
    margin-right: 10px;
    flex-shrink: 0;
  }

  @media only screen and (max-width: 812px) and (orientation: portrait) {
    display: flex;
  }

  @media only screen and (max-width: 565px) {
    padding: 5px 10px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 880px;
  position: relative;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    height: 100vh;
    width: auto;
    max-width: 812px;
    overflow-y: hidden;
  }
  @media only screen and (max-width: 896px) and (orientation: landscape) {
    height: 100vh;
    max-width: 896px;
    overflow-y: hidden;
    width: auto;
  }
`

const Container = styled.div`
  background-color: #f9fafb;
  flex: 1;
  padding: 22px;
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    padding: 1px !important;
  }
  @media only screen and (max-width: 896px) and (orientation: landscape) {
    padding: 1px !important;
  }

`

const BottomBar = styled.div`
  background-color: #fff;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 4px 16px;
  box-shadow: 0px -2px 3px -2px #757575;
  border-bottom: 1px solid #f3eaea;
`

// const Btn = styled(CoreBtn)`
//   width: auto;
//   margin-left: 8px;
// `
const BtnF = styled(CoreBtn)`
  width: 120px;
  text-align: center;
`
const BtnB = styled(CoreBtn)`
  width: 120px;
  text-align: center;
  margin-right: 0;
  margin-left: auto;
`

// const Img = styled.img`
//   max-width: 100%;
// `

const BtnR = styled(CoreBtn)`
  width: auto;
  margin-right: 0;
  background-color: #6f5f5f;
  color: white;
`
const BtnMenu = styled(NavLink)`
  display: none;
  @media only screen and (max-width: 812px)  and (orientation: landscape) {
    display: block;
    width: 80px;
    border: 1px solid #cacaca;
    background-color: #764a88;
    color: white;
    text-decoration: none;
    text-align:center;
    padding: 8px;
    position: relative;
  }
  @media only screen and (max-width: 896px)  and (orientation: landscape) {
    display: block;
    width: 80px;
    border: 1px solid #cacaca;
    background-color: #764a88;
    color: white;
    text-decoration: none;
    text-align:center;
    padding: 8px;
    position: relative;
  }
`

function Frame(props) {
  const { back, next, clear } = useClinicalCaseCtx()

  return (
    <div>
      <Disclaimer>
        <InfoCircle size='20' />
        Para melhor visualização deste conteúdo coloque seu dispositivo na
        horizontal.
      </Disclaimer>
      <Wrapper>
        <Modal {...props.modalProps} />
        <ModalInfo {...props.modalInfoProps} />
        <Container>{props.children}</Container>
        <BottomBar>
          <BtnMenu to='/estudos-de-caso'>Menu</BtnMenu>
          {props.intro !== 0 && (
            <BtnR onClick={() => clear()}>Recomeçar</BtnR>
          )}
          {props.backAmount > 0 && (
            <BtnB onClick={() => back(props.backAmount)}>Voltar</BtnB>
          )}
          {props.nextAmount > 0 && (
            <BtnF onClick={() => next(props.nextAmount)}>Avançar</BtnF>
          )}
        </BottomBar>
      </Wrapper>
    </div>
  )
}

Frame.propTypes = {
  backAmount: PropTypes.number.isRequired,
  nextAmount: PropTypes.number.isRequired,
  modalProps: PropTypes.object,
  modalInfoProps: PropTypes.object,
}

export default Frame
