import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import CoreBtn from './Btn'

const Overlay = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  height: 472px;
  left: 0;
  padding: 48px;
  position: absolute;
  z-index: 99999;
  top: 0;
  width: 880px;
`

const Bg = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  ${({ isCorrect }) =>
    isCorrect &&
    css`
      border-left: 20px solid #ffe37c;
    `}
  ${({ isCorrect }) =>
    !isCorrect &&
    css`
      border-left: 20px solid #ffe37c;
    `}
`

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  ${({ isCorrect }) =>
    isCorrect &&
    css`
      color: green;
    `}
  ${({ isCorrect }) =>
    !isCorrect &&
    css`
      color: red;
    `}
`

const Message = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
`

const Btn = styled(CoreBtn)`
  margin-top: auto;
  ${({ isCorrect }) =>
    isCorrect &&
    css`
      color: black;
      background-color: #ffe37c;
    `}
  ${({ isCorrect }) =>
    !isCorrect &&
    css`
      color: black;
      background-color: #ffe37c;
    `}
`
// const Img = styled.img`
//   max-width: 100%;
// `

function ModalInfo(props) {

  return (
    props.visible && (
      <Overlay>
        <Bg isCorrect={props.isCorrect}>
          <Title isCorrect={props.isCorrect}>
            {props.isCorrect ? 'Entendendo um pouco mais...' : ''}
          </Title>
          <Message>
            <ReactMarkdown>{props.message}</ReactMarkdown>
          </Message>
          <Btn onClick={props.btnCb} isCorrect={props.isCorrect}>
            {props.isCorrect ? 'Entendi!' : 'Tentar Novamente'}
          </Btn>
        </Bg>
      </Overlay>
    )
  )
}

ModalInfo.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string,
  isCorrect: PropTypes.bool,
  btnCb: PropTypes.func,
}

ModalInfo.defaultProps = {
  visible: false,
}

export default ModalInfo
