import React from "react"

import PlaylistTypes from "./components/PlaylistTypes"
import { Provider } from "./contexts/Playlists"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <PlaylistTypes match={match} history={history}/>
    </Provider>
  )
}