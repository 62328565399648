import biology from "./biology"
import genetics from "./genetics"
import biochemistry from "./biochemistry"
import anatomy from "./anatomy"
import pharmacology from "./pharmacology"

import nursing from "./nursing"
import physicalTherapy from "./physicalTherapy"
import medicine from "./medicine"
import immunology from "./immunology"
import microbiology from "./microbiology"
import parasitology from "./parasitology"



export default {
  biology,
  genetics,
  biochemistry,
  anatomy,
  pharmacology,
  nursing,
  physicalTherapy,
  medicine,
  immunology,
  microbiology,
  parasitology,
}