import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import Card from "./components/card";
import FlexRow from "components/FlexRow";
import data from "./resources";
import { useHistory } from "react-router-dom";

// import useAppCtx from 'hooks/useAppCtx'
// import useUniversityCtx from 'hooks/useUniversityCtx'

const CardContainer = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const Container = styled.div`
  margin-bottom: 20px;
  @media only screen and (max-width: 565px) {
    width: 100%;
  }
`;

const Header = styled(FlexRow)`
  background-color: #f5f9fa;
  border-radius: 2px;
  padding: 40px;
  flex-direction: column;
  margin-bottom: 20px;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`;

const Dropdown = styled.select`
  -webkit-appearance: none;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  color: #044c74;
  display: block;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  max-width: 260px;
  outline: none;
  padding-left: 15px;
  padding-right: 45px;
  border: 1px solid #1cace3;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
  }
`;

const Info = styled.div`
  align-self: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  width: 100%;
  border-bottom: 0.5px solid #d3d3d3;
  margin-bottom: 10px;
  padding-bottom: 25px;
  display: flex;
  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    flex-flow: column;
  }
`;

const InfoContainer = styled.div`
  font-weight: 500;
  @media only screen and (max-width: 565px) {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
`;
const InfoLabel = styled.div`
  font-weight: 600;
  margin-top: 10px;
  > span {
    font-weight: 400;
  }
`;

const Button = styled.a`
  background-color: #1cace3;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
  box-shadow: 1px 1px 1px 0px black;
`;

const BackButton = styled.div`
  background-color: #ff595e;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
  box-shadow: 1px 1px 1px 0px black;
  width: 170px;
  margin-left: 20px;
  @media only screen and (max-width: 565px) {
    margin-top: 20px;
    margin-left: 0;
  }
`;

export default function ({ match }) {
  const [dummy, reload] = useState(1);
  const history = useHistory();
  // const ctx = useAppCtx()
  // const { universities } = useUniversityCtx()

  const onClick = () => {
    reload(dummy + 1);
  };

  const { slug } = match.params;
  const [currLesson, setCurrLesson] = useState();

  const topic = useMemo(() => {
    return data[slug];
  }, [slug]);

  useEffect(() => {
    setCurrLesson(Object.keys(topic.lessons)[0]);
    console.log("Detail", currLesson)
    // TODO: Performance test
    // eslint-disable-next-line
  }, [topic]);

  useEffect(() => {
    //mixpanel(ctx, 'Flashcard Detail', { "University": universities.name  })

  }, [])

  const lessons = useMemo(() => {
    if (topic && currLesson) {
      return topic.lessons[currLesson].cards;
    }
    return [];
  }, [topic, currLesson]);

  return (
    <div>
      <Header>
        <Info>
          Escolha um tema:
          <Dropdown onChange={(e) => setCurrLesson(e.target.value)}>
            {Object.entries(topic.lessons).map(([k, v]) => (
              <option value={k}>{v.name}</option>
            ))}
          </Dropdown>
          <Button onClick={onClick}>Reordenar Cartões</Button>
          <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
        </Info>

        <InfoContainer>
          {topic.lessons[currLesson] && (
            <InfoLabel>
              Instruções: <span>{topic.lessons[currLesson].info}</span>
            </InfoLabel>
          )}
        </InfoContainer>
      </Header>
      <CardContainer>
        {lessons &&
          lessons
            .sort((x) => Math.random() - 0.5)
            .map((data) => (
              <Container key={data.id}>
                <Card
                  frontText={data.front}
                  backText={data.back}
                  cardId={data.id}
                />
              </Container>
            ))}
      </CardContainer>
    </div>
  );
}
