import React from "react"

import Exercises from "./components/Exercises"
import { Provider } from "./contexts/Exercises"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <Exercises match={match} history={history}/>
    </Provider>
  )
}