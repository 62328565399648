import React, { useState, useCallback, useMemo, useEffect } from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { CheckCircle, TimesCircle, Video } from "styled-icons/fa-solid"
import { Circle } from "styled-icons/fa-regular"

import mixpanel from "utils/mixpanel"
import FlexColumn from "components/FlexColumn"
import FlexRow from "components/FlexRow"
import useLayoutCtx from "hooks/useLayoutCtx"
import useAppCtx from "hooks/useAppCtx"

import useUniversityCtx from 'hooks/useUniversityCtx'
import useQuestionsCtx from "../hooks/useQuestionsCtx"

const Container = styled(FlexColumn)`
  background-color: #f2f7fa;
  border-radius: 10px;
  padding: 40px 60px;
  @media only screen and (max-width: 565px) {
    padding: 20px 10px;
  }
`

const CenterContainer = styled(FlexColumn)`
  max-width: 760px;
  margin: 0 auto;
  margin-bottom: 30px;
`

const QuestionTitle = styled.h3`
  color: #676a74;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.75;
  margin-top: 40px;
  margin-bottom: 50px;
  white-space: pre-wrap;
  @media only screen and (max-width: 565px) {
    font-size: 14px;
  }
`

const QuestionImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const QuestionImage = styled.img`
  flex-grow: 0;
  border-radius: 8px;
  height: auto;
  width: auto;
  max-height: 300px;
  margin-bottom: 50px;
`

const QuestionAnswerWrapper = styled(FlexRow)`
  align-items: center;
  border-radius: 8px;
  color: #9da3a8;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: solid 2px transparent;
  & > svg {
    flex-shrink: 0;
  }
  &:last-child {
    margin-bottom: 40px;
  }
  ${({ correct }) => correct && css`
    background-color: #cbffc1;
    border-color: #4ec437;
    color: #4ec437;
  `};
  ${({ incorrect }) => incorrect && css`
    background-color: #febdbf;
    border-color: #ff595e;
    color: #ff595e;
  `};
  ${({ selected }) => selected && css`
    background-color: #d4f3ff;
    border-color: #1cace3;
    color: #1cace3;
  `};
`

const QuestionAnswer = styled.div`
  padding: 5px 0;
  margin-left: 10px;
`

const Button = styled.button`
  background-color: #1cace3;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  margin-left: auto;
  margin-top: 40px;
  outline: 0;
  padding: 0 25px;
  @media only screen and (max-width: 565px) {
    width: 100%;
  }
  ${({ disabled }) => disabled && css`
    background-color: #d7dde2;
    color: #fff;
  `};
  ${({ correct }) => correct !== undefined && correct && css`
    background-color: #4ec437;
    color: #fff;
  `};
  ${({ correct }) => correct !== undefined && !correct && css`
    background-color: #ff595e;
    color: #fff;
  `};
`

const QuestionResult = styled(FlexRow)`
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  margin-top: 40px;
  & > svg {
    margin-right: 10px;
  }
  ${({ correct }) => correct && css`
    color: #4ec437;
  `};
  ${({ correct }) => !correct && css`
    color: #ff595e;
  `};
`

const QuestionExplanationHeader = styled.h3`
  color: #676a74;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
`

const QuestionExplanation = styled.div`
  color: #9da3a8;
  font-size: 16px;
  line-height: 1.75;
  white-space: pre-wrap;
`

const ProgressBar = styled.div`
  background-color: #e3e8e9;
  border-radius: 6px;
  height: 12px;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
  > div {
    background-color: #1cace3;
    height: 12px;
    position: absolute;
    transition: width 1s ease-in-out;
    ${({ percent }) => css`
      width: ${percent}%;
    `
  }
`

const ProgressText = styled.div`
  color: #9da3a8;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 1;
  text-align: right;
  width: 160px;
  @media only screen and (max-width: 565px) {
    width: auto;
    padding-left: 10px;
  }
`

const ActionWrapper = styled(FlexRow)`
  @media only screen and (max-width: 565px) {
    flex-direction: column;
  }
`

const BackLink = styled(Link)`
  display: block
  color: #676a74;
  line-height: 40px;
  margin-top: 40px;
`

const WatchLink = styled(Link)`
  color: #1cace3;
  display: block;
  text-decoration: none;
  margin-left: auto;
  margin-bottom: 30px;

  & > svg {
    position: relative;
    margin-left: 10px;
    top: 2px;
  }
`

const Center = styled(FlexRow)`
  align-items: center;
`

export default function() {
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()

  const {
    courseId,
    topic,
    currQuestion,
    progress,
    nextOrFinish,
  } = useQuestionsCtx()

  const [state, setState] = useState({
    confirmed: false,
    selectedIdx: undefined,
  })

  const {
    confirmed,
    selectedIdx,
  } = state

  const answeredCorrectly = useMemo(() => {
    return selectedIdx === currQuestion.correctAnswerIdx
  }, [selectedIdx, currQuestion])

  const selectAnswer = useCallback(selectedIdx => () => {
    setState({ confirmed: false, selectedIdx })
  }, [])

  const confirmAnswer = useCallback(() => {
    setState({ confirmed: true, selectedIdx })
  }, [selectedIdx])

  const proceed = useCallback(() => {
    setState({ confirmed: false, selectedIdx: undefined })
    nextOrFinish(answeredCorrectly)
  }, [answeredCorrectly, nextOrFinish])

  useEffect(() => {
    mixpanel(ctx, 'Exercises_Question', { "Topic_Name": topic.name, "Question_Title": currQuestion.title, "University": universities.name })
    // eslint-disable-next-line
  }, [topic, currQuestion])

  useEffect(() => {
    const backLink = `/exercicios/${courseId}/disciplinas/${topic.id}`
    setLayoutState({ title: topic.name, subTitle: 'Exercícios', backLink })

    return (() => {
      setLayoutState({})
    })
  }, [courseId, topic, setLayoutState])

  return(
    <>
      <Container>
        <CenterContainer>
          <Center>
            <WatchLink
              to={`/disciplinas/${currQuestion.topicId}/modulos/${currQuestion.lessonId}/assuntos/${currQuestion.subjectId}`}
              target="blank"
            >
              Assista ao vídeo
              <Video size="20"/>
            </WatchLink>
          </Center>
          <FlexRow>
            <ProgressBar percent={progress}>
              <div></div>
            </ProgressBar>
            <ProgressText>
              {progress}% completo
            </ProgressText>
          </FlexRow>
          <QuestionTitle>
            {currQuestion.title}
          </QuestionTitle>
          {currQuestion.image &&
            <QuestionImageWrapper>
              <QuestionImage src={`https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/questions/${currQuestion.id}/image`}/>
            </QuestionImageWrapper>
          }
          {currQuestion.answers.map((answer, i) => {
            const selected =
              selectedIdx === i
            const notSelectedNotConfirmed =
              !selected && !confirmed
            const selectedNotConfirmed =
              selected && !confirmed
            const correctPick =
              currQuestion.correctAnswerIdx === i
            const confimedWrong =
              confirmed && selected && !correctPick
            const confimedCorrectly =
              confirmed && selected && correctPick
            const confimedCorrection =
              confirmed && !selected && correctPick
            const confimedNotPicked =
              confirmed && !selected && !correctPick
            return(
              <QuestionAnswerWrapper
                key={i}
                selected={selectedNotConfirmed}
                correct={confimedCorrectly || confimedCorrection}
                incorrect={confimedWrong}
                onClick={!confirmed ? selectAnswer(i) : undefined}
              >
                {(notSelectedNotConfirmed || confimedNotPicked) && <Circle size="24"/>}
                {(selectedNotConfirmed || confimedCorrectly || confimedCorrection) && <CheckCircle size="24"/>}
                {confimedWrong && <TimesCircle size="24"/>}
                <QuestionAnswer>
                  {answer}
                </QuestionAnswer>
              </QuestionAnswerWrapper>
            )
          })}
          {confirmed && currQuestion.explanation &&
            <>
              <QuestionExplanationHeader>
                Explicação
              </QuestionExplanationHeader>
              <QuestionExplanation>
                {currQuestion.explanation}
              </QuestionExplanation>
            </>
          }
          <ActionWrapper>
            {selectedIdx === undefined &&
              <>
                <BackLink to={`/exercicios/${courseId}/disciplinas/${topic.id}`}>
                  voltar
                </BackLink>
                <Button disabled>
                  Responder
                </Button>
              </>
            }
            {!confirmed && selectedIdx !== undefined &&
              <Button onClick={confirmAnswer}>
                Responder
              </Button>
            }
          {confirmed &&
            <>
            {answeredCorrectly ?
              <QuestionResult correct={true}>
                <CheckCircle size="24"/> Resposta correta
              </QuestionResult> :
              <QuestionResult correct={false}>
                <TimesCircle size="24"/> Resposta errada
              </QuestionResult>
            }
            <Button correct={answeredCorrectly} onClick={proceed}>
              Continuar
            </Button>
            </>
          }
          </ActionWrapper>
        </CenterContainer>
      </Container>
    </>
  )
}