import React from "react"

import Topic from "./components/Topic"
import { Provider } from "./contexts/Topic"

export default function({ match, history }) {
  return (
    <Provider match={match} history={history}>
      <Topic match={match} history={history}/>
    </Provider>
  )
}