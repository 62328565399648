import { useCallback, useState, useRef, useLayoutEffect } from 'react'

import VimeoPlayer from '@vimeo/player'

const tryPlay = async api => {
  try {
    if ((await api.getVolume()) === 0) {
      api.setVolume(1)
    }
    await api.play()
    return false
  } catch {
    try {
      api.setVolume(0)
      await api.play()
      return true
    } catch (e) {
      console.log('Could not play video: ', e)
    }
  }
}

export default function useVimeo({ debug, controls = true } = {}) {
  const ref = useRef()
  const [api, setApi] = useState()
  const [apiReady, setApiReady] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [vimeoId, setVimeoId] = useState()
  const [endedCallback, setEndedCallback] = useState(() => () => {})

  useLayoutEffect(() => {
    const fn = async () => {
      if (apiReady) {
        debug && console.log('Replacing video')

        try {
          await api.loadVideo(vimeoId)
        } catch (e) {
          debug && console.log('Could not replace video: ', e)
        }

        await tryPlay(api)

        api.setLoop(vimeoId === '397339858')
        debug && console.log('Replaced video')
      }
    }

    fn()
    // eslint-disable-next-line
  }, [vimeoId])

  useLayoutEffect(() => {
    const fn = async () => {
      if (!apiReady && vimeoId) {
        debug && console.log('Initializing player')
        const newApi = new VimeoPlayer(ref.current, {
          width: 640,
          quality: '540p',
          id: vimeoId,
          controls,
        })

        try {
          await newApi.ready()
        } catch (e) {
          debug && console.log('Could not initialize player: ', e)
        }

        const isMuted = await tryPlay(newApi)
        setIsMuted(isMuted)
        setApi(newApi)
        setApiReady(true)
        setIsPlaying(true)
        newApi.setLoop(vimeoId === '397339858')
        debug && console.log('Initialized player')
      }
    }

    fn()
    // eslint-disable-next-line
  }, [vimeoId, apiReady])

  useLayoutEffect(() => {
    if (apiReady) {
      debug && console.log('Refreshing callbacks')

      api.off('play')
      api.on('play', () => setIsPlaying(true))

      api.off('pause')
      api.on('pause', () => setIsPlaying(false))

      api.off('ended')
      api.on('ended', () => endedCallback())

      api.off('volumechange')
      api.on('volumechange', ({ volume }) =>
        console.log({ volume }) || volume === 0
          ? setIsMuted(true)
          : setIsMuted(false),
      )

      debug && console.log('Refreshed callbacks')
    }
    // eslint-disable-next-line
  }, [apiReady, endedCallback])

  const pause = useCallback(() => apiReady && api.pause(), [apiReady, api])

  const play = useCallback(() => apiReady && api.play(), [apiReady, api])

  const mute = useCallback(() => apiReady && api.setVolume(0), [apiReady, api])

  const unmute = useCallback(() => apiReady && api.setVolume(1), [
    apiReady,
    api,
  ])

  return {
    ref,
    isPlaying,
    isMuted,
    play,
    pause,
    mute,
    unmute,
    setVimeoId,
    setEndedCallback,
    ready: apiReady,
  }
}
