import React from 'react'
import lng from 'date-fns/locale/pt'
import { distanceInWords } from 'date-fns'
import styled, { css } from "styled-components"
import { Trash } from 'styled-icons/boxicons-regular'
import CommunityAPI from "datasources/community"

import useAppCtx from "hooks/useAppCtx"
import useAuthCtx from 'hooks/useAuthCtx'

const Avatar = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 0.875rem;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 15px;
  background: #ccc;
  text-transform: uppercase;
`
const Resume = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #cccccc;
  padding-left: 16px;
`
const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
  }
`
const User = styled.div`
  display: flex;
  text-align: left;
  color: rgb(85, 85, 85);
  word-break: break-word;
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 10px;
  span {
    color: rgb(187, 187, 187);
    font-size: 0.75rem;
    display: flex;
    font-weight: 400;
    margin-left: 5px;
  }
`
const Topics = styled.div`
  display: flex;
  align-items: center;
`
const Topic = styled.div`
  font-size: 0.70rem;
  font-weight: 500;
  border-radius: 0 10px 10px 10px;
  font-size: 13px;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  text-transform: lowercase;
  background-color: #1cace3;;
  color: #ffffff;
  margin-left: 2px;
`
const Title = styled.div`
  display: block;
  color: rgb(85, 85, 85);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    color: #FF595E;
  }
`
const Count = styled.div`
  font-size: 0.70rem;
  font-weight: 600;
  max-width: 101px;
  background-color: #1cace354;
  text-align: center;
  color: ${({ active }) => active ? '#003558' : 'rgb(85, 85, 85)'};
  cursor: pointer;
  border-radius: 0 10px 10px 10px;
  padding: 5px 15px;

`
const Content = styled.div`
  display: block;
  color: rgb(85, 85, 85);
  font-size: 0.875rem;
  line-height: 20px;
  margin-bottom: 15px;
  cursor: pointer;
`
const Action = styled.div`
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    opacity: 0.5;
  }
`

const Container = styled.div`
  border: 1px solid rgb(227, 227, 227);
  border-radius: 3px;
  padding: 16px;
  display: flex;
  position: relative;
  background: white;
  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.3);
  }
  ${({ readonly }) => readonly && css`
    &:hover {
      box-shadow: unset;
    }
    ${Content}, ${Count}, ${Title} {
      pointer-events: none;
    }
  `}
`

export const Post = ({ data, onClick = () => {}, readonly = false, onDelete }) => {
  const ctx = useAppCtx()
  const { user } = useAuthCtx()
  const { id, title, content, topics, createdAt, user: { id: userId, name = 'AN', admin: isAdmin } = {}, repliesCount } = data
  const isReplyOwner = userId === user.id

  const destroy = async () => {
    await CommunityAPI.delete(ctx, { postId: id })
    onDelete && onDelete(data)
  }

  return (
    <Container readonly={readonly} highlight={isAdmin}>
      <Avatar>{name.substring(0, 2)}</Avatar>
      <Resume>
        <Meta>
          <User>{name},<span>{distanceInWords(new Date(), createdAt, { addSuffix: true, locale: lng })}</span></User>
          <div>
            {topics && topics.length > 0  && <Topics>
            <Topic>{topics[0].name}</Topic>
            </Topics>}
            {isReplyOwner && onDelete && <Action title='Deletar pergunta' onClick={destroy}><Trash size={24} /></Action>}
          </div>
        </Meta>
        <Title onClick={onClick}>{title}</Title>
        <Content onClick={onClick}>{content}</Content>
        <Count active={repliesCount > 0} onClick={onClick}>
          {repliesCount === 1 ? `${repliesCount} resposta` : `${repliesCount || 0} respostas`}
        </Count>
      </Resume>
    </Container>
  )
}

export default Post
