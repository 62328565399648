import React, { useEffect } from 'react'
import FlexColumn from 'components/FlexColumn'
import FlexRow from 'components/FlexRow'
import styled from 'styled-components'
import HomeBG from '../../../components/backgrounds/HomeBG.svg'
import Youtube from '../../../components/backgrounds/Youtube.svg'
import TikTok from '../../../components/backgrounds/TikTok.svg'
import Instagram from '../../../components/backgrounds/Instagram.svg'

import VideosBG from '../../../components/backgrounds/VideosBG.png'
import LabBG from '../../../components/backgrounds/LabBG.png'
import QuestionsBG from '../../../components/backgrounds/QuestionsBG.png'
import WorkshopsBG from '../../../components/backgrounds/WorkshopsBG.png'
import WorkHemo from '../../../components/backgrounds/WorkHemo.png'
import WorkStudy from '../../../components/backgrounds/WorkStudy.png'
import WorkGaso from '../../../components/backgrounds/WorkGaso.png'
import WorkATB from '../../../components/backgrounds/WorkATB.png'
import CasosBG from '../../../components/backgrounds/CasosBG.png'
import SabeEstudar from '../../../components/backgrounds/SabeEstudar.png'

import mixpanel from 'utils/mixpanel'
import useAppCtx from 'hooks/useAppCtx'

import useLayoutCtx from 'hooks/useLayoutCtx'
import useAuthCtx from 'hooks/useAuthCtx'
import useUniversityCtx from 'hooks/useUniversityCtx'

const Container = styled(FlexRow)`
  background-color: #f5f9fa;
  border-radius: 3px;
  padding: 30px;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`

const LessonsContainer = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
`

const ResultsContainer = styled(FlexColumn)`
  margin-left: 30px;
  max-width: 370px;
  height: 100%;
  @media only screen and (max-width: 565px) {
    margin-left: 0;
  }
`

const LessonsBox = styled.div`
  background-color: #e1eaf9;
  max-height: 190px;
  border-radius: 3px;
  padding-bottom: 0;
  margin-bottom: 40px;
  flex: 1 1 auto;
  @media only screen and (max-width: 565px) {
    background-color: #f5f9fa;
    border: none;
    padding: 0;
    margin-bottom: 0;
    display: none;
  }
`

const LessonsList = styled.div`
  width: 100%;
  margin-bottom: 30px;
  flex-grow: 1 !important;
  position: relative;
  @media only screen and (max-width: 565px) {
    height: auto;
  }
`
const ImageBg = styled.div`
  background-repeat: no-repeat;
  z-index: 0;
  position: relative;
  min-height: 165px;
  background-image: url(${HomeBG});
  background-position: right bottom;
  background-size: auto 100%;

  @media only screen and (max-width: 1650px) {
    background-image: none;
  }
`
const Welcome = styled.div`
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  color: #676a74;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 30px;
`

const SummaryContainer = styled(FlexRow)`
  flex: 1;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  @media only screen and (max-width: 565px) {
    grid-template-columns: 100%;
  }
`

const Summary = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-self: center;
  color: #676a74;
  font-size: 16px;
  line-height: 22px;
  border-radius: 3px;
  background-color: #ffffff;
  margin-right: 10px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  @media only screen and (max-width: 565px) {
    align-self: flex-start;
    width: 100%;
    font-weight: 600;
  }
`
const Result = styled(FlexRow)`
  font-size: 16px;
  line-height: 42px;
  color: #9da3a8;
  margin-bottom: 30px;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: 565px) {
    margin-bottom: 10px;
  }
`
const ResultsBox = styled.div`
  background-color: white;
  border-radius: 3px;
  padding: 30px;
  padding-bottom: 0;
  height: 100%;
  min-height: 366px;
  @media only screen and (max-width: 565px) {
    background-color: #f5f9fa;
    border: none;
    padding: 0;
    margin: 0;
    min-height: 0;
  }
`

const Spotify = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const BoxBottom = styled.div`
  position: relative;
  padding-bottom: 78.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const SocialLink = styled.a``

const AreaLink = styled.a`
  display: flex;
  flex: 1;
  text-decoration: none;
`

const Img = styled.img`
  max-width: 40px;
  margin-top: 60px;
  margin-right: 82px;
`

const ImgBG = styled.img`
  width: 100%;
`

const AreaTitle = styled.span`
  margin-top: auto;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 500;
  text-align: center;
  @media only screen and (max-width: 565px) {
    font-size: 22px;
  }
`

const SecondArea = styled.div`
  margin-top: 30px;
`

const SecondAreaTitle = styled.span`
  font-size: 16px;
  background-color: #044c74;
  color: white;
  padding: 10px;
  border-radius: 2px;
  margin-top: 30px;
  font-weight: bold;
`

export default function () {
  const { user } = useAuthCtx()
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { isFetchingUniversities, universities } = useUniversityCtx()

  useEffect(() => {
    mixpanel(ctx, 'Landing_Home')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setLayoutState({ title: 'Dashboard' })
  }, [setLayoutState])

  return (
    <Container>
      <LessonsContainer>
        <LessonsBox>
          <LessonsList>
            <ImageBg>
              <Welcome>
                Seja bem vindo, <b>{user.name.split(' ')[0]}!</b>
                <br />
                <br />
                {!isFetchingUniversities && universities && (
                  <span>
                    Caso tenha alguma dúvida ou sugestão, pode entrar em contato
                    através do email <br />
                    {universities.email && (
                      <a href={`mailto:${universities.email}`}>
                        {universities.email}
                      </a>
                    )}{' '}
                    ou pelo nosso chat.
                  </span>
                )}
              </Welcome>
            </ImageBg>
          </LessonsList>
        </LessonsBox>
        <SummaryContainer>
          <AreaLink href='/videos'>
            <Summary>
              <ImgBG src={`${VideosBG}`} />
              <AreaTitle>Vídeos</AreaTitle>
            </Summary>
          </AreaLink>
          <AreaLink href='/laboratorio-virtual'>
            <Summary>
              <ImgBG src={`${LabBG}`} />
              <AreaTitle>Laboratório 3D</AreaTitle>
            </Summary>
          </AreaLink>
          <AreaLink href='/exercicios'>
            <Summary>
              <ImgBG src={`${QuestionsBG}`} />
              <AreaTitle>Exercícios</AreaTitle>
            </Summary>
          </AreaLink>
          <AreaLink href='/estudos-de-caso'>
            <Summary>
              <ImgBG src={`${CasosBG}`} />
              <AreaTitle>Casos Clínicos</AreaTitle>
            </Summary>
          </AreaLink>
          <AreaLink href='/videos/5'>
            <Summary>
              <ImgBG src={`${WorkshopsBG}`} />
              <AreaTitle>Workshops</AreaTitle>
            </Summary>
          </AreaLink>
        </SummaryContainer>

        <SecondAreaTitle>Cursos com certificado</SecondAreaTitle>

        <SecondArea>
          <SummaryContainer>
            <AreaLink href='/videos/5'>
              <Summary>
                <ImgBG src={`${WorkHemo}`} />
                <AreaTitle>Hemograma Completo</AreaTitle>
              </Summary>
            </AreaLink>

            <AreaLink href='/videos/5'>
              <Summary>
                <ImgBG src={`${WorkStudy}`} />
                <AreaTitle>Como estudar melhor</AreaTitle>
              </Summary>
            </AreaLink>

            <AreaLink href='/videos/5'>
              <Summary>
                <ImgBG src={`${WorkGaso}`} />
                <AreaTitle>Gasometria</AreaTitle>
              </Summary>
            </AreaLink>

            <AreaLink href='https://antibioticoterapia.com.br' target='_blank'>
              <Summary>
                <ImgBG src={`${WorkATB}`} />
                <AreaTitle>Antibioticoterapia</AreaTitle>
              </Summary>
            </AreaLink>
          </SummaryContainer>
        </SecondArea>
      </LessonsContainer>

      <ResultsContainer>
        <ResultsBox>
          <Spotify>
            <iframe
              src='https://open.spotify.com/embed-podcast/show/7mnWP0vXNAJNRd0kXNlA83'
              width='100%'
              height='232'
              frameborder='0'
              allowtransparency='true'
              allow='encrypted-media'
              title='spotify'
            ></iframe>
          </Spotify>
          <Result>
            <SocialLink
              href='https://www.youtube.com/channel/UCFs759vmDdFKq_b6MT29E7g'
              target='blank'
            >
              <Img src={`${Youtube}`} />
            </SocialLink>

            <SocialLink href='https://instagram.com/ibselbr' target='blank'>
              <Img src={`${Instagram}`} />
            </SocialLink>

            <SocialLink
              href='https://www.tiktok.com/@ibselbr?lang=pt-BR'
              target='blank'
            >
              <Img src={`${TikTok}`} />
            </SocialLink>
          </Result>
        </ResultsBox>
        <BoxBottom>
          <AreaLink href='/disciplinas/23/modulos/167/assuntos/675'>
            <ImgBG src={`${SabeEstudar}`} />
          </AreaLink>
        </BoxBottom>
      </ResultsContainer>
    </Container>
  )
}
