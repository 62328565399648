import React, { useEffect, useCallback } from "react"

import AuthAPI from "datasources/auth"
import useAppContext from "hooks/useAppCtx"
import Logo from "routes/Anonymous/components/Logo"
import Form from "routes/Anonymous/components/Form"
import Title from "routes/Anonymous/components/Title"
import Subtitle from "routes/Anonymous/components/Subtitle"
import AltAction from "routes/Anonymous/components/AltAction"

export default function({ match, history }) {
  const { params: { accountConfirmationToken } } = match
  const ctx = useAppContext()
  const { auth: { deauth } } = ctx

  const logout = useCallback(() => {
    deauth()
    history.replace("/entrar")
  }, [deauth, history])

  useEffect(() => {
    async function confirmAccount() {
      if (accountConfirmationToken) {
        try {
          const { data: tokens } = await AuthAPI.createToken(ctx, { confirmEmailToken: accountConfirmationToken })
          ctx.auth.auth(tokens)
          history.push("/")
        } catch(e) {
        }
      }
    }
    confirmAccount()
    //eslint-disable-next-line
  }, [accountConfirmationToken])

  return (
    <Form>
      <Logo/>
      <Title>
        Quase Pronto
      </Title>
      <Subtitle>
        Agora só precisamos confirmar seu e-mail.<br/> Favor clicar no link que lhe enviamos.
      </Subtitle>
      <AltAction>
        <a //eslint-disable-line
          href="#"
          onClick={logout}
        >
          Voltar
        </a>
      </AltAction>
    </Form>
  )
}