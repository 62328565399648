import React from 'react'
import styled, { css } from "styled-components"
import CommunityAPI from "datasources/community"
import { Trash } from 'styled-icons/boxicons-regular'
import { Report } from 'styled-icons/material'

import useAppCtx from "hooks/useAppCtx"
import useAuthCtx from 'hooks/useAuthCtx'

const AvatarContainer = styled.div`
  padding-top: 16px;
`
const Avatar = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 0.675rem;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 15px;
  background: #ccc;
  text-transform: uppercase;
`
const Resume = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: rgb(204 204 204 / 47%);
  color: black;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  position: relative;
  ::before {
    content: "";
    display: inline-block;
    position: absolute;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent rgb(255, 255, 255);
    border-image: initial;
    top: 24px;
    left: -15px;
    transform: rotate(270deg);
    border-bottom-color: rgb(204 204 204 / 47%);
  }
`
const Meta = styled.div`
  display: flex;
  justify-content: space-between;
`
const User = styled.div`
  align-items: baseline;
  display: flex;
  text-align: left;
  word-break: break-word;
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 10px;
  span {
    font-size: 0.75rem;
    display: flex;
    font-weight: 400;
    margin-left: 5px;
  }
`
const Content = styled.div`
  display: block;
  font-size: 0.875rem;
  line-height: 20px;
  margin-bottom: 15px;
`
const Container = styled.div`
  display: flex;
  cursor: initial;
  position: relative;
  ${Resume} {
    ${({ highlight }) => highlight && css`
      background: #ffca3a99;
      color: #003558;
      ::before {
        border-bottom-color: #ffca3a99;
      }
    `}
  }
`

const Action = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  a {
    color: inherit;
  }
`
const Badge = styled.span`
  background-color: #ff595e;
  padding: 4px;
  border-radius: 3px;
  color: white;
`

export const Reply = ({ data, onDelete }) => {
  const ctx = useAppCtx()
  const { user } = useAuthCtx()
  const { id, content, user: { id: userId, name = 'AN', admin: isAdmin } = {} } = data
  const isReplyOwner = userId === user.id

  const destroy = async () => {
    await CommunityAPI.deleteReply(ctx, { replyId: id })
    onDelete && onDelete(data)
  }

  return (
    <Container highlight={isAdmin}>
      <AvatarContainer>
        <Avatar>{name.substring(0, 2)}</Avatar>
      </AvatarContainer>
      <Resume>
        <Meta>
          <User>
            {name}
            {isAdmin &&
            <Badge>Professor</Badge>
            }
            {/*<span>{distanceInWords(new Date(), createdAt, { addSuffix: true, locale: lng })}</span>*/}
          </User>
          {isReplyOwner && <Action title='Remover resposta' onClick={destroy}><Trash size={22} /></Action>}
          {!isReplyOwner && <Action title='Reportar resposta'>
            <a href={`mailto:contato@ibsel.com.br?subject=Reportar, Resposta ID: ${id} - ${name}`}>
              <Report size={24} />
            </a>
          </Action>}
        </Meta>
        <Content>{content}</Content>
      </Resume>
    </Container>
  )
}

export default Reply
