import React, { useCallback, useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import styled, { css } from "styled-components"

import mixpanel from "utils/mixpanel"
import FlexRow from "components/FlexRow"
import useAppCtx from "hooks/useAppCtx"
import useLayoutCtx from "hooks/useLayoutCtx"

import useSimulationsCtx from "../hooks/useSimulationsCtx"
import CourseRoute from "../routes/Course"
import TopicRoute from "../routes/Topic"
import Detail from "../routes/Detail"

import useUniversityCtx from 'hooks/useUniversityCtx'
import Tabs from "components/Tabs"

const NavBar = styled(FlexRow)`
`

const Courses = styled(Tabs)`
  height: 40px;
  margin-bottom: 30px;
`

const Course = styled(Tabs.item)`
  background-color: #E8EAEE;
  color: #044c74;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  margin-right: 0px;
  padding: 11px 20px;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  ${({ selected }) => selected && css`
    border-top-color: #044c74;
    border-top: 1px solid;
    background-color: #FFFFFF;
    color: #044c74;
    cursor: pointer;
  `}
`

export default function({ history }) {
  const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()

  const {
    courses,
    currCourse,
    currTopic,
  } = useSimulationsCtx()

  const courseChanged = useCallback(id => e =>
    history.push(`/laboratorio-virtual/${id}`)
  , [history])

  useEffect(() => {
    if (currTopic && universities) {
      setLayoutState({ title: currTopic.name, subTitle: 'Laboratório Virtual', backLink: '/laboratorio-virtual' })
      mixpanel(ctx, 'Simulations_Topic', { "Topic_Name": currTopic.name, "University": universities.name })
    } else {
      setLayoutState({ title: 'Laboratório Virtual' })
      mixpanel(ctx, 'Simulations_Home', { "University": universities.name })
    }
    return (() => {
      setLayoutState({})
    })
  // eslint-disable-next-line
  }, [currTopic, setLayoutState])

  return (
    <>
      {!currTopic &&
        <NavBar>
          <Courses>
            {courses.map(course => {
              const isCurrCourse = currCourse.id === course.id
              return (
                <Course
                  key={course.id}
                  selected={isCurrCourse}
                  onClick={!isCurrCourse ? courseChanged(course.id) : undefined}
                >
                  <Tabs.bullet />
                  {course.name}
                </Course>
              )
            })}
          </Courses>
        </NavBar>
      }

      <Switch>
        <Route exact path="/laboratorio-virtual/:courseId/disciplinas/:topicId/simulacao/:simulationId" component={Detail}/>
        <Route exact path="/laboratorio-virtual/:courseId/disciplinas/:topicId" component={TopicRoute}/>
        <Route exact path="/laboratorio-virtual/:courseId" component={CourseRoute}/>
      </Switch>
    </>
  )
}