import React from "react"
import styled from "styled-components"
import LogoSVG from "components/icons/LogoSVG"
import FlexColumn from "components/FlexColumn"

const Container = styled(FlexColumn)`
  align-items: center;
  margin-bottom: 60px;
`

const SVG = styled(LogoSVG)`
  width: 90px;
  height: 100px;
  & > path {
    fill: white;
  }
`

export function Logo() {
  return (
    <Container>
      <SVG/>
    </Container>
  )
}

export default Logo