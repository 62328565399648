import { get, put, buildUrl } from "./common"

export default {
  async list(ctx) {
    return await get(ctx, buildUrl(`/public/exercises`))
  },
  async get(ctx, { topicId, lessonsHash }) {
    return await get(ctx, buildUrl(`/public/exercises/${topicId}/${lessonsHash}`))
  },
  async put(ctx, { questionId, answeredCorrectly }) {
    return await put(ctx, buildUrl(`/public/user/questions/${questionId}`), { body: { answeredCorrectly } })
  },
  async stats(ctx, topicId) {
    return await get(ctx, buildUrl(`/public/exercises/${topicId}`))
  },
}