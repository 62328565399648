import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    max-width: 100%;
    overflow: hidden;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
  }

  pre {
    font-family: 'Montserrat', sans-serif;
  }

  #root {
    background-color: #e9eaee;
    display: flex;
    max-width: 100%;
    min-height: 100vh;
  }

  input[type="submit"] {
    cursor: pointer;
  }

  input, select, button {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    font-family: 'Montserrat', sans-serif;
    outline: none;
  }
`
