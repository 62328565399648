import React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  align-items: center;
  direction: rtl;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-top: 10px;
  .star:hover, .star:hover ~ .star {
    fill: #FFCA3A;
    stroke: #FFCA3A;
  }
`

const SVG = ({ className, onClick }) =>
  <svg className={`star ${className}`} onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7028 6.84816L12.8175 7.08931L13.0817 7.12813L18.8719 7.97892L14.3979 11.9883L14.1878 12.1766L14.2403 12.4538L15.4457 18.8096L10.3035 14.8813L10 14.6494L9.69647 14.8813L4.55433 18.8096L5.75967 12.4538L5.81224 12.1766L5.60211 11.9883L1.12814 7.97892L6.9183 7.12813L7.18249 7.08931L7.29716 6.84816L10 1.16429L12.7028 6.84816Z"/>
  </svg>

const Star = styled(SVG)`
  cursor: pointer;
  fill: #F5F9FA;
  margin-right: 10px;
  stroke: #D7DDE2;

  ${({ filled, rated }) => !rated && filled && css`
    fill: #D7DDE2;
    stroke: #D7DDE2;
  `}

  ${({ filled, rated }) => rated && filled && css`
    fill: #FFCA3A;
    stroke: #FFCA3A;
  `}
`

const Label = styled.span`
  color: #9da3a8
  cursor: default;
  font-size: 16px;
  margin-right: 20px;
`

const Rating = ({ onRate, rated }) =>
  <Container>
    {rated ?
      <>
        <Star filled={rated === 5} rated={true} onClick={() => onRate(5)}/>
        <Star filled={rated >= 4} rated={true} onClick={() => onRate(4)}/>
        <Star filled={rated >= 3} rated={true} onClick={() => onRate(3)}/>
        <Star filled={rated >= 2} rated={true} onClick={() => onRate(2)}/>
        <Star filled={rated >= 1} rated={true} onClick={() => onRate(1)}/>
      </> :
      <>
        <Star filled={false} onClick={() => onRate(5)}/>
        <Star filled={true} onClick={() => onRate(4)}/>
        <Star filled={true} onClick={() => onRate(3)}/>
        <Star filled={true} onClick={() => onRate(2)}/>
        <Star filled={true} onClick={() => onRate(1)}/>
      </>
    }
    <Label>Avalie</Label>
  </Container>

export default Rating